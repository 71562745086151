/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useRef, useState } from 'react';
import Slider from 'react-rangeslider';
import 'react-rangeslider/lib/index.css';
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import OutsideClickHandler from 'react-outside-click-handler';
import { connect } from 'react-redux';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import AudioSpectrum from 'react-audio-spectrum';
import moment from 'moment';
import { browser, getIp, getPodcastToStorage, os, SeoUrl, setPodcastToStorage } from '../utils';
import api from '../api';

moment.locale('tr');

const formatTime = (seconds) => {
	const h = Math.floor(seconds / 3600);
	const m = Math.floor((seconds % 3600) / 60);
	const s = seconds % 60;
	return [h, m > 9 ? m : h ? '0' + m : m || '0', s > 9 ? s : '0' + s].filter((a) => a).join(':');
};

const intervalTime = 10000;

const VolumeSlider = ({ changeVolume, changeMute }) => {
	const [value, setValue] = useState(100);
	const [volumeIcon, setVolumeIcon] = useState('volume-high-outline');
	const [isMute, setIsMute] = useState(false);

	const onChange = (value) => {
		setValue(value);
		changeVolume(value);

		if (value > 60) {
			setVolumeIcon('volume-high-outline');
		} else if (value <= 60 && value > 30) {
			setVolumeIcon('volume-medium-outline');
		} else if (value <= 30 && value > 0) {
			setVolumeIcon('volume-low-outline');
		} else {
			setVolumeIcon('volume-off-outline');
		}
	};

	const volumeMute = (mute) => {
		setIsMute(mute);
		changeMute(mute);
	};

	return (
		<div className="volume">
			<button onClick={() => volumeMute(!isMute)}>
				<ion-icon name={isMute ? 'volume-mute' : volumeIcon}></ion-icon>
			</button>
			<Slider min={0} max={100} value={value} onChange={onChange} />
		</div>
	);
};

const Favorite = ({ favorited, onClick }) => (
	<button className="favorite" onClick={() => onClick(!favorited)}>
		<div className={`toggle-liked${favorited ? ' active' : ''}`}>
			<div className="toggle-liked-sub">
				<ion-icon name={`heart${favorited ? '' : '-outline'}`}></ion-icon>
			</div>
		</div>
	</button>
);

const PodcastPopupItem = ({ podcasts, file, id, date, play, playHandle }) => {
	const [dur, setDur] = useState(0);

	useEffect(() => {
		//const localpath = `${CDN}/${file}`;
		const path = `https://upload.radyospor.com/${file}`;

		const au = document.createElement('audio');
		au.src = path;
		au.addEventListener(
			'loadedmetadata',
			function () {
				const durb = parseInt(au.duration, 10);
				setDur(formatTime(durb));
			},
			false,
		);
	}, []);

	const handle = (id) => {
		playHandle(id);
	};

	return (
		<li>
			<button className="played" onClick={() => handle(id)}>
				{play ? id === podcasts.id ? <ion-icon name="pause"></ion-icon> : <ion-icon name="play"></ion-icon> : <ion-icon name="play"></ion-icon>}
			</button>

			<div className="program-info">
				<div className="program-name">
					<a href={`/program/${SeoUrl(podcasts.program.name)}-${podcasts.program.id}`}>
						{podcasts.program.name} <span> - {moment(date).format('DD MMMM YYYY')}</span>
					</a>
				</div>
				<div className="program-broadcasters">
					{podcasts.broadcasters.map((brd, j) => (
						<Fragment key={j}>
							<a href={`/programci/${SeoUrl(brd.name)}-${brd.id}`}>{brd.name}</a>
							{podcasts.broadcasters.length > 0 && j < podcasts.broadcasters.length - 1 ? ',\u00A0' : ''}
						</Fragment>
					))}
				</div>
			</div>

			<div className="program-time">{dur}</div>
		</li>
	);
};

const PodcastPopup = ({ status, podcasts, duration, currentTime, play, playerRef, onPlay, changePlay }) => {
	const [spectrum, setSpectrum] = useState(false);

	useEffect(() => {
		if (playerRef.current !== null) {
			playerRef.current.audio.current.crossOrigin = 'anonymous';
			setSpectrum(true);
		}
	}, [playerRef.current]);

	const playChange = (status) => {
		if (status) {
			onPlay(true);
		} else {
			onPlay(false);
		}
	};

	const playHandle = (id) => {
		changePlay(id);
	};

	return (
		<div className={`player-popup${status ? ' opened' : ''}`}>
			<div className="popup-header">
				<div>
					<div className="popup-play">
						<CircularProgressbarWithChildren
							value={(100 * currentTime) / duration}
							styles={{
								path: {
									stroke: '#244B97',
									strokeWidth: 6,
								},
								trail: {
									stroke: '#D8D8D8',
									strokeWidth: 6,
								},
							}}
						>
							<button onClick={() => playChange(play)}>{play ? <ion-icon name="pause"></ion-icon> : <ion-icon name="play"></ion-icon>}</button>
						</CircularProgressbarWithChildren>
					</div>
					<div className="popup-waves">
						<div id="waveform">
							{spectrum && (
								<AudioSpectrum
									id="audio-canvas"
									height={20}
									width={240}
									gap={2}
									audioEle={playerRef.current.audio.current}
									gapColor={'black'}
									meterWidth={2}
									meterCount={512}
									meterColor={[
										{ stop: 0, color: '#f00' },
										{ stop: 0.5, color: '#0CD7FD' },
										{ stop: 1, color: 'red' },
									]}
								/>
							)}
						</div>
					</div>
				</div>

				<div className="popup-info">
					<div className="program-name">
						<a href={`/program/${SeoUrl(podcasts.program.name)}-${podcasts.program.id}`}>{podcasts.program.name}</a>
					</div>
					<div className="program-time">
						{formatTime(parseInt(currentTime, 10))} / {formatTime(parseInt(duration, 10))}
					</div>
				</div>
			</div>

			<ul>
				{podcasts.podcast.map((pd, i) => (
					<PodcastPopupItem key={i} file={pd.file} id={pd.id} date={pd.date} podcasts={podcasts} play={play} playHandle={(id) => playHandle(id)} />
				))}
			</ul>
		</div>
	);
};

const SettingsPopup = ({ status, autoPlay, setAutoplay, shuffle, setShuffle, rewind, setRewind, saveSeek, setSaveSeek, forward, setForward }) => (
	<div className={`player-popup player-settings${status ? ' opened' : ''}`}>
		<div className="popup-header">
			<div className="popup-title">Oynatma Ayarları</div>
		</div>

		<ul>
			{/* <li>
				<label className="setting-check">
					<input type="checkbox" onChange={() => setAutoplay(!autoPlay)} defaultChecked={autoPlay} />
					<span>Sonraki Podcasti otomatik oynat</span>
				</label>
			</li>
			<li>
				<label className="setting-check">
					<input type="checkbox" onChange={() => setShuffle(!shuffle)} defaultChecked={shuffle} />
					<span>Podcastleri karışık oynat</span>
				</label>
			</li> */}
			<li>
				<div className="setting-title">İleri sarma hızı</div>
				<div className="setting-options">
					<select onChange={(e) => setForward(e.target.value)} defaultValue={forward}>
						<option value="15">15s</option>
						<option value="30">30s</option>
						<option value="45">45s</option>
					</select>
				</div>
			</li>
			<li>
				<label className="setting-check">
					<input type="checkbox" onChange={() => setRewind(!rewind)} defaultChecked={rewind} />
					<span>Oynatma tamamlandığında başa dön</span>
				</label>
			</li>
			<li>
				<label className="setting-check">
					<input type="checkbox" onChange={() => setSaveSeek(!saveSeek)} defaultChecked={saveSeek} />
					<span>Kaldığı yerden devam etsin</span>
				</label>
			</li>
		</ul>
	</div>
);

const PlayerPodcast = ({
	user,
	changePlayerType,
	podcast,
	podcast_play,
	podcast_id,
	setPlayPodcast,
	getPodcastCurrentTime,
	getPodcastDurationTime,
	getPodcast,
	setPlayedPodcast,
	setFavoritePodcasts,
	favoritePodcasts,
}) => {
	const [isPlay, setIsPlay] = useState(false);
	const [volume, setVolume] = useState(100);
	const [isFavorite, setIsFavorite] = useState(false);
	const [podcastPopup, setPodcastPopup] = useState(false);
	const [settingsPopup, setSettingsPopup] = useState(false);
	const [podcastInfo, setPodcastInfo] = useState({});
	const [duration, setDuration] = useState(0);
	const [currentTime, setCurrentTime] = useState(0);
	const playerRef = useRef(null);
	const interval = useRef();

	const [autoPlay, setAutoplay] = useState(JSON.parse(localStorage.autoPlay || false));
	const [shuffle, setShuffle] = useState(JSON.parse(localStorage.shuffle || false));
	const [rewind, setRewind] = useState(JSON.parse(localStorage.rewind || false));
	const [saveSeek, setSaveSeek] = useState(JSON.parse(localStorage.saveSeek || true));
	const [forward, setForward] = useState(JSON.parse(localStorage.forward || '15'));

	useEffect(() => {
		localStorage.autoPlay = JSON.stringify(autoPlay);
	}, [autoPlay]);

	useEffect(() => {
		localStorage.shuffle = JSON.stringify(shuffle);
	}, [shuffle]);

	useEffect(() => {
		localStorage.rewind = JSON.stringify(rewind);
	}, [rewind]);

	useEffect(() => {
		localStorage.saveSeek = JSON.stringify(saveSeek);
	}, [saveSeek]);

	useEffect(() => {
		localStorage.forward = JSON.stringify(forward);
	}, [forward]);

	const play = (status) => {
		setIsPlay(!status);
		setPlayPodcast(!status);

		if (status) {
			playerRef.current.audio.current.pause();
		} else {
			playerRef.current.audio.current.play();
		}
	};

	const changeVolume = (value) => {
		playerRef.current.audio.current.volume = value / 100;
		setVolume(value);
	};

	const changeMuteStatus = (status) => {
		if (status) {
			playerRef.current.audio.current.volume = 0;
		} else {
			playerRef.current.audio.current.volume = volume / 100;
		}
	};

	const setFavorite = (status) => {
		setIsFavorite(status);

		if (Object.keys(podcastInfo).length > 0 && Object.keys(user).length > 0) {
			setFavoritePodcasts({ podcasts: podcastInfo.id, userId: user.id });
		}
	};

	useEffect(() => {
		setPodcastInfo(podcast);
	}, [podcast]);

	useEffect(async () => {
		setIsPlay(podcast_play);

		if (playerRef.current) {
			if (!podcast_play) {
				playerRef.current.audio.current.pause();
			} else {
				setTimeout(() => playerRef.current.audio.current.play(), 250);
				if (Object.keys(user).length > 0) {
					const ip = await getIp();
					api.logs({ userId: user.id, logType: 2, eventId: podcastInfo.id, ip, os: os(), bw: browser() }).then((res) => res);
				}
			}
		}
	}, [podcast_play, playerRef]);

	useEffect(() => {
		if (Object.keys(podcastInfo).length > 0) {
			playerRef.current.audio.current.currentTime = parseFloat(getPodcastToStorage(podcastInfo.id) || 0);
		}
	}, [podcastInfo]);

	const onListen = (e) => {
		const duration = e.target.duration;
		const currentTime = e.target.currentTime;

		setDuration(duration);
		setCurrentTime(currentTime);

		getPodcastDurationTime(duration);
		getPodcastCurrentTime(currentTime);

		setPodcastToStorage(podcastInfo.id, currentTime);
	};

	const onPause = () => {
		setPodcastToStorage(podcastInfo.id, currentTime);

		clearTimeout(interval.current);
	};

	const onEnded = () => {
		clearTimeout(interval.current);

		if (JSON.parse(localStorage.rewind) === true) {
			playerRef.current.audio.current.currentTime = 0;

			setTimeout(() => playerRef.current.audio.current.play(), 2000);
		} else {
			play(isPlay);
		}

		setPodcastToStorage(podcastInfo.id, currentTime);
	};

	const podcastUserDataUpdate = async () => {
		interval.current = setTimeout(podcastUserDataUpdate, intervalTime);

		const time = Number(getPodcastToStorage(podcastInfo.id));
		if (Object.keys(user).length > 0) {
			const ip = await getIp();
			api.setListenedPodcast({ userId: user.id, podcastId: podcastInfo.id, source: 'Web', currentTime: time, ip, os: os(), bw: browser() }).then((res) => res);
		}
	};

	const onPlay = () => {
		if (JSON.parse(saveSeek) === true) {
			interval.current = setTimeout(podcastUserDataUpdate, intervalTime);
		}
	};

	const playHandle = (id) => {
		if (podcastInfo.id === id) {
			play(isPlay);
		} else {
			setPlayedPodcast(id);
			getPodcast(id);
		}
	};

	const prevPodcast = () => {
		if (playerRef.current.audio.current.currentTime >= parseInt(JSON.parse(localStorage.forward), 10)) {
			playerRef.current.audio.current.currentTime -= parseInt(JSON.parse(localStorage.forward), 10);
		}
	};

	const nextPodcast = () => {
		if (playerRef.current.audio.current.currentTime + parseInt(JSON.parse(localStorage.forward), 10) <= playerRef.current.audio.current.duration) {
			playerRef.current.audio.current.currentTime += parseInt(JSON.parse(localStorage.forward), 10);
		}
	};

	useEffect(() => {
		const find = favoritePodcasts && favoritePodcasts.find((x) => x === podcastInfo.id);
		const status = typeof find === 'undefined' ? false : true;
		setIsFavorite(status);
	}, [favoritePodcasts, podcastInfo]);

	return (
		<div className="player podcast-player">
			<div className="program-info">
				{podcastInfo && Object.keys(podcastInfo).length > 0 && (
					<>
						<div className="program-image">
							<a href={`/program/${SeoUrl(podcastInfo.program.name)}-${podcastInfo.program.id}`}>
								<img src={podcastInfo.program.image} alt={podcastInfo.program.name} />
							</a>
						</div>

						<div className="program-detail">
							<div className="program-name">
								<a href={`/program/${SeoUrl(podcastInfo.program.name)}-${podcastInfo.program.id}`}>{podcastInfo.program.name}</a>
							</div>
							<div className="program-broadcaster">
								{podcastInfo.broadcasters.map((brd, i) => (
									<a key={i} href={`/programci/${SeoUrl(brd.name)}-${brd.id}`}>
										{brd.name}
									</a>
								))}
							</div>
						</div>
					</>
				)}
			</div>

			<div className="player-middle">
				<button className="prev" onClick={() => prevPodcast()}>
					<ion-icon name="play-back"></ion-icon>
				</button>
				<button className="play" onClick={() => play(isPlay)}>
					{isPlay ? (
						<ion-icon name="pause" class="pause-icon"></ion-icon>
					) : (
						<>
							<ion-icon name="play" class="play-icon"></ion-icon>
							<ion-icon name="play" class="shadow-icon"></ion-icon>
						</>
					)}
				</button>

				<button className="next" onClick={() => nextPodcast()}>
					<ion-icon name="play-forward"></ion-icon>
				</button>

				{podcastInfo && Object.keys(podcastInfo).length > 0 && (
					<AudioPlayer
						autoPlay
						src={`https://upload.radyospor.com/${podcastInfo.file}`}
						ref={playerRef}
						layout="horizontal-reverse"
						customVolumeControls={[]}
						showDownloadProgress={false}
						onPlay={() => onPlay()}
						onListen={(e) => onListen(e)}
						onEnded={() => onEnded()}
						onPause={() => onPause()}
						id="podcast-player"
					/>
				)}

				<VolumeSlider changeVolume={(val) => changeVolume(val)} changeMute={(status) => changeMuteStatus(status)} />
			</div>

			<div className="stream-options">
				{user && Object.keys(user).length > 0 && <Favorite onClick={(status) => setFavorite(status)} favorited={isFavorite} />}

				{Object.keys(podcastInfo).length > 0 && (
					<OutsideClickHandler onOutsideClick={() => setPodcastPopup(false)}>
						<div className="stream-options-podcasts">
							<PodcastPopup
								status={podcastPopup}
								podcasts={podcastInfo}
								currentTime={currentTime}
								duration={duration}
								play={isPlay}
								playerRef={playerRef}
								onPlay={(status) => play(status)}
								setPlayPodcast={setPlayPodcast}
								getPodcast={getPodcast}
								setPlayedPodcast={setPlayedPodcast}
								changePlay={(id) => playHandle(id)}
							/>

							<button className={podcastPopup ? 'opened' : ''} onClick={() => setPodcastPopup(!podcastPopup)}>
								<ion-icon name="menu"></ion-icon>
							</button>
						</div>
					</OutsideClickHandler>
				)}

				<OutsideClickHandler onOutsideClick={() => setSettingsPopup(false)}>
					<div className="stream-options-settings">
						<SettingsPopup
							status={settingsPopup}
							autoPlay={autoPlay}
							setAutoplay={(e) => setAutoplay(e)}
							shuffle={shuffle}
							setShuffle={(e) => setShuffle(e)}
							rewind={rewind}
							setRewind={(e) => setRewind(e)}
							saveSeek={saveSeek}
							setSaveSeek={(e) => setSaveSeek(e)}
							forward={forward}
							setForward={(e) => setForward(e)}
						/>

						<button className={settingsPopup ? 'opened' : ''} onClick={() => setSettingsPopup(!settingsPopup)}>
							<ion-icon name="ellipsis-vertical"></ion-icon>
						</button>
					</div>
				</OutsideClickHandler>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		podcast: state.Play.podcast,
		podcast_play: state.Play.podcast_play,
		podcast_id: state.Play.podcast_id,
	};
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
	const { dispatch } = dispatchProps;
	const { actions: Play } = require('../redux/PlayRedux');

	return {
		...ownProps,
		...stateProps,
		setPlayPodcast: (status) => Play.setPlayPodcast(dispatch, status),
		setPlayedPodcast: (status) => Play.setPlayedPodcast(dispatch, status),
		getPodcastCurrentTime: (time) => Play.getPodcastCurrentTime(dispatch, time),
		getPodcastDurationTime: (time) => Play.getPodcastDurationTime(dispatch, time),
		getPodcast: (id) => Play.getPodcast(dispatch, id),
	};
};

export default connect(mapStateToProps, null, mergeProps)(PlayerPodcast);
