import api from '../api';

const types = {
	// Podcast mi canlı mı?
	SET_PLAYER_TYPE: 'SET_PLAYER_TYPE',

	// Oynatılan podcast
	SET_PLAYED_PODCAST: 'SET_PLAYED_PODCAST',

	// Podcast oynatıldı
	SET_PLAY_PODCAST: 'SET_PLAY_PODCAST',

	// Canlı yayın oynatıldı
	SET_PLAY_LIVE: 'SET_PLAY_LIVE',

	// Podcast bilgileri ve programın diğer podcastleri
	FETCH_PLAYED_PODCAST: 'FETCH_PLAYED_PODCAST',
	FETCH_PLAYED_PODCAST_SUCCESS: 'FETCH_PLAYED_PODCAST_SUCCESS',
	FETCH_PLAYED_PODCAST_FAILURE: 'FETCH_PLAYED_PODCAST_FAILURE',

	// Oynatılan podcastin mevcut süre bilgisi
	PLAYED_PODCAST_CURRENT_TIME: 'PLAYED_PODCAST_CURRENT_TIME',

	// Oynatılan podcastin toplam süre bilgisi
	PLAYED_PODCAST_DURATION: 'PLAYED_PODCAST_DURATION',

	REMOVE_PODCAST: 'REMOVE_PODCAST',
};

export const actions = {
	removePodcast: (dispatch) => dispatch({ type: types.REMOVE_PODCAST }),
	setPlayerType: (dispatch, type) => dispatch({ type: types.SET_PLAYER_TYPE, player_type: type }),
	setPlayedPodcast: (dispatch, id) => dispatch({ type: types.SET_PLAYED_PODCAST, podcast_id: id }),
	setPlayPodcast: (dispatch, status) => dispatch({ type: types.SET_PLAY_PODCAST, podcast_play: status }),
	setPlayLive: (dispatch, status) => dispatch({ type: types.SET_PLAY_LIVE, live_play: status }),
	getPodcast: (dispatch, id) => {
		dispatch({ type: types.FETCH_PLAYED_PODCAST });

		api.getPodcast(id)
			.then((res) => dispatch({ type: types.FETCH_PLAYED_PODCAST_SUCCESS, podcast: res.podcast[0] }))
			.catch((error) => dispatch({ type: types.FETCH_PLAYED_PODCAST_FAILURE, error }));
	},
	getPodcastCurrentTime: (dispatch, time) => dispatch({ type: types.PLAYED_PODCAST_CURRENT_TIME, podcast_current_time: time }),
	getPodcastDurationTime: (dispatch, time) => dispatch({ type: types.PLAYED_PODCAST_DURATION, podcast_duration_time: time }),
};

const initialState = {
	// Her zaman ilk seçim canlı olmalı
	player_type: 'live',
	// Açılışta podcast yok
	podcast_id: 0,
	// Açılışta podcast oynatılmıyor
	podcast_play: false,
	// Açılışta canlı oynatılmıyor
	live_play: false,
	// Podcast Bilgileri
	podcast: {},
	// Podcast mevcut süre
	podcast_current_time: 0,
	// Podcast toplam süre
	podcast_duration_time: 0,
};

export const reducer = (state = initialState, action) => {
	const { error, type, player_type, podcast_id, podcast_play, live_play, podcast, podcast_current_time, podcast_duration_time } = action;

	switch (type) {
		case types.SET_PLAYER_TYPE:
			return { ...state, player_type };
		case types.SET_PLAYED_PODCAST:
			return { ...state, podcast_id };
		case types.SET_PLAY_PODCAST:
			return { ...state, podcast_play };
		case types.SET_PLAY_LIVE:
			return { ...state, live_play };
		case types.FETCH_PLAYED_PODCAST:
			return { ...state, player_type: 'podcast' };
		case types.FETCH_PLAYED_PODCAST_SUCCESS:
			return { ...state, podcast, error };
		case types.FETCH_PLAYED_PODCAST_FAILURE:
			return { ...state, error };
		case types.PLAYED_PODCAST_CURRENT_TIME:
			return { ...state, podcast_current_time };
		case types.PLAYED_PODCAST_DURATION:
			return { ...state, podcast_duration_time };
		case types.REMOVE_PODCAST:
			return { podcast_id: 0, podcast_play: false, podcast: {}, podcast_current_time: 0, podcast_duration_time: 0 };
		default:
			return state;
	}
};

//- ? Podcast mi Canlı mı?
// * Her zaman ilk seçim canlı
// * Podcaste tıklarsam dinleme türü Podcast olmalı
// * Canlıya tıklarsam dinleme türü Canlı olmalı
//- ? Podcast ise hangi podcast?
// * Tıklanan podcastin id'si alınıp api'den podcast bilgileri çağrılmalı.
//- ? Podcast oynatıldı mı?
// * Oynatıldı ise store'da podcast oynatılma durumu yer almalı.
//- ? Oynatıldıysa hangi podcast?
// * Oynatılan podcastin bilgileri her zaman store'da olmalı.
//- ? Podcast oynatılıyorsa programın başka podcastleri var mı?
// * Podcast oynatılma isteği gönderildiğinde api'den programın diğer podcastleri çağrılmalı ve bunu store'dan iletmeli.
//- ? Oynatılan podcastin total süresi ne kadar?
// * Podcast bilgilerinde total süresi yer almalı.
//- ? Oynatılan podcast şu an kaçıncı dakikada?
// * Podcast oynatılmaya başlandığında 'play' eventı o anki bilgiyi dispatch etmeli.
// ? Podcast oynarken başka podcasti oynatmak istersem?
// ! Podcast oynatıldı mı? sorusuna durduruldu yanıtı verilmeli.
// ? Podcast oynarken canlı yayına geçmek istersem?
// * Podcast durdurulmalı, mevcut podcast bilgileri store'dan kaldırılmalı ve dinleme türü canlı olarak güncellenmeli.
// ? Canlı yayındayken podcasti açarsam?
// * Canlı yayın durdurulmalı, mevcut canlı bilgileri store'dan kaldırılmalı ve podcast soruları tekrar sorulmalı.
