/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Helmet } from 'react-helmet';
import { Footer, Header, Programs } from '../components';

const About = () => {
	return (
		<>
			<Helmet>
				<title>Hakkımızda | Radyospor - Türkiye'nin İlk Spor Radyosu</title>
			</Helmet>

			<Header />

			<div className="container">
				<main className="about">
					<div className="row">
						<div className="col-lg-12">
							<div className="section-title">Hakkımızda</div>

							<div className="about-content">
								<p>Radyospor, Türkiye'nin ilk spor radyosudur.</p>
								<p>Yayın akışının tamamı spor programları ve canlı spor yayınları üzerinedir.</p>
								<p>Saran Holding bünyesinde kurulan Radyospor, 14 ilde, uyduda ve internet üzerinden yayın yapmaktadır.</p>
							</div>
						</div>
					</div>

					<Programs />
				</main>
			</div>

			<Footer />
		</>
	);
};

export default About;
