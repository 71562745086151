import React from 'react';

const Footer = (props) => {
	return (
		<footer className="footer">
			<div className="container">
				<div className="footer-menu">
					<nav>
						{[
							{ title: 'Hakkımızda', link: '/hakkimizda' },
							{ title: 'Üyelik Sözleşmesi', link: '/uyelik-sozlesmesi' },
							{ title: 'KVKK Açık Rıza ve Bilgilendirme', link: 'kvkk' },
							{ title: 'Künye', link: '/kunye' },
							{ title: 'İletişim', link: '/iletisim' },
						].map((menu, i) => (
							<a href={menu.link} key={i}>
								{menu.title}
							</a>
						))}
					</nav>
				</div>

				<div className="footer-policy">Veri politikasındaki amaçlarla sınırlı ve mevzuata uygun şekilde çerez konumlandırmaktayız. Detaylar için veri politikamızı inceleyebilirsiniz.</div>
			</div>
		</footer>
	);
};

export default Footer;
