/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';

import { Header, MainSlider, PodcastBig, NewsList, Streaming, PointStatus, Frequencies, Programs, Footer, BroadcasterSlider } from '../components';
import { getToken, getTokenDecoded } from '../utils';

const Home = ({ fetchBroadcasters, broadcasters, fetchPodcasts, podcasts }) => {
	const [type, setType] = useState('last');
	const [options, setOptions] = useState([]);
	const [moreList, setMoreList] = useState(4);
	const [user, setUser] = useState({});

	useEffect(() => {
		fetchBroadcasters();

		if (getToken()) {
			const user = getTokenDecoded(getToken());
			setUser(user);
		}

		if (Object.keys(user).length > 0) {
			setType('listened');
		} else {
			fetchPodcasts(type);
		}

		if (user && Object.keys(user).length > 0) {
			setOptions([
				{ value: 'listened', label: 'Son Dinlenen Podcastler' },
				{ value: 'last', label: 'Son Eklenen Podcastler' },
				{ value: 'most-listened', label: 'En Çok Dinlenen Podcastler' },
			]);
		} else {
			setOptions([
				{ value: 'last', label: 'Son Eklenen Podcastler' },
				{ value: 'most-listened', label: 'En Çok Dinlenen Podcastler' },
			]);
		}
	}, []);

	useEffect(() => {
		fetchPodcasts(type);
	}, [type]);

	const podcastListChange = (type) => {
		setType(type.value);
		setMoreList(4);
	};

	const more = () => {
		setMoreList(moreList + 4);
	};

	return (
		<>
			<Header page="dashboard" />
			{/* <MainSlider /> */}

			<div className="container">
				<section className="last-listened-podcasts">
					<div className="section-title">
						<div className="dashboard-dropdown">
							<Dropdown
								baseClassName="dropdown"
								arrowOpen={<ion-icon name="filter"></ion-icon>}
								arrowClosed={<ion-icon name="filter"></ion-icon>}
								options={options}
								onChange={(e) => podcastListChange(e)}
								value={options[0]}
								placeholder="Filtrele"
							/>
						</div>
					</div>

					<div className="podcast-list">
						<div className="row">
							{podcasts &&
								podcasts.length > 0 &&
								podcasts.slice(0, moreList).map((podcast, i) => <PodcastBig key={i} {...podcast} podcastId={podcast.id} type={type} margin={moreList > 4} />)}
						</div>
						{podcasts && podcasts.length > moreList && (
							<div className="all-podcasts">
								<button onClick={() => more()}>Daha Fazla Göster</button>
							</div>
						)}
					</div>
				</section>

				<main>
					<div className="row">
						<div className="col-lg-8">
							<NewsList />
							<div className="section-seperator"></div>
							<Streaming />
						</div>

						<div className="col-lg-4">
							<aside>
								<PointStatus />
							</aside>
						</div>
					</div>

					<BroadcasterSlider broadcasters={broadcasters} />
					<div className="section-seperator"></div>
					<Frequencies />
					<div className="section-seperator"></div>
					<Programs />
				</main>
			</div>

			<Footer />
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		broadcasters: state.Broadcasters.broadcasters,
		user: state.User.user,
		podcasts: state.Podcasts.podcasts,
	};
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
	const { dispatch } = dispatchProps;
	const { actions: Broadcasters } = require('../redux/BroadcasterRedux');
	const { actions: Podcasts } = require('../redux/PodcastsRedux');

	return {
		...ownProps,
		...stateProps,
		fetchBroadcasters: () => Broadcasters.fetchBroadcasters(dispatch),
		fetchPodcasts: (type) => Podcasts.fetchPodcast(dispatch, type),
	};
};

export default connect(mapStateToProps, null, mergeProps)(Home);
