import React from 'react';
import Broadcaster from './Broadcaster';

const BroadcasterSidebar = ({ favorite, noMargin, broadcasters }) => {
	return (
		<div className={`broadcaster-card ${noMargin ? 'no-margin' : ''}`}>
			<div className="broadcaster-card-title">
				<span>{favorite ? 'Favori ' : ''}Programcılarımız</span>

				{!favorite && <a href="/programcilar">Tümü</a>}
			</div>

			<div className="broadcasters-list">
				{broadcasters && broadcasters?.slice(0, 3).map((broadcaster, i) => <Broadcaster key={i} id={broadcaster.id} name={broadcaster.name} programs={broadcaster.programs} />)}
			</div>
		</div>
	);
};

export default BroadcasterSidebar;
