import React, { useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { SeoUrl, NewsImage } from '../utils';

const NewList = ({ fetchNews, news }) => {
	useState(() => {
		fetchNews();
	});

	return (
		<section className="sport-news">
			<div className="sport-news-title">Spor Haberleri</div>

			<ul>
				{news.length > 0 &&
					news.slice(0, 4).map((list, i) => (
						<li key={i}>
							<a href={`https://ajansspor.com/haber/${SeoUrl(list.Title)}-${list.ContentId}`} target="_blank" rel="noreferrer">
								<div className="news-image">
									<img src={NewsImage(list.MediaId)} alt="" />
								</div>

								<div className="news-detail">
									<div className="news-title">{list.Title}</div>

									<div className="news-date">{moment(list.UpdatedDate).format('DD MMMM YYYY, HH:mm')}</div>
								</div>

								<div className="news-order">
									<span>{i + 1}</span>
								</div>
							</a>
						</li>
					))}
			</ul>

			<div className="more-btn">
				<a href="/haberler">Daha Fazla Göster</a>
			</div>
		</section>
	);
};

const mapStateToProps = (state) => {
	return {
		news: state.News.news,
	};
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
	const { dispatch } = dispatchProps;
	const { actions: News } = require('../redux/NewsRedux');

	return {
		...ownProps,
		...stateProps,
		fetchNews: () => News.fetchNews(dispatch),
	};
};

export default connect(mapStateToProps, null, mergeProps)(NewList);
