/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useState, useEffect } from 'react';
import moment from 'moment';
import { CDN, getToken, getTokenDecoded, SeoUrl } from '../utils';
import { connect } from 'react-redux';

moment.locale('tr');

const formatTime = (seconds) => {
	const h = Math.floor(seconds / 3600);
	const m = Math.floor((seconds % 3600) / 60);
	const s = seconds % 60;
	return [h, m > 9 ? m : h ? '0' + m : m || '0', s > 9 ? s : '0' + s].filter((a) => a).join(':');
};

function getOS() {
	var uA = navigator.userAgent || navigator.vendor || window.opera;
	if ((/iPad|iPhone|iPod/.test(uA) && !window.MSStream) || (uA.includes('Mac') && 'ontouchend' in document)) return 'mobile';

	var i,
		os = ['Windows', 'Android', 'Unix', 'Mac', 'Linux', 'BlackBerry'];
	for (i = 0; i < os.length; i++) if (new RegExp(os[i], 'i').test(uA)) return os[i];
}

const PodcastBig = ({
	podcastId,
	program,
	count,
	detail,
	podcast_id,
	podcast_play,
	setPlayerType,
	setPlayLive,
	setPlayPodcast,
	setPlayedPodcast,
	getPodcast,
	player_type,
	podcasts,
	fetchFavoritePodcasts,
	setFavoritePodcast,
	type,
	name,
	broadcasters,
	date,
	margin,
}) => {
	const [duration, setDuration] = useState(0);
	const [play, setPlay] = useState(false);
	const [user, setUser] = useState({});
	const [favoritePodcasts, setFavoritePodcastList] = useState([]);
	const [isFavorite, setIsFavorite] = useState(false);
	const [modal, setModal] = useState(false);

	useEffect(() => {
		const localpath = `${CDN}/${type === 'listened' ? detail.file : name}`;
		const path = `https://upload.radyospor.com/${type === 'listened' ? detail.file : name}`;

		const au = document.createElement('audio');
		au.src = path;
		au.addEventListener(
			'loadedmetadata',
			function () {
				const dur = parseInt(au.duration, 10);
				setDuration(formatTime(dur));
			},
			false,
		);
	}, []);

	useEffect(() => {
		if (getToken()) {
			const user = getTokenDecoded(getToken());
			setUser(user);

			if (Object.keys(podcasts).length <= 0) {
				fetchFavoritePodcasts(`?userId=${user.id}`);
			}
		}
	}, [podcasts]);

	const setPodcastPlay = (status) => {
		setPlay(!status);

		setPlayLive(false);

		if (player_type !== 'podcast') {
			setPlayerType('podcast');
		}

		if (podcast_id !== podcastId) {
			setPlayPodcast(false);
			setPlay(false);
			getPodcast(podcastId);
		}

		if (play) {
			setPlayPodcast(false);
			setPlay(false);
		} else {
			setPlayPodcast(true);
			setPlay(true);
			setPlayedPodcast(podcastId);
		}
	};

	useEffect(() => {
		if (podcast_id !== podcastId || !podcast_play) {
			setPlay(false);
		}

		if (podcast_id === podcastId && podcast_play) {
			setPlay(true);
		}
	}, [podcast_id, podcast_play]);

	useEffect(() => {
		if (typeof podcasts.podcasts !== 'undefined') {
			setFavoritePodcastList(podcasts.podcasts);
		} else {
			const arr = [];
			podcasts.length > 0 && podcasts.map((pod) => arr.push(pod.id));
			setFavoritePodcastList(arr);
		}
	}, [podcasts]);

	useEffect(() => {
		const find = favoritePodcasts && favoritePodcasts.find((x) => x === podcastId);
		const status = typeof find === 'undefined' ? false : true;
		setIsFavorite(status);
	}, [favoritePodcasts]);

	const setFav = (id) => {
		setIsFavorite(!isFavorite);

		if (Object.keys(user).length > 0) {
			setFavoritePodcast({ podcasts: id, userId: user.id });
		}
	};

	if (duration === 0) return false;

	return (
		<div className="col-md-3 col-sm-6 col-xs-12">
			<div className={`podcast-card podcast-big ${margin ? 'more' : ''}`}>
				<div className="podcast-image">
					<img src={program.image} alt="Podcast" />

					<div className="podcast-play">
						{getOS() === 'mobile' && (
							<button onClick={() => setModal(true)}>
								<ion-icon name="play"></ion-icon>
							</button>
						)}

						{getOS() !== 'mobile' && (
							<button className={play ? 'active' : ''} onClick={() => setPodcastPlay(play)}>
								{play ? <ion-icon name="pause"></ion-icon> : <ion-icon name="play"></ion-icon>}
							</button>
						)}
					</div>
				</div>

				<div className="podcast-detail">
					<div className="podcast-time">{duration}</div>
					<div className="podcast-date">{type === 'listened' ? detail && moment(detail.date).format('DD MMMM YYYY') : moment(date).format('DD MMMM YYYY')}</div>
				</div>

				<div className="podcast-info">
					<div className="podcast-title">
						<a href={`/program/${SeoUrl(program.name)}-${program.id}`}>{program.name}</a>
					</div>
					<div className="podcast-broadcasters">
						{type === 'listened'
							? program.broadcasters &&
							  program.broadcasters.length > 0 &&
							  program.broadcasters.map((broadcaster, i) => (
									<Fragment key={i}>
										<a href={`/programci/${SeoUrl(broadcaster.name)}-${broadcaster.id}`}>{broadcaster.name}</a>
										{program.broadcasters.length > 0 && i < program.broadcasters.length - 1 ? ' & ' : ''}
									</Fragment>
							  ))
							: broadcasters &&
							  broadcasters.length > 0 &&
							  broadcasters.map((broadcaster, i) => (
									<Fragment key={i}>
										<a href={`/programci/${SeoUrl(broadcaster.name)}-${broadcaster.id}`}>{broadcaster.name}</a>
										{broadcasters.length > 0 && i < broadcasters.length - 1 ? ' & ' : ''}
									</Fragment>
							  ))}
					</div>
				</div>

				<div className="podcast-accessibility">
					<div className="podcast-listen-count">
						<ion-icon name="headset"></ion-icon>
						<span>{count}</span>
					</div>

					{user && Object.keys(user).length > 0 && (
						<button className={`podcast-favorite${isFavorite ? ' favorited' : ''}`} onClick={() => setFav(podcastId)}>
							<ion-icon name="star"></ion-icon>
						</button>
					)}

					{/* <button className="podcast-share">
					<ion-icon name="share-social-sharp"></ion-icon>
				</button> */}
				</div>
			</div>

			{modal && (
				<div className="user-modal">
					<div className="modal-inner">
						<div className="modal-header">
							<div className="modal-title">Radyospor Podcast</div>
							<div className="modal-close">
								<button type="button" onClick={() => setModal(false)}>
									<ion-icon name="close-outline"></ion-icon>
								</button>
							</div>
						</div>

						<div className="modal-body">
							<p>Podcastlerimizi dinlemek için Radyospor uygulamasını indirmelisiniz.</p>

							<div className="model-button">
								<a href="https://play.google.com/store/apps/details?id=com.radyospor">
									<img src="https://ajansspor.com/assets/images/icons/play-store.svg" alt="" />
								</a>
								<a href="https://apps.apple.com/tr/app/radyo-spor/id880633790?l=tr#?platform=iphone">
									<img src="https://ajansspor.com/assets/images/icons/app-store.svg" alt="" />
								</a>
							</div>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		podcast_play: state.Play.podcast_play,
		podcast_id: state.Play.podcast_id,
		player_type: state.Play.player_type,
		podcasts: state.Favorites.podcasts,
	};
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
	const { dispatch } = dispatchProps;
	const { actions: Play } = require('../redux/PlayRedux');
	const { actions: Favorites } = require('../redux/FavoriteRedux');

	return {
		...stateProps,
		...ownProps,
		setPlayerType: (type) => Play.setPlayerType(dispatch, type),
		setPlayLive: (type) => Play.setPlayLive(dispatch, type),
		setPlayPodcast: (type) => Play.setPlayPodcast(dispatch, type),
		getPodcast: (id) => Play.getPodcast(dispatch, id),
		setPlayedPodcast: (id) => Play.setPlayedPodcast(dispatch, id),
		fetchFavoritePodcasts: (id) => Favorites.fetchFavoritePodcasts(dispatch, id),
		setFavoritePodcast: (data) => Favorites.setFavoritePodcast(dispatch, data),
	};
};

export default connect(mapStateToProps, null, mergeProps)(PodcastBig);
