import { createStore, compose, applyMiddleware } from 'redux';
import { persistStore, persistCombineReducers } from 'redux-persist';
import { composeWithDevTools } from 'redux-devtools-extension';
//import { createLogger } from 'redux-logger';
import createSagaMiddleware from 'redux-saga';
import storage from 'redux-persist/lib/storage';

import allReducers from '../redux';
import sagas from '../sagas';

const config = {
	key: 'root',
	storage,
	blacklist: ['persist/REHYDRATE', 'Contact', 'Programs', 'Favorites', 'Login', 'Register', 'User', 'LiveStream', 'Podcasts', 'Play'],
	debug: true,
};

const middleware = [];
const sagaMiddleware = createSagaMiddleware();
middleware.push(sagaMiddleware);

if (process.env.NODE_ENV === 'development') {
	//middleware.push(createLogger());
}

const reducers = persistCombineReducers(config, allReducers);
const enhancers = [composeWithDevTools(applyMiddleware(...middleware))];
const persistConfig = { enhancers };
const store = createStore(reducers, undefined, compose(...enhancers));
const persistor = persistStore(store, persistConfig, () => {});

const configureStore = () => {
	return { persistor, store };
};

sagaMiddleware.run(sagas);

export default configureStore;
