import React from 'react';
import Broadcaster from './Broadcaster';

const FavoriteBroadcaster = ({ noMargin, broadcasters, removeFavorite }) => {
	return (
		<div className={`broadcaster-card ${noMargin ? 'no-margin' : ''}`}>
			<div className="broadcaster-card-title">
				<span>Favori Programcılarım</span>
			</div>

			<div className="broadcasters-list">
				{broadcasters &&
					broadcasters.map((broadcaster, i) => (
						<Broadcaster key={i} id={broadcaster.id} name={broadcaster.name} programs={broadcaster.programInfo} favorite removeFavorite={(id) => removeFavorite(id)} />
					))}
			</div>
		</div>
	);
};

export default FavoriteBroadcaster;
