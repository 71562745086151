/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState } from 'react';
import moment from 'moment';
import { CDN, getToken, getTokenDecoded, SeoUrl } from '../utils';
import { connect } from 'react-redux';
import { ColorExtractor } from 'react-color-extractor';

moment.locale('tr');

function getOS() {
	var uA = navigator.userAgent || navigator.vendor || window.opera;
	if ((/iPad|iPhone|iPod/.test(uA) && !window.MSStream) || (uA.includes('Mac') && 'ontouchend' in document)) return 'mobile';

	var i,
		os = ['Windows', 'Android', 'Unix', 'Mac', 'Linux', 'BlackBerry'];
	for (i = 0; i < os.length; i++) if (new RegExp(os[i], 'i').test(uA)) return os[i];
}

const formatTime = (seconds) => {
	const h = Math.floor(seconds / 3600);
	const m = Math.floor((seconds % 3600) / 60);
	const s = seconds % 60;
	return [h, m > 9 ? m : h ? '0' + m : m || '0', s > 9 ? s : '0' + s].filter((a) => a).join(':');
};

const ListenedPodcast = ({
	id,
	image,
	file,
	name,
	title,
	date,
	datetime,
	broadcasters,
	programId,
	podcast,
	setPlayerType,
	setPlayLive,
	getPodcast,
	setPlayedPodcast,
	setPlayPodcast,
	podcast_play,
	podcast_id,
	removePodcast,
	player_type,
	dates,
	currentTime,
}) => {
	const [duration, setDuration] = useState(0);
	const [durat, setDurat] = useState(0);
	const [play, setPlay] = useState(false);
	const [user, setUser] = useState({});
	const [colors, setColors] = useState([]);
	const [modal, setModal] = useState(false);

	useEffect(() => {
		if (getToken()) {
			const user = getTokenDecoded(getToken());
			setUser(user);
		}
	}, []);

	useEffect(() => {
		const localpath = `${CDN}/${file}`;
		const path = `https://upload.radyospor.com/${file}`;

		const au = document.createElement('audio');
		au.src = path;
		au.addEventListener(
			'loadedmetadata',
			function () {
				const dur = parseInt(au.duration, 10);
				setDuration(formatTime(dur));
				setDurat(dur);
			},
			false,
		);
	}, []);

	const setPodcastPlay = () => {
		setPlayLive(false);

		if (player_type !== 'podcast') {
			setPlayerType('podcast');
		}

		if (podcast_id !== id) {
			setPlayPodcast(false);
			setPlay(false);
			getPodcast(id);
		}

		if (play) {
			setPlayPodcast(false);
			setPlay(false);
		} else {
			setPlayPodcast(true);
			setPlay(true);
			setPlayedPodcast(id);
		}
	};

	useEffect(() => {
		if (podcast_id !== id || !podcast_play) {
			setPlay(false);
		}

		if (podcast_id === id && podcast_play) {
			setPlay(true);
		}
	}, [podcast_id, podcast_play]);

	const getColors = (colors) => setColors(colors);

	const percentage = (duration, current) => {
		return Math.round((current * 100) / duration);
	};

	return (
		<div className="listened-podcast">
			{dates && <div className="podcast-listened-progress" style={{ width: `${percentage(durat, currentTime)}%`, backgroundColor: colors[0] }}></div>}

			<div className="podcast-image">
				<ColorExtractor getColors={getColors}>
					<img src={image} alt="" />
				</ColorExtractor>
			</div>

			<div className="podcast-info">
				{duration !== 0 && <div className="podcast-time">{duration}</div>}

				<div className="podcast-title">
					<a href={`/program/${SeoUrl(name)}-${programId}`}>{name}</a>
					{dates && dates.length > 0 && (
						<small className="podcast-dates">
							Bu podcasti ilk kez{' '}
							{dates.length === 1
								? moment(dates[0].date, 'DD-MM-YYYY').format('DD MMMM YYYY')
								: `${moment(dates[0].date, 'DD-MM-YYYY').format('DD MMMM YYYY')}, son kez ${moment(dates[dates.length - 1].date, 'DD-MM-YYYY').format('DD MMMM YYYY')}`}{' '}
							tarihinde dinlediniz.
						</small>
					)}
				</div>

				<div className="podcast-broadcasters">
					{broadcasters &&
						broadcasters.length > 0 &&
						broadcasters.map((broadcaster, i) => (
							<Fragment key={i}>
								<a href={`/programci/${SeoUrl(broadcaster.name)}-${broadcaster.id}`}>{broadcaster.name}</a>
								{broadcasters.length > 0 && i < broadcasters.length - 1 ? ' & ' : ''}
							</Fragment>
						))}
				</div>
			</div>

			<div className="podcast-date">{moment(date).format('DD.MM.YYYY')}</div>
			<div className="podcast-play">
				{getOS() !== 'mobile' && <button onClick={() => setPodcastPlay()}>{play ? <ion-icon name="pause"></ion-icon> : <ion-icon name="play"></ion-icon>}</button>}

				{getOS() === 'mobile' && <button onClick={() => setModal(true)}>{<ion-icon name="play"></ion-icon>}</button>}
			</div>

			{modal && (
				<div className="user-modal">
					<div className="modal-inner">
						<div className="modal-header">
							<div className="modal-title">Radyospor Podcast</div>
							<div className="modal-close">
								<button type="button" onClick={() => setModal(false)}>
									<ion-icon name="close-outline"></ion-icon>
								</button>
							</div>
						</div>

						<div className="modal-body">
							<p>Podcastlerimizi dinlemek için Radyospor uygulamasını indirmelisiniz.</p>

							<div className="model-button">
								<a href="https://play.google.com/store/apps/details?id=com.radyospor">
									<img src="https://ajansspor.com/assets/images/icons/play-store.svg" alt="" />
								</a>
								<a href="https://apps.apple.com/tr/app/radyo-spor/id880633790?l=tr#?platform=iphone">
									<img src="https://ajansspor.com/assets/images/icons/app-store.svg" alt="" />
								</a>
							</div>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		podcast: state.Play.podcast,
		user: state.User.user,
		podcast_play: state.Play.podcast_play,
		podcast_id: state.Play.podcast_id,
		player_type: state.Play.player_type,
	};
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
	const { dispatch } = dispatchProps;
	const { actions: Play } = require('../redux/PlayRedux');

	return {
		...stateProps,
		...ownProps,
		setPlayerType: (type) => Play.setPlayerType(dispatch, type),
		setPlayLive: (type) => Play.setPlayLive(dispatch, type),
		setPlayPodcast: (type) => Play.setPlayPodcast(dispatch, type),
		getPodcast: (id) => Play.getPodcast(dispatch, id),
		setPlayedPodcast: (id) => Play.setPlayedPodcast(dispatch, id),
		removePodcast: () => Play.removePodcast(dispatch),
	};
};

export default connect(mapStateToProps, null, mergeProps)(ListenedPodcast);
