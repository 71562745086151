import api from '../api';

const types = {
	FETCH_LIVE_STREAM: 'FETCH_LIVE_STREAM',
	FETCH_LIVE_STREAM_SUCCESS: 'FETCH_LIVE_STREAM_SUCCESS',
	FETCH_LIVE_STREAM_FAILURE: 'FETCH_LIVE_STREAM_FAILURE',
};

export const actions = {
	fetchLiveStreaming: (dispatch, day) => {
		//dispatch({ type: types.FETCH_LIVE_STREAM });

		api.live(day)
			.then((res) => dispatch({ type: types.FETCH_LIVE_STREAM_SUCCESS, live: res[0] }))
			.catch((error) => dispatch({ type: types.FETCH_LIVE_STREAM_FAILURE, error }));
	},
};

const initialState = {
	isFetching: true,
	error: null,
	live: {},
};

export const reducer = (state = initialState, action) => {
	const { error, type, live } = action;

	switch (type) {
		case types.FETCH_LIVE_STREAM:
			return { ...initialState };
		case types.FETCH_LIVE_STREAM_SUCCESS:
			return { ...state, live, isFetching: false, error };
		case types.FETCH_LIVE_STREAM_FAILURE:
			return { ...state, error, isFetching: false };
		default:
			return state;
	}
};
