/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Footer, Header, Programs } from '../components';

const Masthead = ({ settings }) => {
	return (
		<>
			<Helmet>
				<title>Künye | Radyospor - Türkiye'nin İlk Spor Radyosu</title>
			</Helmet>

			<Header />

			<div className="container">
				<main className="masthead">
					<div className="row">
						<div className="col-lg-12">
							<div className="section-title">Künye</div>

							<div className="masthead-content">
								{settings && (
									<ul>
										<li>
											<span>Yönetim Yeri ve Adresi</span>
											<span>
												<a href={settings.location} target="_blank" rel="noreferrer">
													{settings.address}
												</a>
											</span>
										</li>

										<li>
											<span>Telefon No</span>
											<span>
												<a href={`tel:${settings.phone && settings.phone.replace(/ /g, '')}`}>{settings.phone}</a>
											</span>
										</li>

										<li>
											<span>Fax No</span>
											<span>{settings.fax}</span>
										</li>
										<li>
											<span>E-posta</span>
											<span>
												<a href={`mailto:${settings.email}`}>{settings.email}</a>
											</span>
										</li>

										<li>
											<span>Genel Yayın Yönetmeni ve Sorumlu Müdür</span>
											<span>Barış Ertül</span>
											<span>İletişim: 0212 363 0404</span>
											<span>Eposta Adresi: barisertul@radyospor.com</span>
										</li>
										<li>
											<span>Haber Müdürü</span>
											<span>M. Özgür Sancar</span>
										</li>
										<li>
											<span>İnternet Sitesi Sorumlusu</span>
											<span>Murat Duymuş</span>
										</li>
										<li>
											<span>Reklam Satış</span>
											<span>Mustafa Kıyıcıoğlu</span>
											<span>mustafa.kiyicioglu@saran.com.tr</span>
										</li>
										<br />
										<br />
										<li>
											<span>Ticaret Sicil Bilgileri ve Adres</span>
											<span>Firma Adı: Tatlıses Radyo TV ve Medya Hiz. A.Ş.</span>
											<span>{settings.address}</span>
											<span>KEP Adresi: tatlisesradyo@hs03.kep.tr</span>
										</li>
										<li>
											<span>Vergi Dairesi ve Numarası</span>
											<span>Sarıyer Vergi Dairesi / 8310040625</span>
										</li>
										<li>
											<span>Yayın Lisans Bilgileri ve Yayın Ortamları</span>
											<span>(1) Uydu Radyo Yayın Lisansı (U-RD)</span>
											<span>(2) Ulusal Karasal Radyo Yayın Lisansı (R1)</span>
											<span>(3) İnternet Ortamında Radyo Yayın İzni (İnternet RD)</span>
										</li>
									</ul>
								)}
							</div>
						</div>
					</div>

					<Programs />
				</main>
			</div>

			<Footer />
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		settings: state.Settings.settings,
	};
};

export default connect(mapStateToProps)(Masthead);
