/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Header, Streaming as StreamList, Footer, PointStatus, NewsList, Frequencies as Frequency, Programs, BroadcasterSidebar } from '../components';

const Frequencies = ({ fetchFrequencies, frequencies, fetchBroadcasters, broadcasters }) => {
	useEffect(() => {
		fetchFrequencies();
		fetchBroadcasters();
	}, []);

	return (
		<>
			<Helmet>
				<title>Frekanslar | Radyospor - Türkiye'nin İlk Spor Radyosu</title>
			</Helmet>

			<Header page="frequencies" />

			<div className="container">
				<div className="section-title">
					<h1>Frekanslar</h1>
				</div>

				<Frequency hideTitle />

				<div className="frequency-list">
					<ul>
						{frequencies.length > 0 &&
							frequencies
								.filter((x) => x.name !== 'Uydu')
								.map((frequency, i) => (
									<li key={i}>
										<div className="location-name">{frequency.name}</div>
										<div className="location-frequency">{frequency.value}</div>
									</li>
								))}
					</ul>
				</div>

				<main>
					<div className="row">
						<div className="col-lg-8">
							{/* <section className="listened-podcasts">
								<div className="listened-podcast-head">
									<div className="listened-podcast-title">Son Dinlenen Podcastler</div>
									<a href="#!">Tümü</a>
								</div>

								<div className="listened-podcast-list">
									{[...Array(5)].map((_, i) => (
										<ListenedPodcast key={i} />
									))}
								</div>
							</section> */}
							{/* <div className="section-seperator"></div> */}
							<NewsList />
							<div className="section-seperator"></div>
							<StreamList />
						</div>

						<div className="col-lg-4">
							<aside>
								<PointStatus />

								<BroadcasterSidebar broadcasters={broadcasters} />
							</aside>
						</div>
					</div>

					<Programs />
				</main>
			</div>

			<Footer />
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		frequencies: state.Frequencies.frequencies,
		broadcasters: state.Broadcasters.broadcasters,
	};
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
	const { dispatch } = dispatchProps;
	const { actions: Frequencies } = require('../redux/FrequencyRedux');
	const { actions: Broadcasters } = require('../redux/BroadcasterRedux');

	return {
		...ownProps,
		...stateProps,
		fetchFrequencies: () => Frequencies.fetchFrequencies(dispatch),
		fetchBroadcasters: () => Broadcasters.fetchBroadcasters(dispatch),
	};
};

export default connect(mapStateToProps, null, mergeProps)(Frequencies);
