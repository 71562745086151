/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getToken, getTokenDecoded } from '../utils';
import PlayerLive from './PlayerLive';
import PlayerPodcast from './PlayerPodcast';

const Player = ({
	player_type,
	setPlayerType,
	setPlayLive,
	setPlayPodcast,
	setPlayedPodcast,
	fetchFavoritePrograms,
	setFavoriteProgram,
	programs,
	fetchFavoritePodcasts,
	setFavoritePodcast,
	podcasts,
	podcast_id,
	live,
}) => {
	const [user, setUser] = useState({});
	const [favoritePrograms, setFavoritePrograms] = useState([]);
	const [favoritePodcasts, setFavoritePodcasts] = useState([]);

	useEffect(() => {
		if (getToken()) {
			const user = getTokenDecoded(getToken());
			setUser(user);
		}
	}, []);

	useEffect(() => {
		if (Object.keys(user).length > 0) {
			if (Object.keys(programs).length <= 0) {
				fetchFavoritePrograms(`?userId=${user.id}`);
			}

			if (Object.keys(podcasts).length <= 0) {
				fetchFavoritePodcasts(`?userId=${user.id}`);
			}
		}
	}, [user]);

	useEffect(() => {
		if (Object.keys(user).length > 0) {
			fetchFavoritePodcasts(`?userId=${user.id}`);
		}
	}, [podcast_id]);

	const changePlayerType = (type) => {
		// canlı yayına geçiş
		setPlayerType(type);
		setPlayPodcast(false);
		setPlayLive(true);
		setPlayedPodcast(0);
	};

	useEffect(() => {
		if (typeof programs.programs !== 'undefined') {
			setFavoritePrograms(programs.programs);
		} else {
			const arr = [];
			programs.length > 0 && programs.map((prg) => arr.push(prg.id));
			setFavoritePrograms(arr);
		}
	}, [programs]);

	const setFavoritePrg = (data) => {
		setFavoriteProgram(data);
	};

	useEffect(() => {
		if (typeof podcasts.podcasts !== 'undefined') {
			setFavoritePodcasts(podcasts.podcasts);
		} else {
			const arr = [];
			podcasts.length > 0 && podcasts.map((pod) => arr.push(pod.id));
			setFavoritePodcasts(arr);
		}
	}, [podcasts]);

	const setFavoritePod = (data) => setFavoritePodcast(data);

	return player_type === 'live' ? (
		typeof live !== 'undefined' && <PlayerLive user={user} favoritePrograms={favoritePrograms} setFavoriteProgram={(data) => setFavoritePrg(data)} />
	) : (
		<>
			{typeof live !== 'undefined' && (
				<div className="upto-live" onClick={() => changePlayerType('live')}>
					<p>{Object.keys(live).length > 0 && live?.programInfo?.name} programı şu an canlı yayında. Canlı yayına dönmek için tıklayın.</p>
				</div>
			)}
			<PlayerPodcast user={user} favoritePodcasts={favoritePodcasts} setFavoritePodcasts={(data) => setFavoritePod(data)} />
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		player_type: state.Play.player_type,
		podcast_id: state.Play.podcast_id,
		podcast_play: state.Play.podcast_play,
		live_play: state.Play.live_play,
		podcast: state.Play.podcast,
		podcast_total_time: state.Play.podcast_total_time,
		podcast_duration_time: state.Play.podcast_duration_time,
		programs: state.Favorites.programs,
		podcasts: state.Favorites.podcasts,
		live: state.LiveStream.live,
	};
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
	const { dispatch } = dispatchProps;
	const { actions: Play } = require('../redux/PlayRedux');
	const { actions: Favorites } = require('../redux/FavoriteRedux');

	return {
		...ownProps,
		...stateProps,
		setPlayerType: (type) => Play.setPlayerType(dispatch, type),
		setPlayedPodcast: (id) => Play.setPlayedPodcast(dispatch, id),
		setPlayPodcast: (status) => Play.setPlayPodcast(dispatch, status),
		setPlayLive: (status) => Play.setPlayLive(dispatch, status),
		getPodcast: (id) => Play.getPodcast(dispatch, id),
		getPodcastTotalTime: (time) => Play.getPodcastTotalTime(dispatch, time),
		getPodcastDurationTime: (time) => Play.getPodcastDurationTime(dispatch, time),
		fetchFavoritePrograms: (id) => Favorites.fetchFavoritePrograms(dispatch, id),
		setFavoriteProgram: (data) => Favorites.setFavoriteProgram(dispatch, data),
		fetchFavoritePodcasts: (id) => Favorites.fetchFavoritePodcasts(dispatch, id),
		setFavoritePodcast: (data) => Favorites.setFavoritePodcast(dispatch, data),
	};
};

export default connect(mapStateToProps, null, mergeProps)(Player);
