import api from '../api';
import { getTokenDecoded } from '../utils';

const types = {
	FETCH_USER_REGISTER: 'FETCH_USER_REGISTER',
	FETCH_USER_REGISTER_SUCCESS: 'FETCH_USER_REGISTER_SUCCESS',
	FETCH_USER_REGISTER_FAILURE: 'FETCH_USER_REGISTER_FAILURE',
};

export const actions = {
	fetchRegisterUser: (dispatch, data) => {
		dispatch({ type: types.FETCH_USER_REGISTER });

		api.client().then((res) => {
			api.register(data).then((res) => {
				if (res.success) {
					dispatch({ type: types.FETCH_USER_REGISTER_SUCCESS, user: { ...getTokenDecoded(res.token), token: res.token } });
				} else {
					dispatch({ type: types.FETCH_USER_REGISTER_FAILURE, error: res.message });
				}
			});
		});
	},
};

const initialState = {
	isFetching: false,
	error: null,
	user: {},
};

export const reducer = (state = initialState, action) => {
	const { error, type, user } = action;

	switch (type) {
		case types.FETCH_USER_REGISTER:
			return { ...state, isFetching: true };

		case types.FETCH_USER_REGISTER_SUCCESS:
			return { ...state, user, isFetching: false, error };

		case types.FETCH_USER_REGISTER_FAILURE:
			return { ...state, error, isFetching: false };

		default:
			return state;
	}
};
