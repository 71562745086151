import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import InputMask from 'react-input-mask';
import { Oval } from 'react-loader-spinner';
import PasswordStrengthBar from 'react-password-strength-bar';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet';
import Avatar from 'react-avatar';

import { Footer, Header } from '../components';
import { getToken, getTokenDecoded, setToken } from '../utils';

moment.locale('tr');

const validationSchema = Yup.object({
	fullname: Yup.string().required('Adınız boş olamaz.'),
	username: Yup.string().required('Kullanıcı adınız boş olamaz.'),
	birthday: Yup.string(),
	gender: Yup.string(),
	password: Yup.string().min(8, 'Şifre en az 8 karakter olmalıdır.'),
	passwordConfirm: Yup.string()
		.min(8, 'Şifre en az 8 karakter olmalıdır.')
		.oneOf([Yup.ref('password'), null], 'Şifreler eşit olmalı.'),
});

const ProfileSettings = ({ update, updatedUser }) => {
	const [user, setUser] = useState({});
	const [status, setStatus] = useState(false);
	const [errorType, setErrorType] = useState(null);
	const [errorMessage, setErrorMessage] = useState('');
	const [visiblePassword, setVisiblePassword] = useState(false);

	useEffect(() => {
		const user = getTokenDecoded(getToken());
		setUser(user);
	}, []);

	useEffect(() => {
		if (Object.keys(updatedUser).length > 0) {
			setStatus(false);

			if (updatedUser.success) {
				setToken(updatedUser.token);

				toast.success(updatedUser.message, {
					position: 'bottom-center',
					autoClose: 3000,
					hideProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					closeButton: false,
				});

				setTimeout(() => {
					window.location.reload();
				}, 3000);
			} else {
				toast.error(updatedUser.message, {
					position: 'bottom-center',
					autoClose: 3000,
					hideProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					closeButton: false,
				});

				typeof updatedUser.error_type !== 'undefined' && setErrorType(updatedUser.error_type);
				setErrorMessage(updatedUser.message);
			}
		}
	}, [updatedUser]);

	const { handleSubmit, handleChange, values, errors } = useFormik({
		enableReinitialize: true,
		initialValues: {
			fullname: user.fullname || '',
			username: user.username || '',
			email: user.email || '',
			birthday: user.birthday || moment().format('DD/MM/YYYY'),
			gender: user.gender || '',
			password: '',
			passwordConfirm: '',
		},
		validationSchema,
		onSubmit: async (values) => {
			setStatus(true);
			update({ ...values, id: user.id, loginType: user.loginType });
		},
	});

	return (
		<>
			<Helmet>
				<title>Profil Ayarları | Radyospor - Türkiye'nin İlk Spor Radyosu</title>
			</Helmet>

			<Header />

			<div className="container">
				<main className="profile">
					<div className="row">
						<div className="col-lg-12">
							<div className="profile-form">
								<div className="user-profile-image">
									<div className="profile-setting__image">
										<Avatar name={user.fullname || user.username} size={120} />
									</div>
								</div>

								<form action="" onSubmit={handleSubmit}>
									<div className={`form-group w100`}>
										<label htmlFor="fullname">Ad soyad</label>
										<input type="text" id="fullname" name="fullname" placeholder="" onChange={handleChange} value={values.fullname} className={errors.fullname && 'error'} />
										<small>{errors.fullname}</small>
									</div>

									<div className={`form-group`}>
										<label htmlFor="username">Kullanıcı adı</label>
										<input
											type="text"
											id="username"
											name="username"
											placeholder=""
											onChange={handleChange}
											value={values.username}
											className={errors.username || errorType === 'username' ? 'error' : ''}
											disabled={user.isUsernameChanged}
										/>
										{user.isUsernameChanged && <small>Kullanıcı adınızı daha önce değiştirdiniz.</small>}

										<small>
											{errors.username} {errorType && errorType === 'username' && errorMessage}
										</small>
									</div>

									<div className={`form-group`}>
										<label htmlFor="email">Eposta adresi</label>
										<input
											type="text"
											id="email"
											name="email"
											placeholder=""
											onChange={handleChange}
											value={values.email}
											className={errors.email || errorType === 'email' ? 'error' : ''}
										/>
										<small>
											{errors.username} {errorType && errorType === 'email' && errorMessage}
										</small>
									</div>

									<div className={`form-group`}>
										<label htmlFor="birthday">Doğum tarihi</label>
										<InputMask
											mask="99/99/9999"
											type="text"
											id="birthday"
											name="birthday"
											placeholder="gg/aa/yyyy"
											onChange={handleChange}
											value={values.birthday}
											className={errors.birthday && 'error'}
										/>
									</div>

									<div className="form-group select-group">
										<label htmlFor="gender">Cinsiyet</label>
										<select name="gender" id="gender" onChange={handleChange} value={values.gender}>
											<option value="Belirtmek istemiyorum">Belirtmek istemiyorum</option>
											<option value="Erkek">Erkek</option>
											<option value="Kadın">Kadın</option>
										</select>
									</div>

									<div className={`form-group`}>
										<label htmlFor="password">Şifre</label>
										<input
											type={visiblePassword ? 'text' : 'password'}
											id="password"
											name="password"
											placeholder="********"
											onChange={handleChange}
											value={values.password}
											className={errors.password && 'error'}
										/>

										<div className="password-visibler" onClick={() => setVisiblePassword(!visiblePassword)}>
											{visiblePassword ? <ion-icon name="eye-off-outline"></ion-icon> : <ion-icon name="eye-outline"></ion-icon>}
										</div>

										{values.password.length > 0 && (
											<PasswordStrengthBar
												className="password-checker"
												shortScoreWord="Çok kısa"
												scoreWords={['Zayıf', 'İyi', 'Güzel', 'Güçlü', 'Mükemmel']}
												password={values.password}
											/>
										)}

										<small>{errors.password}</small>
									</div>

									<div className={`form-group`}>
										<label htmlFor="passwordConfirm">Şifre (tekrar)</label>
										<input
											type={visiblePassword ? 'text' : 'password'}
											id="passwordConfirm"
											name="passwordConfirm"
											placeholder="********"
											onChange={handleChange}
											value={values.passwordConfirm}
											className={errors.passwordConfirm && 'error'}
										/>

										<div className="password-visibler" onClick={() => setVisiblePassword(!visiblePassword)}>
											{visiblePassword ? <ion-icon name="eye-off-outline"></ion-icon> : <ion-icon name="eye-outline"></ion-icon>}
										</div>

										<small>{errors.passwordConfirm}</small>
									</div>

									<button type="submit">{status ? <Oval color="#fff" height={32} width={32} wrapperStyle={{ margin: '0 auto' }} /> : 'Güncelle'}</button>
								</form>
							</div>
						</div>
					</div>
				</main>
			</div>

			<Footer />
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		updatedUser: state.User.user,
	};
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
	const { dispatch } = dispatchProps;
	const { actions: User } = require('../redux/UserRedux');

	return {
		...ownProps,
		...stateProps,
		update: (data) => User.update(dispatch, data),
	};
};

export default connect(mapStateToProps, null, mergeProps)(ProfileSettings);
