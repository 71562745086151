/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Helmet } from 'react-helmet';
import { Footer, Header, Programs } from '../components';

const MembershiAgreement = () => {
	return (
		<>
			<Helmet>
				<title>Üyelik Sözleşmesi | Radyospor - Türkiye'nin İlk Spor Radyosu</title>
			</Helmet>
			<Header />

			<div className="container">
				<main className="membership-agreement">
					<div className="row">
						<div className="col-lg-12">
							<div className="section-title">Üyelik Sözleşmesi</div>

							<div className="membership-agreement-content">
								TATLISES RADYO TELEVİZYON VE MEDYA HİZMETLERİ A.Ş. - ÜYELİK SÖZLEŞMESİ
								<br />
								<br />
								MADDE 1-TARAFLAR:
								<br />
								İşbu Üyelik Sözleşmesi ("Sözleşme" olarak anılacaktır);
								<br />
								Merkezi PTT Evleri Mah. Hacıosman Bayırı Cad. No:16, 34453, Sarıyer/İSTANBUL adresinde bulunan TATLISES RADYO TELEVİZYON VE MEDYA HİZMETLERİ A.Ş. ("RADYOSPOR" olarak
								anılacaktır ) ile, “Üye” (aşağıda tanımlı) arasında aşağıdaki hüküm ve şartlar kapsamında akdedilmektedir. İşbu Sözleşme kapsamında RADYOSPOR ve Üye birlikte,
								“Taraflar” olarak anılacaktır.
								<br />
								MADDE 2-TANIMLAR
								<br />
								İşbu Sözleşme’de geçen tanımlardan;
								<br />
								<br />
								<br /> 2.1. Üye: İşbu Sözleşme'yi kabul ederek, RADYOSPOR tarafından RADYOSPOR Platformları’nda sunulan çeşitli özelliklerden, bir Kullanıcı Adı ve Şifre vasıtasıyla
								faydalanacak internet kullanıcısını ifade eder.
								<br />
								<br /> 2.2. Kullanıcı Adı: Her bir RADYOSPOR Üyesi için farklı olacak şekilde, RADYOSPOR Platformları’na üyelik sırasında belirlenen ayırt edici ismi ifade eder.
								<br />
								<br /> 2.3 Şifre: Üyenin, RADYOSPOR Platformları’na üyelik sırasında belirleyeceği ve RADYOSPOR Platformları’ndaki Hesabı’na erişebilmesini sağlayan şifreyi ifade eder.
								<br />
								<br /> 2.4. İçerik(ler): RADYOSPOR Platformları’nda yer alan her türlü haber, yazı, metin, makale, bilgi, fotoğraf, video, görüntü, simge, grafik, istatistik, canlı
								skor, logo, Üye İçerikleri dahil ancak bunlarla sınırlı olmamak kaydıyla; her türlü görsel-işitsel içeriği ifade eder.
								<br />
								<br /> 2.5. RADYOSPOR Web Sitesi: Tüm hakları RADYOSPOR’a ait olan; mobil cihaz, tablet, bilgisayar, akıllı televizyon dahil ve bunlarla sınırlı olmaksızın
								internet/dijital iletim metodunu kullanan her türlü cihazdan ulaşılabilen ve İçerik’e erişim imkanı sunan ............................. alan adı ve diğer alt alan
								adlarından erişilebilen web sitesini ifade eder.
								<br />
								<br /> 2.6. RADYOSPOR Uygulaması: Tüm hakları RADYOSPOR’a ait olan; mobil cihaz, tablet, akıllı televizyon dahil ve bunlarla sınırlı olmaksızın internet/dijital iletim
								metodunu kullanan her türlü cihaza indirilmiş/yüklenmiş veya indirilmek/yüklenmek suretiyle İçerik’e erişim imkanı sunan “RADYOSPOR” veya ileride RADYOSPOR tarafından
								belirlenecek diğer isimli dijital uygulamayı ifade eder.
								<br />
								<br /> 2.7. RADYOSPOR Platformları: RADYOSPOR Web Sitesi ve RADYOSPOR Uygulaması’nı ifade eder.
								<br />
								<br /> 2.8. Ziyaretçi: RADYOSPOR Platformları’na Üye olmayan, ancak RADYOSPOR Platformları’nı ziyaret eden internet kullanıcısını ifade eder.
								<br />
								<br /> 2.9. Hesap: Üye’nin, RADYOSPOR Platformları’nda sunulan çeşitli özelliklerden faydalanmak için Kullanıcı Adı ve Şifre ile giriş yapacağı oturumu ifade eder.
								<br />
								<br /> 2.10. Kayıt Formu: Üye’nin, RADYOSPOR Platformları’na üye olurken girdiği isim, soyisim, e-posta adresini ifade eder.
								<br />
								<br /> 2.11. Üçüncü Şahıs Hesapları: Üye’nin, RADYOSPOR Platformları’na üye olurken Kullanıcı Adı ve Şifre belirlemek yerine; halihazırda kendisine ait olan Twitter
								ve/veya Facebook gibi sosyal medya hesapları veya Gmail, Hotmail gibi kullanıcı hesapları aracılığıyla üyelik oluşturmak istemesi halinde, RADYOSPOR Platformları’nda
								üyelik oluşturmak için kullanacağı üçüncü şahıs hesap bilgilerini ifade eder.
								<br />
								<br /> 2.12. Kişisel Veriler: Kimliği belirli veya belirlenebilir gerçek kişiye ilişkin her türlü bilgiyi ifade eder.
								<br />
								<br /> 2.13. Kişisel Verilerin İşlenmesi: Kişisel Veriler’in tamamen veya kısmen otomatik olan ya da herhangi bir veri kayıt sisteminin parçası olmak kaydıyla otomatik
								olmayan yollarla elde edilmesi, kaydedilmesi, depolanması, muhafaza edilmesi, değiştirilmesi, yeniden düzenlenmesi, açıklanması, aktarılması, devralınması, elde
								edilebilir hâle getirilmesi, sınıflandırılması ya da kullanılmasının engellenmesi gibi veriler üzerinde gerçekleştirilen her türlü işlemi ifade eder.
								<br />
								<br /> MADDE 3-SÖZLEŞMENİN AMAÇ VE KONUSU:
								<br />
								<br /> İşbu Sözleşme, Üye’nin RADYOSPOR tarafından RADYOSPOR Platformları’nda sunulan çeşitli özelliklerden yararlanmasını sağlamak amacı ile düzenlenmekte olup, anılan
								ilişki kapsamında Taraflar’ın hak ve yükümlülüklerini tespit etmektedir.
								<br />
								<br /> MADDE 4- SÖZLEŞME’NİN KURULMASI
								<br />
								<br /> Taraflar, Üye’nin Kullanıcı Adı ve Şifre ile RADYOSPOR Platformları’nda Hesap açıp, işbu Sözleşme’nin altında yer alan ‘’Kabul Ediyorum’’/“Onaylıyorum” butonuna
								tıklaması ile birlikte bu Sözleşme’yi kabul etmiş olacağını, söz konusu kabul ile üyelik işleminin tamamlanacağını, Üye’nin kabul beyanının RADYOSPOR kayıtlarına geçmiş
								olacağını ve Sözleşme’nin bu anda kurularak yürürlüğe girmiş olacağını kabul beyan ve taahhüt ederler.
								<br />
								<br /> MADDE 5- İÇERİK
								<br />
								<br /> 5.1. RADYOSPOR Platformları’ndaki İçerikler hem Ziyaretçi hem de Üye tarafından erişilebilir ve faydalanılabilir. RADYOSPOR Platformları’nda yer alan İçerikler’e
								yorum eklenebilmesi, belli İçerikler’in veya yorumların favorilere eklenebilmesi, içeriklerin beğenilebilmesi, RADYOSPOR tarafından düzenlenecek anket, kampanya,
								yarışma ve oyunlara katılım hakkına sahip olunabilmesi, opsiyonel olarak takım bilgisi ve/veya seçilen ilgi alanlarına istinaden haber ve verilere, spor datalarına daha
								hızlı erişim sağlanması, bu kapsamda kişiye özelleştirilmiş bildirimlerin alınabilmesi olanakları ise yalnızca Üye’nin kullanımı içindir ve sadece bu Sözleşme’nin
								koşullarına uygun olarak faydalanılabilir.
								<br />
								<br /> 5.2. Söz konusu RADYOSPOR Platformları’nda bulunan İçerikler RADYOSPOR’un önceden yazılı olarak izni alınmadan kısmen veya tamamen kopya edilemez, dağıtılamaz,
								çoğaltılamaz, kullandırılamaz, değiştirilemez ve ileride kullanılmak üzere saklanamaz. Aksi halde; RADYOSPOR tarafından tüm hukuki ve cezai işlemleri başlatma ve
								taleplerde bulunma hakkı saklıdır. Tüm RADYOSPOR Platformları İçerikler’i münhasıran RADYOSPOR’un fikri ve sınai mülkiyetindedir. RADYOSPOR Platformları’nda yer alan
								İçerikler’in tüm hakları saklıdır.
								<br />
								<br /> 5.3. RADYOSPOR Platformları ve İçerikler’in içeriğine, tasarımına ve yazılımına ilişkin tüm mali hakların izinsiz kullanımı, işleme, çoğaltma, yayma, temsil,
								kopyalama, işleme ve internet başta olmak üzere radyo-televizyon, uydu ve kablo gibi telli veya telsiz yayın yapan kuruluşlar vasıtasıyla veya dijital iletim de dahil,
								işaret, ses ve/veya görüntü nakline yarayan araçlar ve bunlarla sınırlı kalmamak kaydıyla her türlü araçla yayınlama ve umuma arz, ve bunlarla sınırlı olmamak üzere her
								türlü izinsiz kullanım ve/veya ifşa, RADYOSPOR’un fikri ve sınai mülkiyet hakkının ihlali anlamına gelecektir.
								<br />
								<br /> 5.4. Üye, RADYOSPOR Platformları’nı kullanırken Türk Ceza Kanunu, Türk Ticaret Kanunu, Borçlar Kanunu, Fikir ve Sanat Eserleri Kanunu, Marka, Tasarım ve Patent
								Haklarının Korunması, Kişisel Verilerin Korunması Kanunu ile ilgili kanun hükmünde kararnameler ve yasal düzenlemeler, halihazırda yürürlükte olan ilgili her türlü
								mevzuat hükümleri, Uluslararası Sözleşmeler ile ileride yürürlüğe girecek olan mevzuata ve RADYOSPOR’un RADYOSPOR Uygulaması’na ilişkin olarak yayımlayacağı
								bildirimlere uymayı kabul eder. Bildirimlere ve yasalara aykırı kullanım sebebiyle doğabilecek hukuki, cezai ve mali her türlü sorumluluk Üye’ye aittir. RADYOSPOR’un bu
								sorumluluk nedeni ile uğrayacağı her türlü zarar için Üye’ye ferileri ile birlikte rücu hakkı saklıdır.
								<br />
								<br /> 5.5. RADYOSPOR, Üye’yi, RADYOSPOR Platformları’ndaki mevcut olan İçerikler’in tek bir kopyasını görüntüleme ve buna erişme konusunda ticari olmayan, yalnızca
								kendi kişisel kullanımı için yetkilendirmektedir. RADYOSPOR Platformları ve İçerikler, herhangi bir ticari amaç için kullanılamaz. Üye, herhangi bir şekilde,
								RADYOSPOR’un ve RADYOSPOR Platformları’nın ticari unvanını, markasını, hizmet markasını, logosunu, alan adını ve diğer varlıklarını RADYOSPOR’un önceden yazılı izni
								olmadan kullanamaz.
								<br />
								<br /> 5.6. Üye, RADYOSPOR’un RADYOSPOR Platformları kapsamında çeşitli özellikler sunuyor olmasının Üye’ye herhangi bir donanım ve/veya yazılım temini, kurulumu veya
								mevcut donanım ve/veya yazılımın düzgün çalışması konusunda bir taahhüt ya da garanti verdiği anlamına gelmediğini ve bu hususta gerekli donanım ve/veya yazılımsal
								gereklilikleri sağlamaktan doğan sorumluluğun tüm masrafları ve riski ile birlikte kendisine ait olduğunu kabul, beyan ve taahhüt eder. Üye, bu doğrultuda RADYOSPOR
								Platformları’nda Üye’ye sunulan özelliklerden faydalanabilmek için, mobil cihazının, bilgisayarının ve/veya kullandığı diğer cihazların, internet hizmetinin ve teknik
								donanımının gerekli minimum kriterleri karşıladığını, bu kriterlere uygun olmayan mobil cihaz, bilgisayar ve/veya diğer cihazların ve teknik donanım sebebiyle
								hizmetlerden faydalanamaması durumunda, RADYOSPOR’un herhangi bir sorumluluğunun olmadığını, bu sebeple herhangi bir nam altında RADYOSPOR’dan tazminat talep
								etmeyeceğini veya diğer herhangi bir talepte bulunmayacağını kabul, beyan ve taahhüt eder.
								<br />
								<br /> 5.7. Üye; kendisi tarafından RADYOSPOR Platformları’nda üretilecek veya eklenecek yorum, alıntı vb gibi her türlü içeriğe (“Üye İçerikleri”), ilişkin 5846 sayılı
								Fikir ve Sanat Eserleri Kanunu’ndan (“FSEK”) kaynaklanan “işleme” (FSEK madde 21), “çoğaltma” (FSEK madde 22), “yayma” (FSEK madde 23), “temsil” (FSEK madde 24),
								“işaret, ses ve/veya görüntü nakline yarayan araçlarla umuma iletim” (FSEK madde 25) dahil ancak bunlarla sınırlı olmaksızın tüm mali manevi hakları ile mülkiyet
								haklarını bu hakları 3. kişilere devir haklarıyla birlikte, herhangi bir bedel talep edilmeksizin süre, yer, mecra ve muhteva açısından gayrimahdut olarak TAM RUHSAT
								şeklinde RADYOSPOR’a devretmektedir. İşbu taahhüt Sözleşme kapsamında tesis edilen Taraflar arasındaki diğer taahhütlerden bağımsız bir taahhüt olarak Sözleşme
								feshinden ve/veya sona ermesinden sonrada Taraflar arasındaki yürürlüğünü devam ettirecektir.
								<br />
								<br /> 5.8. RADYOSPOR tarafından yayınlanan İçerikler ve yine Üye İçerikleri bilgilendirme amaçlı olup, olduğu gibi sunulmaktadır. RADYOSPOR söz konusu verilerin
								doğruluğu, tamlığı ve güvenilirliği ile ilgili herhangi bir sorumluluk ya da taahhüdü bulunmamaktadır. İşbu Sözleşme kapsamında ticari elverişlilik, belli bir amaca
								veya kullanıma uygunluk, tamlık veya doğruluğa ilişkin olarak açık veya zımni herhangi bir taahhütte bulunmamaktadır. RADYOSPOR Platformları’nda sağlanan haber
								içerikleri, spor dataları, canlı skorlar ver diğer her türlü İçerikler farklı kaynaklardan derlenmekte olup RADYOSPOR’un bunların doğruluğunu ve güncelliğini araştırma
								yükümlülüğü ve imkanı bulunmamaktadır. Üye, bu kapsamda RADYOSPOR Platformları’nda sağlanan İçerikler bakımından RADYOSPOR’un herhangi bir sorumluluğu olmadığını kabul
								ve beyan eder. RADYOSPOR Platformları’na Üye tarafından yorum olarak eklenen, yüklenen, yaratılan her türlü Üye İçerikleri’nin doğruluğu, güncelliği ve yürürlükteki
								mevzuata uygun olup olmadığı tamamen Üye’nin sorumluluğundadır, RADYOSPOR’un bu sorumluluk nedeni ile uğrayacağı her türlü zarar için Üye’ye ferileri ile birlikte rücu
								hakkı saklıdır.
								<br />
								<br /> 5.9. Üye, RADYOSPOR Platformları’na yorum olarak eklediği, yüklediği, yarattığı her türlü Üye İçerikleri’nde 3. kişilerin kişilik ve fikri mülkiyet haklarına
								zarar verecek, karalayıcı, aldatıcı unsurlar, pornografik, ahlaka aykırı fotoğraflar, hakaret, şiddet, ırkçılık, ayrımcılık ve yasadışılık unsuru bulunmayacağını,
								RADYOSPOR’dan önceden herhangi bir yazılı onay ve/veya RADYOSPOR ile herhangi bir anlaşma sağlamadan Üye İçerikleri vasıtasıyla reklam oluşturmayacağını, örtülü reklam
								niteliğinde olabilecek Üye İçerikleri ile spam içerikler üretmeyeceğini taahhüt etmiştir. Aksi halde; tüm hukuki ve cezai sorumluluk Üye’ye ait olacaktır. Yine bu
								taahhüde aykırı davranan Üyeler, RADYOSPOR tarafından derhal üyelikten çıkarılabilecektir. Üyeliğin Üye’den kaynaklanan sebeplerle RADYOSPOR tarafından sona erdirilmesi
								halinde RADYOSPOR’un uğradığı ya da uğraması muhtemel tüm doğrudan ve dolaylı zararları ilgili Üye’den talep etme hakkı saklıdır.
								<br />
								<br /> 5.10. RADYOSPOR, herhangi bir İçerik’i veya Üye İçerikleri’ni, herhangi bir zamanda Üye’ye haber vermeksizin ve herhangi bir sebep göstermeksizin kendi takdiri
								doğrultusunda, kaldırma, düzenleme, değiştirme, engelleme, RADYOSPOR Platformları’nın yapısını, tasarımını, kapsamını Üye’ye bildirim gerekmeksizin tek taraflı olarak
								kısmen veya tamamen değiştirme veya sonlandırma veya askıya alma, belli bölümlerini, kategorilerini veya özelliklerini ücretli hale getirme ve bu ücretleri tek taraflı
								olarak belirleme hakkına sahiptir. RADYOSPOR ayrıca; Üye İçerikleri’ni herhangi bir sebep göstermeksizin reddetme, geciktirme veya yayınlamama haklarına sahiptir. Böyle
								bir durumda Üye, RADYOSPOR’dan hiçbir talepte bulunmayacağını gayrikabili rücu biçimde kabul etmiştir.
								<br />
								<br /> 5.11. Üye; RADYOSPOR Platformları’na ve İçerikler’e elektronik ortamda erişecek olup, Üye’ye fiziksel ortamda bir teslimat yapılmayacaktır.
								<br />
								<br /> MADDE 6-ÜYE’NİN HAK VE YÜKÜMLÜLÜKLERİ
								<br />
								<br /> 6.1. Üyelik başvurusunda bulunan kişi, fiil ehliyetine sahip olduğunu, akıl sağlığının yerinde olduğunu ve eylemlerinin sorumluluğunu taşımakla yükümlü olduğunu
								peşinen kabul eder. 6.2. Üye, üyelik başvurusu sırasında gerekli olan bilgileri Kayıt Formu’na girmekle yükümlüdür. Üye, üyelik başlangıcında bildirdiği tüm bilgilerin
								doğru, tam ve güncel olmasından sorumludur.
								<br />
								<br /> 6.3. Üye; kendisine ait Kullanıcı Adı ve Şifre’nin ve bunlarla ilişkilendirilmiş olan Hesap’ın güvenliğinden kendisi sorumludur. RADYOSPOR Üyeliği süresince, Üye
								tarafından kullanılacak olan Kullanıcı Adı ve Şifre koruma mükellefiyeti ve Kullanıcı Adı ve Şifre kullanımına dayanan her türlü sorumluluk Üye’ye ait olacaktır. Üye,
								kendisine ait Kullanıcı Adı ve Şifre’sini gizli tutmakla yükümlü olduğunu bu gizliliğin gerektirdiği tüm tedbirleri almayı kabul ve taahhüt eder. Üye, Üyelik için
								sağladığı bilgilerin, şifre unutma gibi, gerekli olduğu durumlarda bilginin hatalı veya noksan olmasından doğacak zararlardan dolayı da sorumluluğun kendisine ait
								olacağını kabul ve taahhüt eder. Üye, kendisine ait Kullanıcı Adı ve Şifre’nin herhangi bir üçüncü şahıs tarafından kullanılmasının sonuçlarından tamamıyla kendisinin
								sorumlu olduğunu, bu işlemlerin kendisi tarafından yapılmadığı yolundaki itiraz ve def'i haklarından peşinen feragat ettiğini bu gibi usulsüz kullanımları yapan
								kişilerin kimliklerini RADYOSPOR’un tespit etmekle yükümlü olmadığını, usulsüz kullanımlardan dolayı RADYOSPOR’un hiçbir mali, hukuki ve cezai yükümlülüğünün
								bulunmadığını kabul ve taahhüt eder. Üyelik, bu Sözleşme’de belirtilen tüm Üyelik koşulları yerine getirildiği ve Üyelik işlemleri tamamlandıktan sonraki süre boyunca
								Üye tarafından korunduğu takdirde devam edecektir.
								<br />
								<br /> 6.4. RADYOSPOR Platformları’nın kullanımında, herhangi bir bilgisayar şebekesinin güvenliğini ihlal etmek, güvenlik şifreleme kodlarını kırmak, tehdit edici veya
								müstehcenlik içeren yasa dışı materyalleri transfer etmek veya yüklemek ya da herhangi bir yasa dışı aktiviteyle meşgul olmak kesinlikle yasaktır. Üye; Maillist,
								Listserv, veya otomatik çağrı cevaplayıcısının hiçbir cinsini veya 'spam'i (mesaj sağanağını), Üye’nin oturum açmadığı esnada çalıştırılan ya da harekete geçirilen
								hiçbir yöntemi, RADYOSPOR Platformları’na yükleyemez, diğer Ziyaretçi veya Üyeler’in RADYOSPOR Platformları’nı kullanmasını engelleyemez, zorlaştıramaz veya
								veritabanlarını ya da sunucuları bozacak veya işlemez hale getirecek eylemlerde bulunamaz, herhangi bir yazılım veya donanıma zarar veremez, virüs içeren yazılım veya
								başka bir bilgisayar kodu, dosya oluşturamaz, yetkisi olmayan herhangi bir sisteme, dataya, şifreye ulaşmaya çalışamaz, direkt veya dolaylı olarak, verilen
								hizmetlerdeki algoritmaları ve kodları deşifre edecek, işlevlerini bozacak davranışlarda bulunamaz, İçerikler’i değiştiremez, dönüştüremez, çeviremez, alıntı
								göstermeksizin başka sitelerde yayınlayamaz, kanunen yasak bilgiler içeren mesajlar, zincir posta, yazılım virüsü gibi 3. kişilere zarar verebilecek içerikleri
								dağıtamaz, diğer Ziyaretçi veya Üye’lerin bilgisayarındaki bilgilere ya da yazılıma zarar verecek program ve/veya bilgiler gönderemez, bunun gibi hukuka aykırı ve
								RADYOSPOR’a veya RADYOSPOR Platformları’na zarar verecek davranışlarda bulunamaz. Aksi halde; RADYOSPOR tarafından tüm hukuki ve cezai işlemleri başlatma ve taleplerde
								bulunma hakkı saklıdır.
								<br />
								<br /> 6.5. Üye, RADYOSPOR Platformları’nın işleyişinin kusurdan ari ve kesintisiz olmadığını ve zaman zaman teknik aksaklıklar veya erişim engelleriyle
								karşılaşabileceğini bildiğini kabul, beyan ve taahhüt eder.
								<br />
								<br /> 6.6. Üye, işbu Sözleşme kapsamında RADYOSPOR Platformları’nı kullanmakla, RADYOSPOR Platformları’ndaki hiçbir hakkın kendisine verilmediğini kabul, beyan ve
								taahhüt eder. Üye RADYOSPOR Platformları’nı ve İçerikler’i, 5237 sayılı Türk Ceza Kanunu, 5846 sayılı Fikir ve Sanat Eserleri Kanunu dahil ve bunlarla sınırlı
								olmaksızın yürürlükteki mevzuata aykırı olarak veya hak ve/veya eser sahipleri olan kişilere ve her türlü üçüncü kişiye maddi veya manevi olarak zarar verecek şekilde
								kullanmayacağını, bu maddede sayılanlarla sınırlı olmaksızın İçerikler’i değiştirmeyeceğini, çoğaltmayacağını, işletmeyeceğini, pazarlamayacağını, kiralamayacağını,
								herhangi bir şekilde üçüncü kişilerin erişimine/kullanımına sunmayacağını ve/veya üçüncü kişilere iletmeyeceğini, başka bir mecrada teşhir etmeyeceğini kabul, beyan ve
								taahhüt eder. Üye, işbu maddeye aykırı davranması halinde işbu Sözleşme'nin RADYOSPOR tarafından tek taraflı olarak derhal, tazminatsız şekilde feshedilebileceğini ve
								ilgili ihlal sebebiyle hukuki ve cezai tüm sorumluluğun tarafına ait olacağını; RADYOSPOR’un menfi-müspet her türlü zararını ayrıca herhangi bir ihbar veya ihtara gerek
								kalmaksızın, RADYOSPOR’un ilk yazılı talebi üzerine derhal, nakden ve defaten tazmin edeceğini kabul, beyan ve taahhüt eder.
								<br />
								<br /> 6.7. Üye, RADYOSPOR’un ağır ihmal veya kusuru dışında, kendisinden kaynaklanan her türlü (RADYOSPOR Platformları’na erişim sağlayacağı mobil cihaz, bilgisayar
								veya diğer erişim cihazlarının yaratacağı arızalar, bilgi ve veri kaybı vb.) kayıptan dolayı RADYOSPOR’un herhangi bir sorumluluğunun olmadığını; RADYOSPOR
								Platformları’na erişmekte olduğu mobil cihaz vb. elektronik cihazların, kablolu, kablosuz her türlü internet erişiminin, bağlantılarının güvenliğini de sağlamak zorunda
								olduğunu; bu hususta gereken tedbirleri almayı kabul, beyan ve taahhüt eder.
								<br />
								<br /> 6.8. Üye, RADYOSPOR’un ağır ihmal veya kusuru dışında, RADYOSPOR Platformları sebebiyle RADYOSPOR Platformları’na eriştiği cihaza herhangi bir zarar geldiğini;
								İçerikler’den dolayı virüs, truva atı v.b gibi kötü amaçlı kodların cihazlarına bulaştığını öne sürerek RADYOSPOR’dan herhangi bir tazminat talebinde veya diğer
								herhangi bir talepte bulunamaz.
								<br />
								<br /> 6.9. Üye, Hesap aracılığı ile gerçekleştirdiği tüm eylemlerden ve yorum, alıntı vb olarak yüklediği veya eklediği tüm içeriklerden yalnızca kendisi sorumludur.
								Bu sorumluluk üyeliği sona erse dahi devam etmektedir.
								<br />
								<br /> 6.10. Üye, aşağıda listelenen Topluluk Kurallarına uymayı kabul, beyan ve taahhüt eder. Bu kuralları ihlal edenler hakkında soruşturma yapmak ve bunları ihlal
								eden kişilerin üyeliklerini silmek, bu tarz uygunsuz iletişimi hizmetten kaldırmak ve bu kişilere karşı her türlü gerekli yasal tedbirleri almak ve başlatmak konusunda
								RADYOSPOR yetkilidir.
								<br />
								<br /> a) Üye saygılı olmak zorundadır. Rahatsızlık vermek veya başka birisinin tacizine yandaş olmak, onu savunmak yasaktır.
								<br />
								<br /> b) Yorumlarda, ırkçı, cinsiyetçi, müstehcen, iftira edici, hakaret ve şiddet içeren, kişileri veya belirli grupları nefrete teşvik edici, küçük düşürücü
								ifadelere yer verilmemelidir, bu tür yorumlara ve yargı mercilerine saygısızlık içeren veya içerme ihtimali olan yorumlara, terörü savunan veya teşvik eden içeriklere
								müsamaha gösterilmeyecektir. RADYOSPOR bu tür yorum ve içerikleri yayınlamama hakkını saklı tutmaktadır. Bir Üye’nin yanlış ve yanıltıcı bulduğu bilgileri veya yasa
								dışı aktiviteleri veya tehdit edici davranışları yapmak ve ileri safhaya taşımak yasaktır.
								<br />
								<br /> c) Yapılan yorumlarda mümkün olduğu kadar yazım ve imla kurallarına önem verilmeli, İçerikler’den sapmayan yorumlar yapılmalıdır. RADYOSPOR yorumlardaki imla
								hatalarını, anlaşılamayan yorumları düzeltme ve silme, yine İçerikler ile ilgili olmayan yorumları da silme hakkını saklı tutar.
								<br />
								<br /> d) Yasadışı bir çalışmayı veya başka birisine ait bir çalışmayı izinsiz olarak kullanmak, kısıtlı veya parola korumalı sayfalar yayınlamak ya da başka bir
								sayfadan link içermeyen veya erişilemeyen gizli sayfa veya resimler kullanmak, telif hakkı ihlali içeren yorumlarda bulunmak yasaktır.
								<br />
								<br /> e) Yasadışı silah yapımı veya kullanımı, uyuşturucu, alkol, tütün ürünlerinin özendirilmesi ve kullanımı gibi veya her türlü yasadışı aktiviteler hakkında
								bilgilendirici veya özendirici içerik kullanmak, birisinin özel hayatını ihlal etme ya da bilgisayar virüsleri sağlama veya oluşturma, üçüncü kişilerin yahut başka bir
								Üye’nin kişisel bilgilerini ifşa etmek, bu bilgileri kendi çıkarları için kullanmak, tehdit etmek, ırkçılığı, bağnazlığı, herhangi bir birey ya da gruba karşı nefreti
								ve fiziksel zarar verilmesini teşvik eden içerik yayınlamak, insanları şiddete yönlendirmek adına başkalarını kışkırtan davranışlarda bulmak yasaktır. Bu kuralı ihlal
								eden Üye’nin, hesaplarının kapatılacaktır.
								<br />
								<br /> f) Üye’nin kendi güvenlikleri için kişisel ve özel bilgilerini paylaşmaması, yürürlükteki mevzuat hükümlerine uyması esastır.
								<br />
								<br /> MADDE 7- ÜYEYE AİT KİŞİSEL BİLGİLER VE TİCARİ İLETİLER
								<br />
								<br /> 7.1. Üye’nin, Üyelik başlangıcında Üyelik Sözleşmesi kapsamında vermiş olduğu kimlik bilgisi (isim, soyadı), Facebook, Twitter, Gmail, Hotmail gibi Üçüncü Şahıs
								Hesapları ile üye olunmuş ise işbu hesap bilgileri ve iletişim bilgisi (e-posta adresi) ‘’(i) üye kaydının ve hesabının oluşturulması, (ii) söz konusu RADYOSPOR
								Platformları üzerinden sunulan ürün, hizmet ve servislerin ifası, bu çerçevede RADYOSPOR’un Üye’ye karşı olan yükümlülüklerinin yerine getirilmesi, (iii) hizmetler
								hakkında Üye’nin değerlendirme/memnuniyet ölçümleri/şikayet yönetimi, hukuki süreç gibi süreçlerin yönetilmesi, şikayet ve taleplere yönelik Üye’ye bilgi vermek, (iv)
								resmi makamlardan gelen talepleri incelemek, değerlendirmek ve yanıtlamak, hukuki ve ticari güvenliğinin temini’’ amacıyla bağlantılı, sınırlı ve ölçülü olacak şekilde
								işlenecek olup, RADYOSPOR’un veri tabanında dijital veya fiziki ortamda kayıt altına alınabilecek, depolanabilecek, kaydedilebilecek ve muhafaza edilebilecek, bu
								maddede belirtilen amaçlarla sınırlı olarak işbirliği içerisinde olduğumuz üçüncü şahıslara, tedarikçilere, iş ortaklarına, hukuken yetkili özel hukuk tüzel kişilerine,
								işbirlikçilerine KVKK’nin 8. Maddesine uygun olarak yurt içinde aktarılabilecektir. Üye bu madde kapsamında söz konusu Kişisel Verilerin İşlenmesi ve RADYOSPOR veri
								tabanında saklı tutulması konusunda yeterli bilgi sahibi olduğunu kabul ederek bu Kişisel Verilerin İşlenmesi’ne ve RADYOSPOR veri tabanında saklı tutulmasına ve bu
								amaçla yukarıda belirtilen kişiler ile paylaşılmasına açıkça muvafakat etmektedir. Üye işbu madde kapsamında yapılacak işlemlerin 6698 sayılı Kişisel Verilerin
								Korunması Kanuna ve 5237 sayılı Türk Ceza Kanunun ilgili maddelerine aykırılık teşkil etmediğini kabul etmektedir.
								<br />
								<br /> 7.2. Üye’nin Üyelik Başlangıcında Üyelik Sözleşmesi kapsamında vermiş olduğu yukarıda belirtilen Kişisel Verileri’ne ek olarak işlem güvenliği (IP adresi
								bilgileri, RADYOSPOR Web Sitesi/ RADYOSPOR Uygulaması’na giriş çıkış bilgileri, standart web günlüğü bilgileri), pazarlama satış bilgisi (anket, çerez kayıtları,
								kampanya, promosyon çalışmasıyla elde edilen bilgiler), sunduğumuz ürün ve hizmetlerin kullanışı sırasında elde edilen hesap hareketleri (favori sporlar, ligler
								takımlar, etkinlikler ve/veya spor taraftarlarını ilgilendiren konularla ilgili sağladığınız bilgiler, takım tercihleriniz, isteyerek paylaştığınız veriler, yorumlar,
								görüntülediğiniz içerik türü, ne sıklıkla, hangi saatlerde görüntülediğinize yönelik veriler), Üye’nin RADYOSPOR ile olan yazışma kayıtları gibi kişisel verileri de
								yine yukarıdaki amaçlara ek olarak ‘’(i) sunulan ürün ve hizmetlerin Üye’nin beğeni, kullanım alışkanlıkları ve ihtiyaçlarınıza göre özelleştirilerek Üye’ye önerilmesi,
								(ii) istatistiki raporlama sisteminde veya reklam ve pazarlama (anket ve/veya promosyon) alanında kullanıcılarının genel eğilimlerinin belirlenmesi, kişiselleştirilmiş
								içerik ve reklam olarak Üye’ye sunulması, hizmetlerle ilgili kalite ve standart denetimleri yapabilmek, (iii) tarafımızca düzenlenen ödüllü yarışma veya çekilişlere
								katılmanız durumunda tarafınıza ait bilgileri gerekli mercilerle paylaşmak, yarışma veya çekiliş sonuçlarını açıklamak ve yarışma ve çekilişin sonucu kazandığınız
								ödüllerin teminini sağlamak (iv) bilgi işlem gereksinimleri, sistemsel yapısı, alınan bilgi işlem destek hizmetlerinin gerekliliği, IP adresleri sitedeki sistemle
								ilgili sorunların tanımlanabilmesi ve çıkabilecek muhtemel sorunların acil olarak giderilmesi (v) müşteri memnuniyetinin ölçümlenmesi ve artırılması, yeni hizmet ve
								ürünler ile ilgili Üye görüş ve önerilerini almak, (vi) 5651 Sayılı Kanun gereği trafik verisi saklama yükümlülüklerinin yerine getirilmesi’’ amacıyla bağlantılı,
								sınırlı ve ölçülü olacak şekilde işlenecek olup, RADYOSPOR’un veri tabanında dijital veya fiziki ortamda kayıt altına alınabilecek, depolanabilecek, kaydedilebilecek ve
								muhafaza edilebilecektir. Pazarlama satış bilgisi ve sunduğumuz ürün ve hizmetlerin kullanışı sırasında elde edilen Üye hesap hareketleri maddede belirtilen amaçlarla
								sınırlı olarak işbirliği içerisinde olduğumuz üçüncü şahıslara, tedarikçilere, iş ortaklarına, hukuken yetkili özel hukuk tüzel kişilerine, işbirlikçilerine KVKK’nın 8.
								Maddesine uygun olarak yurt içinde aktarılabilecektir.
								<br />
								<br /> Üye bu madde kapsamında bilgilerinin yukarıda belirtilen hususların gerçekleştirilmesi amacıyla işbu Sözleşme konusu iş ile ilgili 3. Kişiler ve/veya şirketin iş
								ortakları veyahut bu amaçla faaliyet gösteren başka üçüncü kişiler ile paylaşılması hususunda yeterli bilgi sahibi olduğunu kabul ederek bu kişisel verilerin bu kişiler
								ile paylaşılmasına açıkça muvafakat etmektedir. Üye işbu madde kapsamındaki işlemlerin 6698 sayılı Kişisel Verilerin Korunması Kanuna ve 5237 sayılı Türk Ceza Kanunun
								ilgili maddelerine aykırılık teşkil etmediğini kabul etmektedir.
								<br />
								<br /> 7.3. Üye, üyelik başvurusu esnasında vermiş olduğu ve RADYOSPOR veri tabanında saklı tutulan işbu Sözleşme konusu kişisel bilgilerle ilgili aydınlatma metnine,
								RADYOSPOR Platformları’nda yer verilen ‘’Kişisel Verilerin Korunması Kanunu ve Gizlilik Politikası’’ndan ulaşabilir.
								<br />
								<br /> 7.4. Üye, RADYOSPOR’a vermiş olduğu ve RADYOSPOR veri tabanında saklı tutulan Sözleşme konusu Kişisel Veriler’in ve RADYOSPOR Platformları’na dair kullanım kayıt
								ve verilerinin, RADYOSPOR tarafından istatistiki raporlama sisteminde veya reklam ve pazarlama (anket ve/veya promosyon) alanında, kullanıcılarının genel eğilimlerinin
								belirlenmesi amacıyla kişilik haklarına zarar vermeksizin kullanılmasına onay verdiğini kabul eder.
								<br />
								<br /> 7.5. RADYOSPOR, Üye’nin RADYOSPOR’a göndereceği e-posta, faks ve mektuplarda Üye’nin belirttiği Üye’ye ait Kullanıcı Adı ve Şifre bilgisinin, anne kızlık
								soyadının, finansal bilgilerinin ve buna benzer kişisel bilgilerinin başkalarınca kullanım güvenliğini hiçbir koşulda garanti edemez. Üye bu bilgilerinin ve iletişim
								kanallarının güvenliğinden kendisi sorumludur. Aynı zaman Üye, kendi güvenliği açısından kişisel ve özel bilgilerini yorumlarda paylaşmaması gerektiğini bilmekle ve
								buna uygun hareket etmekle yükümlüdür.
								<br />
								<br /> 7.6. Üye, RADYOSPOR Platformları’na kayıt için vermiş olduğu bütün Kişisel Verileri kendi rızasıyla RADYOSPOR’a sağladığını; RADYOSPOR tarafından Kişisel
								Verilerin İşlenmesi’ni kabul ettiğini, söz konusu bilgilerin doğru ve kendisine ait olduğunu, aksi takdirde hukuki ve cezai tüm sorumluluğun tarafına ait olduğunu
								kabul, beyan ve taahhüt etmektedir.
								<br />
								<br /> 7.7. Üye, reklam, satış, pazarlama anket, rezervasyon ayrıcalığı vb. amaçla sahsına özel ticari elektronik gönderiminin yapılması için İletişim İzni’ne onay
								veren kutucuğu işaretlemesi halinde, RADYOSPOR tarafından kendisine gönderilecek her türlü ticari elektronik ileti için açıkça onay verdiğini, kabul, beyan ve taahhüt
								eder. RADYOSPOR, tarafından gönderilecek ticari elektronik iletiler Üye’ye e-posta ve/veya sms yoluyla ve/veya push/web push (anlık) bildirim ile iletilebilecektir.
								Üye, RADYOSPOR tarafından kendisine reklam, pazarlama vb. konularla ilgili gönderilecek her türlü ticari elektronik iletinin gönderimini sona erdirmek için, RADYOSPOR
								Platformları’ndaki Hesabına giriş yaparak, Hesabım bölümünden “Ticari İleti Almak İstiyorum” seçimini kaldırabilir. Üye, istediği zaman RADYOSPOR Platformları’nda
								bulunan Hesabına giriş yaparak, Hesabım bölümünden “Ticari İleti Almak İstiyorum” seçeneğini seçerek, reklam, pazarlama vb. konularla ilgili gönderilecek her türlü
								ticari elektronik ileti gönderimini almayı seçebilir.
								<br />
								<br /> 7.8. Üye, RADYOSPOR Platformları’ndaki kendi rızaları ile oluşturdukları her türlü kişisel profil bilgilerinin başkalarının görüntülemesini, yorum olarak
								ürettikleri içeriklerin RADYOSPOR Platformları’nda herkesin görebileceği şekilde yayımlanacağını kendi rızaları ile kabul etmiştir. RADYOSPOR, Kişisel Verilerin
								Korunması Kanunu uyarınca üye tarafından alenileştirilmiş olan bu kişisel verileri, açık rıza aramaksızın işleyebilecektir.
								<br />
								<br /> 7.9. Üye, Üçüncü Şahıs Hesabı’ndan RADYOSPOR Platformları’na giriş yaptığında, giriş bilgilerinin RADYOSPOR tarafından doğrulanacağını bilmekte, ilgili Üçüncü
								Şahıs Hesabı’na ilişkin bilgilerin RADYOSPOR ile paylaşılmasına açıkça rıza gösterdiğini kabul, beyan ve taahhüt etmektedir.
								<br />
								<br /> MADDE 8- SÖZLEŞMENİN SÜRESİ VE FESİH
								<br />
								<br /> 8.1. İşbu Sözleşme belirsiz sürelidir ve Taraflar’dan biri tarafından işbu madde uyarınca feshedilene kadar yürürlükte kalacaktır.
								<br />
								<br /> 8.2. Üye istediği an RADYOSPOR Üyeliğinden kendi talebi ile çıkma hakkına sahiptir. Üye, RADYOSPOR Platformlarında bulunan Hesabım bölümünde yer alan “Üyelikten
								Çık” butonuna tıklayarak ya da konuya ilişkin yazılı talebini RADYOSPOR’a iletmek kaydı ile Üyelik hesabının sonlandırılmasını isteyebilir.
								<br />
								<br /> 8.3. RADYOSPOR, Üye’nin bu Sözleşme’ye aykırı davranması, RADYOSPOR Platformları’nı kullanırken ya da RADYOSPOR’a üye olurken verdiği bilgilerin hatalı veya
								gerçeğe aykırı olması, RADYOSPOR Topluluk Kurallarına aykırı davranması, RADYOSPOR’da verilen hizmetleri kötü niyetli veya hukuka, ahlaka, 3.kişilerin haklarına aykırı
								şekilde kullanması veya RADYOSPOR’un gerekli görmesi halinde üyeliğini gerekçe göstermeksizin sona erdirebilir, Sözleşme’yi feshedebilir. Bu durumlarda RADYOSPOR’un
								ayrıca hukuki yollara başvurma hakkı saklıdır.
								<br />
								<br /> 8.4. Üye’nin üyeliğinin sonlanması halinde; Üye, RADYOSPOR'dan herhangi bir hak ve/veya tazminat talebinde bulunmayacağını peşinen kabul, beyan ve taahhüt eder.
								<br />
								<br /> MADDE 9- KAMPANYA, ANKET, OYUN VE PROMOSYONLAR
								<br />
								<br /> Üye, RADYOSPOR tarafından sunulan kampanya, anket, oyun ve promosyonlardan RADYOSPOR tarafından belirlenen kullanım/yararlanma koşulları çerçevesinde
								yararlanabileceğini peşinen beyan, kabul ve taahhüt eder. RADYOSPOR, kampanya, oyun, anket ve promosyon uygulama ve kullanım genel şartlarını her zaman tek taraflı
								belirleme ve bunlarda tek taraflı olarak değişiklik yapma hakkını saklı tutar. Kampanya, anket, oyun ve promosyonlara katılım Üye’nin inisiyatifindedir. Üye’nin,
								kampanya, oyun, anket ve promosyonlara katılması halinde elde edilen bilgiler Üye’nin datasına işlenecektir. RADYOSPOR, kampanya, oyun ve promosyon veya genel
								uygulamalarında Üye’nin Oyun oynama ve davranış analizlerini dikkate alarak Üye’ye farklı teklifler sunma, uyguladığı bağlılık programlarında bu analizleri
								belirleyeceği zamanlarda gerçekleştirme ve/veya değişiklik yapma hakkına sahiptir. Tüm bu uygulamalar RADYOSPOR’un belirleyeceği süreler içerisinde geçerlidir.
								<br />
								<br /> MADDE 10-RADYOSPOR’UN KAYITLARININ GEÇERLİLİĞİ
								<br />
								<br /> Üye, RADYOSPOR kayıtlarının geçerliliğini peşinen kabul ettiğini, RADYOSPOR ile arasında çıkacak her türlü ihtilafta RADYOSPOR’un her türlü kayıt, belge ve
								defterleri ile her türlü bilgisayar ve ses kayıtlarının, mikrofilm ve mikrofişlerinin tek ve kesin delil teşkil edeceğini ve bağlayıcı olacağını, bu maddenin HMK 193.
								madde kapsamında bir delil sözleşmesi olduğunu kabul eder.
								<br />
								<br /> MADDE 11- SÖZLEŞMENİN DEVRİ
								<br />
								<br /> Üye, işbu Sözleşme’den doğan hak ve yükümlülüklerini hiçbir şekilde üçüncü şahıslara devir ve temlik edemez.
								<br />
								<br /> MADDE 12- UYUŞMAZLIKLARI ÇÖZÜMÜ
								<br />
								<br /> Taraflar Sözleşme’nin tatbik ve tefsirinden doğacak ihtilafların çözümünde Türk Hukuku'nun uygulanmasını, her türlü hukuki ilişki için ifa yeri ve yetkili yargı
								merciinin İstanbul (Çağlayan) Mahkemeleri ve İcra Daireleri olmasını kabul ve beyan etmişlerdir.
								<br />
								<br /> MADDE 13- MUHTELİF HÜKÜMLER
								<br />
								<br /> 13.1. İşbu Sözleşme uyarınca Üye’ye sunulan hizmetlerin kapsamı, RADYOSPOR tarafından herhangi bir zamanda ve tek taraflı olarak genişletilebilir yahut
								daraltılabilir veya hizmetler durdurulabilir. RADYOSPOR bu Sözleşme’de yer alan maddelerde tek taraflı olarak değişiklik yapma hakkına sahiptir. Bu nedenle Üye, işbu
								Sözleşme ile kendisine sunulan hizmet kapsamının bir taahhüt olmadığını ve değişebileceğini peşinen kabul ve taahhüt eder.
								<br />
								<br /> 13.2. RADYOSPOR’un, Üye’nin işbu Sözleşme’yi ihlalinde veya edimlerinden birini yerine getirmekte temerrüdünde herhangi bir itirazda bulunmamış olması, sürekli
								bir feragat olarak yorumlanamaz.
								<br />
								<br /> 13.3. İşbu Sözleşme, metninde değinilmiş olan diğer belgeler ve EKLER ile birlikte Sözleşme’nin bütününü teşkil eder ve Taraflar arasında önceden işbu Sözleşme
								konusuyla ilgili olarak yapılmış olan tüm yazılı ya da sözlü her türlü mutabakatın yerine geçer.
								<br />
								<br /> 13.4. İşbu Sözleşme’nin herhangi bir maddesinin kısmen ya da tamamen yetkili mahkeme kararı ile hükümsüz veya geçersiz kılınması halinde, Sözleşme’nin geri kalan
								tüm hükümleri yürürlükte kalmaya ve uygulanmaya devam edecektir. Böyle bir durumun ortaya çıkması halinde, hangi nedenle olursa olsun geçersiz kalan hükmün ya da
								maddelerin geri kalan bölümleri yasa ya da mahkeme kararlarını izin vereceği ölçülerde geçerli ve uygulanabilir olmaya devam edeceklerdir.
								<br />
								<br /> İşbu Sözleşme 13 (onüç) ana maddeden ibarettir. Üye bu Sözleşme’nin tamamını okuduğunu, anladığını, içeriğindeki tüm maddeleri kayıtsız şartsız kabul ettiğini ve
								onayladığını peşinen kabul ve taahhüt eder. İşbu Sözleşme Taraflar arasında uygulanacak olup, 1 (bir) nüshası işbu Sözleşme’nin kurulması üzerine elektronik posta
								yoluyla Üye’ye gönderilecektir.
								<br />
								<br /> EK: 1- Ticari İleti Onay Metni
								<br />
								<br /> EK: 2- Kişisel Verilerin İşlenmesi Kapsamında Açık Rıza Metni
							</div>
						</div>
					</div>

					<Programs />
				</main>
			</div>

			<Footer />
		</>
	);
};

export default MembershiAgreement;
