import React from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
//import { persistStore } from 'redux-persist';
//import { PersistGate } from 'redux-persist/es/integration/react';

import { Player } from './components';
import Broadcasters from './pages/Broadcasters';
import Broadcaster from './pages/Broadcaster';
import Favorites from './pages/Favorites';
import Frequencies from './pages/Frequencies';
import Home from './pages/Home';
import LiveScore from './pages/LiveScore';
import News from './pages/News';
import Podcasts from './pages/Podcasts';
import ListenedPodcast from './pages/ListenedPodcast';
import ProgramDetail from './pages/ProgramDetail';
import Streaming from './pages/Streaming';
import Contact from './pages/Contact';
import Login from './pages/Login';
import Profile from './pages/Profile';
import ProfileSettings from './pages/ProfileSettings';

import About from './pages/About';
import MembershipAgreement from './pages/MembershipAgreement';
import Masthead from './pages/Masthead';
import KVKK from './pages/KVKK';

import configureStore from './store/configureStore';
import { isAuthenticated } from './utils';
import Register from './pages/Register';

const { store } = configureStore();

const LoginRoute = ({ component: Component, ...rest }) => {
	let isAuth = isAuthenticated();

	return (
		<Route
			{...rest}
			render={(props) =>
				isAuth ? (
					<Component {...props} />
				) : (
					<Redirect
						to={{
							pathname: '/login',
							state: { from: props.location },
						}}
					/>
				)
			}
		/>
	);
};

// nonAuth Component
const VisitorRoute = ({ component: Component, ...rest }) => {
	let isAuth = isAuthenticated();
	return (
		<Route
			{...rest}
			render={(props) =>
				!isAuth ? (
					<Component {...props} />
				) : (
					<Redirect
						to={{
							pathname: '/',
							state: { from: props.location },
						}}
					/>
				)
			}
		/>
	);
};

const App = (location) => {
	//const persistor = persistStore(store);

	return (
		<Provider store={store}>
			{/* <PersistGate loading={null} persistor={persistor}> */}
			<BrowserRouter>
				<Switch>
					<Route location={location} path="/" exact component={Home} />
					<Route location={location} path="/yayin-akisi" exact component={Streaming} />
					{/* <Route location={location} path="/podcastler" exact component={Podcasts} /> */}
					<Route location={location} path="/podcastler" exact component={Podcasts} />
					<Route location={location} path="/haberler" exact component={News} />
					<Route location={location} path="/programcilar" exact component={Broadcasters} />
					<Route location={location} path="/programci/:id" exact component={Broadcaster} />
					<Route location={location} path="/frekanslar" exact component={Frequencies} />
					<Route location={location} path="/canli-skor" exact component={LiveScore} />
					<Route location={location} path="/favoriler" exact component={Favorites} />
					<Route location={location} path="/program/:id" exact component={ProgramDetail} />
					<Route location={location} path="/iletisim" exact component={Contact} />
					<Route location={location} path="/hakkimizda" exact component={About} />
					<Route location={location} path="/uyelik-sozlesmesi" exact component={MembershipAgreement} />
					<Route location={location} path="/kunye" exact component={Masthead} />
					<Route location={location} path="/kvkk" exact component={KVKK} />
					<VisitorRoute location={location} path="/kayit" exact component={Register} />
					<VisitorRoute location={location} path="/giris" exact component={Login} />
					<LoginRoute location={location} path="/profil" exact component={Profile} />
					<LoginRoute location={location} path="/ayarlar" exact component={ProfileSettings} />
					<LoginRoute location={location} path="/dinlediklerim" exact component={ListenedPodcast} />

					<Redirect to="/" />
				</Switch>
			</BrowserRouter>
			<ToastContainer theme="dark" limit={1} />

			{window.location.pathname !== '/giris' && <Player />}

			{/* </PersistGate> */}
		</Provider>
	);
};

export default App;
