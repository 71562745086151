/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Slider from 'react-slick';
import { getToken, getTokenDecoded, SeoUrl } from '../utils';

const SliderItem = ({ broadcaster, setFavorited, favoriteBroadcasters, favoriteStatus }) => {
	const [isFavorite, setIsFavorite] = useState(false);

	const setFavorite = () => {
		setIsFavorite(!isFavorite);
		setFavorited(broadcaster.id);
	};

	useEffect(() => {
		const find = favoriteBroadcasters && favoriteBroadcasters.find((x) => x === broadcaster.id);
		const status = typeof find === 'undefined' ? false : true;
		setIsFavorite(status);
	}, [favoriteBroadcasters]);

	return (
		<div className="broadcaster-item" style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
			{favoriteStatus && (
				<button onClick={() => setFavorite()} className={`broadcaster-favorite${isFavorite ? ' favorited' : ''}`}>
					<ion-icon name="star"></ion-icon>
				</button>
			)}

			<a href={`/programci/${SeoUrl(broadcaster.name)}-${broadcaster.id}`}>
				<div className="broadcaster-photo">
					<img src="/assets/images/no-broadcaster.jpeg" alt="" />
				</div>

				<div className="broadcaster-name">{broadcaster.name}</div>

				<div className="broadcaster-info">
					{broadcaster.programs
						.filter((c) => c.status)
						.map((program, i) => (
							<span key={i}>
								{program.name} {broadcaster.programs.length > 0 && i < broadcaster.programs.length - 1 ? ',\u00A0' : ''}
							</span>
						))}
				</div>
			</a>

			<div className="broadcaster-links">
				{broadcaster.twitter && broadcaster.twitter !== '-' && (
					<a href={broadcaster.twitter} target="_blank" rel="noreferrer" style={{ height: 32, display: 'flex', alignItems: 'center' }}>
						<img src="/assets/images/x.svg" alt="" style={{ width: 24, height: 24 }} />
					</a>
				)}

				{broadcaster.ajansspor && broadcaster.ajansspor !== '-' && (
					<a href={broadcaster.ajansspor} target="_blank" rel="noreferrer">
						<img src="/assets/images/ajansspor-round.svg" alt="" />
					</a>
				)}
			</div>
		</div>
	);
};

const BroadcasterSlider = ({ broadcasters, favorites, fetchFavoriteBroadcasters, setFavoriteBroadcaster }) => {
	const sliderRef = React.useRef(null);
	const [user, setUser] = useState({});
	const [favoriteBroadcasters, setFavoriteBroadcasters] = useState([]);
	const [broadcasterList, setBroadcasters] = useState([]);

	const PrevArrow = () => (
		<button className="prev" onClick={() => sliderRef?.current?.slickPrev()}>
			<ion-icon name="chevron-back-outline"></ion-icon>
		</button>
	);

	useEffect(() => {
		if (getToken()) {
			const user = getTokenDecoded(getToken());
			setUser(user);

			if (user && Object.keys(user).length > 0) {
				fetchFavoriteBroadcasters(user.id);
			}
		}
	}, []);

	useEffect(() => {
		setBroadcasters(broadcasters);
	}, [broadcasters]);

	useEffect(() => {
		if (favorites && typeof favorites !== 'undefined' && Array.isArray(favorites)) {
			const arr = [];
			favorites.map((fv) => arr.push(fv.id));
			setFavoriteBroadcasters(arr);
		}
	}, [favorites]);

	const setFavorite = (id) => {
		if (Object.keys(user).length > 0) {
			setFavoriteBroadcaster({ broadcasters: id, userId: user.id });
		}
	};

	const NextArrow = () => (
		<button className="next" onClick={() => sliderRef?.current?.slickNext()}>
			<ion-icon name="chevron-forward-outline"></ion-icon>
		</button>
	);

	const settings = {
		dots: false,
		infinite: false,
		speed: 500,
		slidesToShow: 3.5,
		slidesToScroll: 3,
		arrows: false,
		responsive: [
			{
				breakpoint: 992,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 2,
				},
			},
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 2.5,
				},
			},
			{
				breakpoint: 576,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1,
				},
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1.5,
					slidesToScroll: 1,
				},
			},
		],
	};

	return (
		<section className="broadcasters">
			<div className="section-title">Programcılarımız</div>

			<div className="broadcaster-list">
				<div className="carousel-arrows">
					<PrevArrow />
					<NextArrow />
				</div>

				<Slider ref={sliderRef} {...settings}>
					{broadcasterList.length > 0 &&
						broadcasterList
							.filter((x) => x.programs.length > 0)
							.slice(0, 10)
							.map((broadcaster, i) => (
								<SliderItem
									favoriteStatus={Object.keys(user).length > 0 ? true : false}
									broadcaster={broadcaster}
									key={i}
									favoriteBroadcasters={favoriteBroadcasters}
									setFavorited={(id) => setFavorite(id)}
								/>
							))}
				</Slider>
			</div>
		</section>
	);
};

const mapStateToProps = (state) => {
	return {
		favorites: state.Favorites.broadcasters,
	};
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
	const { dispatch } = dispatchProps;
	const { actions: Favorites } = require('../redux/FavoriteRedux');

	return {
		...ownProps,
		...stateProps,
		fetchFavoriteBroadcasters: (id) => Favorites.fetchFavoriteBroadcasters(dispatch, id),
		setFavoriteBroadcaster: (data) => Favorites.setFavoriteBroadcaster(dispatch, data),
	};
};

export default connect(mapStateToProps, null, mergeProps)(BroadcasterSlider);
