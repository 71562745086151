import api from '../api';

const types = {
	ADD_CONTACT: 'ADD_CONTACT',
	ADD_CONTACT_SUCCESS: 'ADD_CONTACT_SUCCESS',
	ADD_CONTACT_FAILURE: 'ADD_CONTACT_FAILURE',
};

export const actions = {
	addContact: (dispatch, data) => {
		dispatch({ type: types.ADD_CONTACT });

		api.client().then((res) => {
			api.contact({
				name: data.name,
				email: data.emailAddress,
				phone: data.phoneNumber,
				subject: data.subject,
				message: data.message,
				ipaddress: res.ipAddress,
				os: data.os,
				browser: data.browser,
				state: res.stateProv,
				city: res.city,
				country: res.countryName,
				continent: res.continentName,
			})
				.then((res) => dispatch({ type: types.ADD_CONTACT_SUCCESS, contact: res }))
				.catch((error) => dispatch({ type: types.ADD_CONTACT_FAILURE, error }));
		});
	},
};

const initialState = {
	isFetching: false,
	error: null,
	contact: {},
};

export const reducer = (state = initialState, action) => {
	const { error, type, contact } = action;

	switch (type) {
		case types.ADD_CONTACT:
			return { ...initialState, isFetching: true };
		case types.ADD_CONTACT_SUCCESS:
			return { ...state, contact, isFetching: false, error };
		case types.ADD_CONTACT_FAILURE:
			return { ...state, error, isFetching: false };
		default:
			return state;
	}
};
