import api from '../api';

const types = {
	FETCH_MATCH_DETAIL: 'FETCH_MATCH_DETAIL',
	FETCH_MATCH_DETAIL_SUCCESS: 'FETCH_MATCH_DETAIL_SUCCESS',
	FETCH_MATCH_DETAIL_FAILURE: 'FETCH_MATCH_DETAIL_FAILURE',
};

export const actions = {
	fetchMatchDetail: (dispatch, id) => {
		dispatch({ type: types.FETCH_MATCH_DETAIL });

		api.getMatchDetail(id)
			.then((res) => dispatch({ type: types.FETCH_MATCH_DETAIL_SUCCESS, matchDetail: res }))
			.catch((error) => dispatch({ type: types.FETCH_MATCH_DETAIL_FAILURE, error }));
	},
};

const initialState = {
	isFetching: false,
	error: null,
	matchDetail: {},
};

export const reducer = (state = initialState, action) => {
	const { error, type, matchDetail } = action;

	switch (type) {
		case types.FETCH_MATCH_DETAIL:
			return { ...initialState, isFetching: true };
		case types.FETCH_MATCH_DETAIL_SUCCESS:
			return { ...state, matchDetail, isFetching: false, error };
		case types.FETCH_MATCH_DETAIL_FAILURE:
			return { ...state, error, isFetching: false };
		default:
			return state;
	}
};
