/* eslint-disable no-useless-escape */
import jwtDecode from 'jwt-decode';
import api from './api';
const LocaleTeam = require('./locale-team.json');

export const SeoUrl = (string) => {
	const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧıîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;';
	const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------';
	const p = new RegExp(a.split('').join('|'), 'g');

	let str = typeof string !== 'undefined' ? string : '';

	return str
		.toString()
		.toLowerCase()
		.replace(/\s+/g, '-') // Replace spaces with -
		.replace(p, (c) => b.charAt(a.indexOf(c))) // Replace special characters
		.replace(/&/g, '-ve-') // Replace & with 've'
		.replace(/[^\w\-]+/g, '') // Remove all non-word characters
		.replace(/\-\-+/g, '-') // Replace multiple - with single -
		.replace(/^-+/, '') // Trim - from start of text
		.replace(/-+$/, ''); // Trim - from end of text
};

export const NewsImage = (image, size = false) => {
	return `https://ajssarimg2.mediatriple.net/${image}.jpg.webp${!size ? '?w=350&h=197' : ''}`;
};

export const ProgramImage = (id) => `http://localhost:6055/images/program/${id}.webp`;
export const UID = parseInt(window.location.pathname.split('-')[window.location.pathname.split('-').length - 1], 10);

export const CDN = 'http://localhost:6055';

export const TOKEN_KEY = 'rstoken';
const PODCAST_PREFIX = 'pd';

export const setToken = (token) => {
	localStorage.setItem(TOKEN_KEY, token);
};

export const getToken = () => {
	return localStorage.getItem(TOKEN_KEY);
};

export const getTokenDecoded = (token) => {
	const decode = jwtDecode(token);
	return decode;
};

export const isAuthenticated = () => {
	const jwt = localStorage.getItem(TOKEN_KEY);
	let isAuthenticated = false;
	if (jwt) isAuthenticated = true;
	return isAuthenticated;
};

export const logOut = () => {
	localStorage.removeItem(TOKEN_KEY);
};

export const setPodcastToStorage = (id, time) => localStorage.setItem(`${PODCAST_PREFIX}-${id}`, time);
export const getPodcastToStorage = (id) => localStorage.getItem(`${PODCAST_PREFIX}-${id}`);

export const getIp = () => {
	return api.ip().then((res) => res.ip);
};

export const os = () => {
	let OSName = 'Unknown OS';
	if (navigator.appVersion.indexOf('Win') !== -1) OSName = 'Windows';
	if (navigator.appVersion.indexOf('Mac') !== -1) OSName = 'MacOS';
	if (navigator.appVersion.indexOf('X11') !== -1) OSName = 'UNIX';
	if (navigator.appVersion.indexOf('Linux') !== -1) OSName = 'Linux';

	return OSName;
};

export const browser = () => {
	if ((navigator.userAgent.indexOf('Opera') || navigator.userAgent.indexOf('OPR')) !== -1) {
		return 'Opera';
	} else if (navigator.userAgent.indexOf('Chrome') !== -1) {
		return 'Chrome';
	} else if (navigator.userAgent.indexOf('Safari') !== -1) {
		return 'Safari';
	} else if (navigator.userAgent.indexOf('Firefox') !== -1) {
		return 'Firefox';
	} else if (navigator.userAgent.indexOf('MSIE') !== -1 || !!document.documentMode === true) {
		return 'Internet Explorer';
	} else {
		return 'Tanımsız';
	}
};

export const LocaleTeamName = (str) => {
	const get = LocaleTeam.find((x) => x.en === str);

	if (get) {
		if (get.tr) return get.tr;
		else return get.en;
	} else {
		return str;
	}
};

export const TeamImage = (string) => {
	if (string.toString().indexOf('/teams/611.png') > -1) {
		return 'https://ajansspor.com/assets/images/icons/fenerbahce.svg';
	}
	return string;
};
