import api from '../api';

const types = {
	FETCH_NEWS: 'FETCH_NEWS',
	FETCH_NEWS_SUCCESS: 'FETCH_NEWS_SUCCESS',
	FETCH_NEWS_FAILURE: 'FETCH_NEWS_FAILURE',
};

export const actions = {
	fetchNews: (dispatch) => {
		dispatch({ type: types.FETCH_NEWS });

		api.news()
			.then((res) => dispatch({ type: types.FETCH_NEWS_SUCCESS, news: res.content }))
			.catch((error) => dispatch({ type: types.FETCH_NEWS_FAILURE, error }));
	},
};

const initialState = {
	isFetching: true,
	error: null,
	news: [],
};

export const reducer = (state = initialState, action) => {
	const { error, type, news } = action;

	switch (type) {
		case types.FETCH_NEWS:
			return { ...initialState };
		case types.FETCH_NEWS_SUCCESS:
			return { ...state, news, isFetching: false, error };
		case types.FETCH_NEWS_FAILURE:
			return { ...state, error, isFetching: false };
		default:
			return state;
	}
};
