/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { TailSpin } from 'react-loader-spinner';
import moment from 'moment';
import 'moment/locale/tr';

import { toast } from 'react-toastify';

import { SeoUrl, getTokenDecoded, getToken } from '../utils';

moment.locale('tr');

const FavoriteButton = ({ favorite, programId, addFavorite }) => {
	const [active, setActive] = useState(favorite);

	useEffect(() => {
		setActive(favorite);
	}, [favorite]);

	const setFavorite = (data) => {
		addFavorite(data);
		setActive(!active);
	};

	return (
		<button type="button" onClick={() => setFavorite({ programs: programId, userId: getTokenDecoded(getToken()).id })}>
			{active ? <img src="/assets/images/icons/favorite-filled.svg" alt="" /> : <img src="/assets/images/icons/favorite.svg" alt="" />}
		</button>
	);
};

const Streaming = ({ big, fetchStreaming, streaming, isFetching, live, fetchFavoritePrograms, setFavoriteProgram, programs }) => {
	const [width, setWidth] = useState(window.innerWidth);
	const [isMobile, setIsMobile] = useState(width <= 768);
	const [dates, changeDates] = useState(new Date().getDay() === 0 ? 7 : new Date().getDay());
	const [date, changeDate] = useState(moment().format());
	const [auth, setAuth] = useState(false);
	const [favoritePrograms, setFavoritePrograms] = useState([]);

	useEffect(() => {
		window.addEventListener('resize', (e) => {
			setWidth(window.innerWidth);
			setIsMobile(width <= 768);
		});

		fetchStreaming(dates);
	}, [dates]);

	useEffect(() => {
		setAuth(getToken() ? true : false);

		if (getToken()) {
			fetchFavoritePrograms(`?userId=${getTokenDecoded(getToken()).id}`);
		}
	}, []);

	useEffect(() => {
		setFavoritePrograms(programs.programs);

		if (programs.message) {
			toast(programs.message, {
				position: 'bottom-center',
				autoClose: 1500,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				closeButton: false,
			});
		}
	}, [programs]);

	const dateChange = (date) => {
		changeDate(date);
		const day = moment(date).day();
		changeDates(day === 0 ? 7 : day);
	};

	const addFavorite = (data) => {
		setFavoriteProgram(data);
	};

	return (
		<section className="streaming">
			{big && (
				<>
					<div>
						<a href="/podcastler" className="streaming-title">
							Podcastler
						</a>
					</div>

					<div className="stream-head">
						<div className="stream-year">{moment().format('YYYY')}</div>

						<div className="stream-day-selector">
							<button className="prev-btn" onClick={() => dateChange(moment(date).add(-1, 'day'))}>
								<ion-icon name="chevron-back-outline"></ion-icon>
							</button>

							<div className="stream-day-name">{moment(date).format('DD MMMM, dddd')}</div>

							<button className="next-btn" onClick={() => dateChange(moment(date).add(1, 'day'))}>
								<ion-icon name="chevron-forward-outline"></ion-icon>
							</button>
						</div>
					</div>
				</>
			)}

			<div className={`stream-days ${big ? 'stream-days-padding' : ''}`}>
				<ul>
					{[
						{ long: 'Pazartesi', short: 'Pzt' },
						{ long: 'Salı', short: 'Sa' },
						{ long: 'Çarşamba', short: 'Çrş' },
						{ long: 'Perşembe', short: 'Prş' },
						{ long: 'Cuma', short: 'Cu' },
						{ long: 'Cumartesi', short: 'Cts' },
						{ long: 'Pazar', short: 'Pz' },
					].map((day, i) => (
						<li key={i} onClick={() => changeDates(i + 1)} className={dates === i + 1 ? 'active' : null}>
							{isMobile ? day.short : day.long}
						</li>
					))}
				</ul>
			</div>

			<div className="stream-list">
				<ul>
					{/* Small: 4 */}
					{streaming?.length > 0 &&
						streaming?.map((stream, i) => (
							<li key={i} className={live?.programId === stream?.programId ? 'live' : ''}>
								<div className="stream-info">
									<div className="stream-broadcasters">
										{stream.broadcasters.map((broadcaster, i) => (
											<React.Fragment key={i}>
												<a href={`/programci/${SeoUrl(broadcaster.name)}-${broadcaster.id}`}>{broadcaster.name}</a>
												{stream.broadcasters.length > 0 && i < stream.broadcasters.length - 1 ? ',\u00A0' : ''}
											</React.Fragment>
										))}
									</div>

									<div className="stream-title">{stream.programInfo.name}</div>
								</div>

								<div className="stream-detail">
									<div className="stream-time">
										{stream.startTime} - {stream.endTime}
									</div>

									{auth && (
										<div className="stream-favorite">
											<FavoriteButton
												favorite={favoritePrograms && favoritePrograms.find((x) => x === stream.programId)}
												programId={stream.programId}
												addFavorite={(data) => addFavorite(data)}
											/>
										</div>
									)}
								</div>
							</li>
						))}
				</ul>

				{isFetching && (
					<div className="stream-list-loading">
						<TailSpin color="#23439c" height={64} width={64} />
					</div>
				)}
			</div>
		</section>
	);
};

const mapStateToProps = (state) => {
	return {
		streaming: state.Streaming.streaming,
		isFetching: state.Streaming.isFetching,
		live: state.LiveStream.live,
		programs: state.Favorites.programs,
	};
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
	const { dispatch } = dispatchProps;
	const { actions: Streaming } = require('../redux/StreamingRedux');
	const { actions: Favorites } = require('../redux/FavoriteRedux');

	return {
		...ownProps,
		...stateProps,
		fetchStreaming: (day) => Streaming.fetchStreaming(dispatch, day),
		fetchFavoritePrograms: (id) => Favorites.fetchFavoritePrograms(dispatch, id),
		setFavoriteProgram: (data) => Favorites.setFavoriteProgram(dispatch, data),
	};
};

export default connect(mapStateToProps, null, mergeProps)(Streaming);
