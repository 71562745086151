/* eslint-disable import/no-anonymous-default-export */
import { reducer as SettingsReducer } from './SettingsRedux';
import { reducer as ContactReducer } from './ContactRedux';
import { reducer as FrequenciesReducer } from './FrequencyRedux';
import { reducer as BroadcastersReducer } from './BroadcasterRedux';
import { reducer as StreamingReducer } from './StreamingRedux';
import { reducer as LiveReducer } from './LiveRedux';
import { reducer as NewsReducer } from './NewsRedux';
import { reducer as PointStatusReducer } from './PointStatusRedux';
import { reducer as ProgramReducer } from './ProgramRedux';
import { reducer as LiveScoreReducer } from './LiveScoreRedux';
import { reducer as LoginReducer } from './LoginRedux';
import { reducer as RegisterReducer } from './RegisterRedux';
import { reducer as FavoriteReducer } from './FavoriteRedux';
import { reducer as MatchDetailReducer } from './MatchDetailRedux';
import { reducer as UserReducer } from './UserRedux';
import { reducer as PodcastsReducer } from './PodcastsRedux';
import { reducer as PlayReducer } from './PlayRedux';

export default {
	Settings: SettingsReducer,
	Contact: ContactReducer,
	Frequencies: FrequenciesReducer,
	Broadcasters: BroadcastersReducer,
	Streaming: StreamingReducer,
	LiveStream: LiveReducer,
	News: NewsReducer,
	PointStatus: PointStatusReducer,
	Programs: ProgramReducer,
	LiveScore: LiveScoreReducer,
	Login: LoginReducer,
	Register: RegisterReducer,
	Favorites: FavoriteReducer,
	MatchDetail: MatchDetailReducer,
	User: UserReducer,
	Podcasts: PodcastsReducer,
	Play: PlayReducer,
};
