/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { LocaleTeamName, TeamImage } from '../utils';

const PointStatus = ({ fetchPointStatus, pointStatus }) => {
	useEffect(() => {
		fetchPointStatus(1);
	}, []);

	return (
		<div className="point-status">
			<div className="point-status-header">
				<div className="point-status-info">
					<div className="league-name">Spor Toto Süper Lig</div>
					<div className="league-country">Türkiye</div>
				</div>

				{/* <div className="point-status-filter">
					<button>
						<ion-icon name="filter"></ion-icon>
					</button>
				</div> */}
			</div>

			{/* <div className="card-selector">
				<ul>
					<li>
						<a href="#!" className="active">
							Puan Durumu
						</a>
					</li>
					<li>
						<a href="#!">Fikstür</a>
					</li>
				</ul>
			</div> */}

			<div className="table-head">
				<div className="th">Takımlar</div>

				<div className="th-group">
					<div className="th">O</div>
					<div className="th">A</div>
					<div className="th">PU</div>
				</div>
			</div>

			<div className="table-body">
				<ul>
					{pointStatus?.standings?.length > 0 &&
						pointStatus?.standings[0]?.map((team, i) => (
							<li key={i}>
								<a href={`https://ajansspor.com/futbol/takim/genel-bakis/${team.team.id}`} target="_blank" rel="noreferrer">
									<div className="td order">
										<span>{team.rank}</span>
									</div>
									<div className="td">
										<div className="team-image">
											<img src={TeamImage(team.team.logo)} alt={LocaleTeamName(team.team.name)} />
										</div>

										<span>{LocaleTeamName(team.team.name)}</span>
									</div>

									<div className="td-group">
										<div className="td">{team.all.win + team.all.draw + team.all.lose}</div>
										<div className="td">{team.all.goals.for}</div>
										<div className="td">{team.points}</div>
									</div>
								</a>
							</li>
						))}
				</ul>

				<div className="all-btn">
					<a href="https://ajansspor.com/kategori/16/futbol#category-point-status" target="_blank" rel="noreferrer">
						Tümünü gör
					</a>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		pointStatus: state.PointStatus.pointstatus,
	};
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
	const { dispatch } = dispatchProps;
	const { actions: PointStatus } = require('../redux/PointStatusRedux');

	return {
		...stateProps,
		...ownProps,
		fetchPointStatus: (id) => PointStatus.fetchPointStatus(dispatch, id),
	};
};

export default connect(mapStateToProps, null, mergeProps)(PointStatus);
