/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Header, Footer, Contact as Contacts, Programs } from '../components';

const Contact = ({ settings }) => {
	return (
		<>
			<Helmet>
				<title>İletişim | Radyospor - Türkiye'nin İlk Spor Radyosu</title>
			</Helmet>

			<Header page="contact" title="İletişim" />

			<div className="container">
				<Contacts {...settings} />

				<main>
					<Programs />
				</main>
			</div>

			<Footer />
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		settings: state.Settings.settings,
	};
};

export default connect(mapStateToProps)(Contact);
