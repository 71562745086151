import api from '../api';

const types = {
	FETCH_LIVE_SCORE: 'FETCH_LIVE_SCORE',
	FETCH_LIVE_SCORE_SUCCESS: 'FETCH_LIVE_SCORE_SUCCESS',
	FETCH_LIVE_SCORE_FAILURE: 'FETCH_LIVE_SCORE_FAILURE',
};

export const actions = {
	fetchLiveMatches: (dispatch, day) => {
		//dispatch({ type: types.FETCH_LIVE_SCORE });

		api.liveMatch(day)
			.then((res) => dispatch({ type: types.FETCH_LIVE_SCORE_SUCCESS, matches: res.liveMatches }))
			.catch((error) => dispatch({ type: types.FETCH_LIVE_SCORE_FAILURE, error }));
	},
};

const initialState = {
	isFetching: true,
	error: null,
	matches: {},
};

export const reducer = (state = initialState, action) => {
	const { error, type, matches } = action;

	switch (type) {
		case types.FETCH_LIVE_SCORE:
			return { ...initialState };
		case types.FETCH_LIVE_SCORE_SUCCESS:
			return { ...state, matches, isFetching: false, error };
		case types.FETCH_LIVE_SCORE_FAILURE:
			return { ...state, error, isFetching: false };
		default:
			return state;
	}
};
