/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { connect } from 'react-redux';

const Frequencies = ({ hideTitle, fetchFrequencies, frequencies }) => {
	useEffect(() => {
		fetchFrequencies();
	}, []);

	return (
		<section className="frequencies">
			{!hideTitle && <div className="section-title">Frekanslarımız</div>}

			<div className="frequency-info">UYDU / {frequencies.length > 0 && frequencies.find((x) => x.name === 'Uydu').value}</div>

			<div className="frequency-map">
				<img src="/assets/images/frequency-map.webp" alt="" />
			</div>
		</section>
	);
};

const mapStateToProps = (state) => {
	return {
		frequencies: state.Frequencies.frequencies,
	};
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
	const { dispatch } = dispatchProps;
	const { actions: Frequencies } = require('../redux/FrequencyRedux');

	return {
		...ownProps,
		...stateProps,
		fetchFrequencies: () => Frequencies.fetchFrequencies(dispatch),
	};
};

export default connect(mapStateToProps, null, mergeProps)(Frequencies);
