/* eslint-disable react-hooks/exhaustive-deps  */
import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Helmet } from 'react-helmet';
import { Header, Footer, Programs, BroadcasterSidebar, ListenedPodcast } from '../components';
import { SeoUrl, UID, NewsImage, getTokenDecoded, getToken } from '../utils';

moment.locale('tr');

const ProgramDetail = ({ fetchBroadcasters, broadcasters, fetchBroadcaster, broadcaster, fetchProgramDetail, program, live, news, programs, fetchFavoritePrograms, setFavoriteProgram }) => {
	const [currentPage, setCurrentPage] = useState(1);
	const [sorting, setSorting] = useState('az');
	const [sortStatus, setSortStatus] = useState(false);
	const [isFavorite, setIsFavorite] = useState(false);
	const [user, setUser] = useState({});
	const viewPerPage = 10;

	const maxPage = parseInt(program.count / viewPerPage, 10);

	useEffect(() => {
		fetchBroadcasters();

		if (getToken()) {
			const user = getTokenDecoded(getToken());
			setUser(user);

			fetchBroadcaster(user.id);
			fetchFavoritePrograms(`?userId=${user.id}`);
		}

		if (isNaN(UID)) {
			window.location.href = '/';
		} else {
			fetchProgramDetail(UID);
		}
	}, []);

	useEffect(() => {
		const find = programs.programs && programs.programs.find((x) => x === UID);
		const status = typeof find === 'undefined' ? false : true;
		setIsFavorite(status);
	}, [programs]);

	const handleClick = (event) => {
		setCurrentPage(event);
	};

	const setFavorite = (id) => {
		setIsFavorite(!isFavorite);

		if (Object.keys(user).length > 0) {
			setFavoriteProgram({ programs: id, userId: user.id });
		}
	};

	const indexOfLast = currentPage * viewPerPage;
	const indexOfFirst = indexOfLast - viewPerPage;

	return (
		<>
			<Helmet>
				<title>{`${program.name} | Radyospor - Türkiye'nin İlk Spor Radyosu`}</title>
			</Helmet>

			<Header />

			<div className="container">
				<div className="section-title">
					<h1>{program.name}</h1>
				</div>

				<main className="no-margin">
					<div className="row">
						<div className="col-lg-8">
							<section className={`program-detail${Object.keys(program).length > 0 && program.podcasts.length <= 0 ? ' unpublished' : ''}`}>
								<div className="program-image">
									<img src={program.image} alt="" />

									{Object.keys(user).length > 0 && (
										<button className={`program-favorite${isFavorite ? ' favorited' : ''}`} onClick={() => setFavorite(program.id)}>
											<ion-icon name="star"></ion-icon>
										</button>
									)}
								</div>

								<div className="program-info">
									{typeof live !== 'undefined' && Object.keys(live).length > 0 && program.id === live.programId && <div className="live-info">Şu anda yayında</div>}

									{Object.keys(program).length > 0 && !program.status && <div className="unpublished-title">Program yayından kaldırılmıştır.</div>}
									<div className="program-name">{program.name}</div>

									<div className="program-broadcasters">
										{Object.keys(program).length > 0 &&
											program.programDetail.length > 0 &&
											program.programDetail[0].broadcasters.length > 0 &&
											program.programDetail[0].broadcasters.map((broadcaster, i) => (
												<Fragment key={i}>
													<a href={`/programci/${SeoUrl(broadcaster.name)}-${broadcaster.id}`}>{broadcaster.name}</a>
													{program.programDetail[0].broadcasters.length > 0 && i < program.programDetail[0].broadcasters.length - 1 ? ',\u00A0' : ''}
												</Fragment>
											))}
									</div>

									{typeof program.info !== 'undefined' && <p>{program.info}</p>}
								</div>
							</section>

							{!program.status ||
								(Object.keys(program).length > 0 && program.podcasts.length <= 0 && (
									<div className="unpublished-program">
										<img src="/assets/images/unpublished.webp" alt="" />
									</div>
								))}

							<div className="section-seperator"></div>

							{programs.status && Object.keys(program).length > 0 && (
								<>
									<section className="listened-podcasts">
										<div className="listened-podcast-head">
											<div className="listened-podcast-title">Podcast Arşivi</div>
											<div className="podcast-filter">
												<span onClick={() => setSortStatus(!sortStatus)} className="pointer">
													<ion-icon name="filter"></ion-icon>
												</span>

												{sortStatus && (
													<ul className="dropdown">
														<li
															onClick={() => {
																setSorting('az');
																setSortStatus(false);
															}}
															className={sorting === 'az' ? 'active' : ''}
														>
															Yeniden eskiye
														</li>
														<li
															onClick={() => {
																setSorting('za');
																setSortStatus(false);
															}}
															className={sorting === 'za' ? 'active' : ''}
														>
															Eskiden yeniye
														</li>
													</ul>
												)}
											</div>
										</div>

										<div className="listened-podcast-list">
											{program.podcasts.length > 0 ? (
												program.podcasts
													.sort((x, y) => (sorting === 'az' ? y.id - x.id : x.id - y.id))
													.slice(indexOfFirst, indexOfLast)
													.map((podcast, i) => (
														<ListenedPodcast
															key={i}
															id={podcast.id}
															image={program.image}
															file={podcast.name + '.mp3'}
															name={program.name}
															title={podcast.title}
															date={podcast.date}
															datetime={podcast.createdAt}
															broadcasters={Object.keys(program).length > 0 && program.programDetail[0].broadcasters.length > 0 && program.programDetail[0].broadcasters}
														/>
													))
											) : (
												<div className="podcast-not-found">Podcast bulunamadı.</div>
											)}
										</div>

										{program.count > 0 && (
											<div className="listened-podcast-pagination">
												<ul>
													{currentPage > 1 && (
														<li onClick={() => handleClick(currentPage - 1)}>
															{' '}
															<ion-icon name="chevron-back-outline"></ion-icon>{' '}
														</li>
													)}

													{[...Array(5)].map((_, i) => {
														const page = currentPage < 3 ? i + 1 : currentPage - 2 + i;

														if (!(page > maxPage + 1)) {
															return (
																<li onClick={() => handleClick(page)} className={currentPage === page ? 'active' : ''}>
																	{page}
																</li>
															);
														}

														return false;
													})}
													{/* {currentPage < maxPage - 3 && (
														<li className={currentPage === maxPage + 1 ? 'active' : ''} onClick={() => handleClick(maxPage + 1)}>
															...{maxPage + 1}
														</li>
													)} */}
													{maxPage > 1 && currentPage < maxPage && (
														<li onClick={() => handleClick(currentPage + 1)}>
															{' '}
															<ion-icon name="chevron-forward-outline"></ion-icon>
														</li>
													)}
												</ul>
											</div>
										)}
									</section>
									<div className="section-seperator"></div>
								</>
							)}

							<section className="news">
								<div className="news-title">Spor Haberleri</div>

								<div className="news-list">
									<ul>
										{news?.length > 0 &&
											news?.slice(0, 4).map((list, i) => (
												<li key={i}>
													<a href={`https://ajansspor.com/haber/${SeoUrl(list.Title)}-${list.ContentId}`} target="_blank" rel="noreferrer">
														<div className="news-image">
															<img src={NewsImage(list.MediaId)} alt="" />
														</div>

														<div className="news-info">
															<div className="news-source">
																<img src="/assets/images/ajansspor.svg" alt="" />
															</div>

															<div className="news-head">
																{/* <div className="news-author">Tolgahan Akkuş</div> */}

																<div className="news-date">{moment(list.UpdatedDate).format('DD MMMM YYYY, HH:mm')}</div>
															</div>

															<p>{list.Title}</p>

															{/* <div className="news-footer">
															<div className="news-read-count">
																<ion-icon name="eye"></ion-icon>
																<span>12.268.442</span>
															</div>

															<button className="podcast-favorite">
																<ion-icon name="star"></ion-icon>
															</button>

															<button className="podcast-share">
																<ion-icon name="share-social-sharp"></ion-icon>
															</button>
														</div> */}
														</div>
													</a>
												</li>
											))}
									</ul>
								</div>
							</section>
						</div>

						<div className="col-lg-4">
							<aside>
								<BroadcasterSidebar noMargin count={4} broadcasters={broadcasters} />
							</aside>
						</div>
					</div>

					<Programs />
				</main>
			</div>

			<Footer />
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		broadcasters: state.Broadcasters.broadcasters,
		broadcaster: state.Broadcasters.broadcaster,
		program: state.Programs.program,
		live: state.LiveStream.live,
		news: state.News.news,
		programs: state.Favorites.programs,
	};
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
	const { dispatch } = dispatchProps;
	const { actions: Broadcasters } = require('../redux/BroadcasterRedux');
	const { actions: ProgramDetail } = require('../redux/ProgramRedux');
	const { actions: News } = require('../redux/NewsRedux');
	const { actions: Favorites } = require('../redux/FavoriteRedux');

	return {
		...ownProps,
		...stateProps,
		fetchBroadcasters: () => Broadcasters.fetchBroadcasters(dispatch),
		fetchBroadcaster: (id) => Broadcasters.fetchBroadcaster(dispatch, id),
		fetchProgramDetail: (id) => ProgramDetail.fetchProgram(dispatch, id),
		fetchNews: () => News.fetchNews(dispatch),
		fetchFavoritePrograms: (id) => Favorites.fetchFavoritePrograms(dispatch, id),
		setFavoriteProgram: (data) => Favorites.setFavoriteProgram(dispatch, data),
	};
};

export default connect(mapStateToProps, null, mergeProps)(ProgramDetail);
