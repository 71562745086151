/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getToken, getTokenDecoded, SeoUrl } from '../utils';

const ProgramItem = ({ setFavorited, program, favoritePrograms, favoriteStatus }) => {
	const [isFavorite, setIsFavorite] = useState(false);

	const setFavorite = () => {
		setIsFavorite(!isFavorite);
		setFavorited(program.id);
	};

	useEffect(() => {
		const find = favoritePrograms && favoritePrograms.find((x) => x === program.id);
		const status = typeof find === 'undefined' ? false : true;
		setIsFavorite(status);
	}, [favoritePrograms]);

	return (
		<li>
			<a href={`/program/${SeoUrl(program.name)}-${program.id}`}>
				<img src={program.image} alt="" />
			</a>

			{favoriteStatus && (
				<div className={`favorite-program${isFavorite ? ' favorited' : ''}`}>
					<button onClick={() => setFavorite()}>
						<ion-icon name="star"></ion-icon>
					</button>
				</div>
			)}
		</li>
	);
};

const Programs = ({ fetchPrograms, programs, favorites, fetchFavoritePrograms, setFavoriteProgram }) => {
	const [user, setUser] = useState({});
	const [favoritePrograms, setFavoritePrograms] = useState([]);

	useEffect(() => {
		fetchPrograms();

		if (getToken()) {
			const user = getTokenDecoded(getToken());
			setUser(user);

			if (!favorites) {
				fetchFavoritePrograms(`?userId=${user.id}`);
			}
		}
	}, []);

	useEffect(() => {
		if (typeof favorites.programs !== 'undefined') {
			setFavoritePrograms(favorites.programs);
		} else {
			const arr = [];
			favorites.length > 0 && favorites.map((prg) => arr.push(prg.id));
			setFavoritePrograms(arr);
		}
	}, [favorites]);

	const setFavorite = (id) => {
		if (Object.keys(user).length > 0) {
			setFavoriteProgram({ programs: id, userId: user.id });
		}
	};

	return (
		<section className="programs">
			<div className="section-title">Tüm Programlarımız</div>

			<div className="program-list">
				<ul>
					{programs?.length > 0 &&
						programs
							?.filter((c) => c.status)
							.map((program, i) => (
								<ProgramItem
									favoriteStatus={Object.keys(user).length > 0 ? true : false}
									key={i}
									setFavorited={(id) => setFavorite(id)}
									program={program}
									favoritePrograms={favoritePrograms}
								/>
							))}
				</ul>
			</div>
		</section>
	);
};

const mapStateToProps = (state) => {
	return {
		programs: state.Programs.programs,
		favorites: state.Favorites.programs,
	};
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
	const { dispatch } = dispatchProps;
	const { actions: Programs } = require('../redux/ProgramRedux');
	const { actions: Favorites } = require('../redux/FavoriteRedux');

	return {
		...stateProps,
		...ownProps,
		fetchPrograms: () => Programs.fetchPrograms(dispatch),
		fetchFavoritePrograms: (id) => Favorites.fetchFavoritePrograms(dispatch, id),
		setFavoriteProgram: (data) => Favorites.setFavoriteProgram(dispatch, data),
	};
};

export default connect(mapStateToProps, null, mergeProps)(Programs);
