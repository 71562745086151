import api from '../api';

const types = {
	FETCH_BROADCASTERS: 'FETCH_BROADCASTERS',
	FETCH_BROADCASTERS_SUCCESS: 'FETCH_BROADCASTERS_SUCCESS',
	FETCH_BROADCASTERS_FAILURE: 'FETCH_BROADCASTERS_FAILURE',

	FETCH_BROADCASTER: 'FETCH_BROADCASTER',
	FETCH_BROADCASTER_SUCCESS: 'FETCH_BROADCASTER_SUCCESS',
	FETCH_BROADCASTER_FAILURE: 'FETCH_BROADCASTER_FAILURE',
};

export const actions = {
	fetchBroadcasters: (dispatch) => {
		dispatch({ type: types.FETCH_BROADCASTERS });

		api.broadcasters()
			.then((res) => dispatch({ type: types.FETCH_BROADCASTERS_SUCCESS, broadcasters: res }))
			.catch((error) => dispatch({ type: types.FETCH_BROADCASTERS_FAILURE, error }));
	},
	fetchBroadcaster: (dispatch, id) => {
		dispatch({ type: types.FETCH_BROADCASTER });

		api.broadcaster(id)
			.then((res) => dispatch({ type: types.FETCH_BROADCASTER_SUCCESS, broadcaster: res }))
			.catch((error) => dispatch({ type: types.FETCH_BROADCASTER_FAILURE, error }));
	},
};

const initialState = {
	isFetching: true,
	error: null,
	broadcasters: [],
	broadcaster: {},
};

export const reducer = (state = initialState, action) => {
	const { error, type, broadcasters, broadcaster } = action;

	switch (type) {
		case types.FETCH_BROADCASTERS:
			return { ...initialState };
		case types.FETCH_BROADCASTERS_SUCCESS:
			return { ...state, broadcasters, isFetching: false, error };
		case types.FETCH_BROADCASTERS_FAILURE:
			return { ...state, error, isFetching: false };

		case types.FETCH_BROADCASTER:
			return { ...initialState };
		case types.FETCH_BROADCASTER_SUCCESS:
			return { ...state, broadcaster, isFetching: false, error };
		case types.FETCH_BROADCASTER_FAILURE:
			return { ...state, error, isFetching: false };
		default:
			return state;
	}
};
