import React, { useEffect, useState } from 'react';
import ReactPlaceholder from 'react-placeholder';
import 'react-placeholder/lib/reactPlaceholder.css';
import { connect } from 'react-redux';
import { Oval } from 'react-loader-spinner';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import InputMask from 'react-input-mask';
import ReCAPTCHA from 'react-google-recaptcha';

const validationSchema = Yup.object({
	name: Yup.string().required('Ad soyad boş olamaz.'),
	emailAddress: Yup.string().email().required('Eposta adresi boş olamaz.'),
	subject: Yup.string().required('Konu başlığı boş olamaz.'),
	message: Yup.string().required('Mesaj boş olamaz.'),
});

const Contact = ({ address, location, phone, fax, email, addContact, contact }) => {
	const [status, setStatus] = useState(false);
	const [captcha, setCaptcha] = useState();

	const os = () => {
		let OSName = 'Unknown OS';
		if (navigator.appVersion.indexOf('Win') !== -1) OSName = 'Windows';
		if (navigator.appVersion.indexOf('Mac') !== -1) OSName = 'MacOS';
		if (navigator.appVersion.indexOf('X11') !== -1) OSName = 'UNIX';
		if (navigator.appVersion.indexOf('Linux') !== -1) OSName = 'Linux';

		return OSName;
	};

	const browser = () => {
		if ((navigator.userAgent.indexOf('Opera') || navigator.userAgent.indexOf('OPR')) !== -1) {
			return 'Opera';
		} else if (navigator.userAgent.indexOf('Chrome') !== -1) {
			return 'Chrome';
		} else if (navigator.userAgent.indexOf('Safari') !== -1) {
			return 'Safari';
		} else if (navigator.userAgent.indexOf('Firefox') !== -1) {
			return 'Firefox';
		} else if (navigator.userAgent.indexOf('MSIE') !== -1 || !!document.documentMode === true) {
			return 'Internet Explorer';
		} else {
			return 'Tanımsız';
		}
	};

	const recaptchaChange = (value) => {
		setCaptcha(value);
	};

	const { handleSubmit, handleChange, values, errors } = useFormik({
		initialValues: {
			name: '',
			emailAddress: '',
			phoneNumber: '',
			subject: '',
			message: '',
		},
		validationSchema,
		onSubmit: async (values) => {
			if (captcha) {
				addContact({ ...values, os: os(), browser: browser() });
			} else {
				alert('Robot olmadığınızı doğrulayın.');
			}
		},
	});

	useEffect(() => {
		setStatus(contact.isFetching);
	}, [contact.isFetching]);

	useEffect(() => {
		if (Object.keys(contact.contact).length > 0) {
			setTimeout(() => {
				window.location.reload();
			}, 1500);
		}
	}, [contact.contact]);

	return (
		<section className="contact">
			<div className="section-title">İletişim</div>

			<div className="contact-content">
				<ul>
					<li>
						<span>Yönetim Yeri Adresi</span>

						<ReactPlaceholder showLoadingAnimation type="text" rows={2} ready={address}>
							<div className="contact-maps">
								<span>{address}</span>
								<a href={location} target="_blank" rel="noreferrer">
									[Haritada Görüntüle]
								</a>
							</div>
						</ReactPlaceholder>
					</li>

					<li>
						<span>Telefon No</span>
						<ReactPlaceholder showLoadingAnimation type="text" rows={1} ready={phone}>
							<a href={phone && phone.replace(/ /g, '')}>{phone}</a>
						</ReactPlaceholder>
					</li>

					<li>
						<span>Fax No</span>
						<ReactPlaceholder showLoadingAnimation type="text" rows={1} ready={fax}>
							{fax}
						</ReactPlaceholder>
					</li>
					<li>
						<span>E-posta</span>
						<ReactPlaceholder showLoadingAnimation type="text" rows={1} ready={email}>
							<a href={`mailto:${email}`}>{email}</a>
						</ReactPlaceholder>
					</li>
				</ul>

				<div className="contact-text">Bize mesajınızı iletebilirsiniz.</div>

				<div className="contact-form">
					<form action="" onSubmit={handleSubmit}>
						<div className="form-horizontal">
							<div className="form-group">
								<label htmlFor="name">Ad Soyad</label>
								<input type="text" id="name" name="name" placeholder="" onChange={handleChange} value={values.name} className={errors.name && 'error'} />
							</div>

							<div className="form-group">
								<label htmlFor="email">Eposta Adresi</label>
								<input type="text" id="email" name="emailAddress" placeholder="" onChange={handleChange} value={values.emailAddress} className={errors.emailAddress && 'error'} />
							</div>

							<div className="form-group">
								<label htmlFor="phone">Telefon No</label>
								<InputMask
									mask="+\90 999 999 99 99"
									maskPlaceholder={null}
									type="text"
									id="phone"
									name="phoneNumber"
									placeholder=""
									onChange={handleChange}
									value={values.phoneNumber}
								/>
							</div>

							<div className="form-group">
								<label htmlFor="subject">Konu</label>
								<select name="subject" id="subject" defaultValue="0" defaultChecked={values.subject} onChange={handleChange} className={errors.subject && 'error'}>
									<option value="0">Seçin</option>
									<option value="İstek">İstek</option>
									<option value="Öneri">Öneri</option>
									<option value="Şikayet">Şikayet</option>
									<option value="Reklam">Reklam</option>
									<option value="Diğer">Diğer</option>
								</select>
							</div>
						</div>

						<div className="form-group">
							<label htmlFor="message">Mesaj</label>
							<textarea name="message" id="message" cols="30" rows="10" onChange={handleChange} value={values.message} className={errors.message && 'error'}></textarea>
						</div>

						<div className="g-recaptcha">
							<ReCAPTCHA sitekey="6LdbGg0dAAAAAEAjn9-Oytdcrio68vTps3WcWigC" onChange={recaptchaChange} />
						</div>

						<div className="form-bottom">
							{Object.keys(errors).length > 0 && <div className="form-text error">Bir yada birden fazla boş olan mevcut.</div>}

							{contact.contact.message && <div className="form-text success">{contact.contact.message}</div>}

							<button type="submit">{status ? <Oval color="#fff" height={16} width={16} /> : 'Gönder'}</button>
						</div>
					</form>
				</div>
			</div>
		</section>
	);
};

const mapStateToProps = (state) => {
	return {
		contact: state.Contact,
	};
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
	const { dispatch } = dispatchProps;
	const { actions: Contact } = require('../redux/ContactRedux');

	return {
		...ownProps,
		...stateProps,
		addContact: (data) => Contact.addContact(dispatch, data),
	};
};

export default connect(mapStateToProps, null, mergeProps)(Contact);
