import api from '../api';
import { getToken, getTokenDecoded } from '../utils';

const types = {
	FETCH_FACEBOOK_LOGIN: 'FETCH_FACEBOOK_LOGIN',
	FETCH_FACEBOOK_LOGIN_SUCCESS: 'FETCH_FACEBOOK_LOGIN_SUCCESS',
	FETCH_FACEBOOK_LOGIN_FAILURE: 'FETCH_FACEBOOK_LOGIN_FAILURE',
	FETCH_USER: 'FETCH_USER',
	FETCH_USER_SUCCESS: 'FETCH_USER_SUCCESS',
	FETCH_USER_FAILURE: 'FETCH_USER_FAILURE',
	FETCH_USER_LOGIN: 'FETCH_USER_LOGIN',
	FETCH_USER_LOGIN_FAILURE: 'FETCH_USER_LOGIN_FAILURE',
	FETCH_USER_LOGIN_SUCCESS: 'FETCH_USER_LOGIN_SUCCESS',
};

export const actions = {
	facebook: (dispatch, data) => {
		dispatch({ type: types.FETCH_FACEBOOK_LOGIN });

		api.client().then((res) => {
			api.facebook({ accessToken: data.accessToken, ip: res.ipAddress, os: data.os, bw: data.bw })
				.then((res) => dispatch({ type: types.FETCH_FACEBOOK_LOGIN_SUCCESS, user: res }))
				.catch((error) => dispatch({ type: types.FETCH_FACEBOOK_LOGIN_FAILURE, error }));
		});
	},
	fetchUser: (dispatch) => {
		dispatch({ type: types.FETCH_USER });

		if (getToken()) {
			dispatch({ type: types.FETCH_USER_SUCCESS, user: { ...getTokenDecoded(getToken()), token: getToken() } });
		} else {
			dispatch({ type: types.FETCH_USER_FAILURE, error: 'Kullanıcı bulunamadı.' });
		}
	},
	fetchLoginUser: (dispatch, data) => {
		dispatch({ type: types.FETCH_USER_LOGIN });

		api.client().then((res) => {
			api.login(data).then((res) => {
				if (res.success) {
					dispatch({ type: types.FETCH_USER_LOGIN_SUCCESS, user: { ...getTokenDecoded(res.token), token: res.token } });
				} else {
					dispatch({ type: types.FETCH_USER_LOGIN_FAILURE, error: res.message });
				}
			});
		});
	},
};

const initialState = {
	isFetching: false,
	error: null,
	user: {},
};

export const reducer = (state = initialState, action) => {
	const { error, type, user } = action;

	switch (type) {
		case types.FETCH_FACEBOOK_LOGIN:
			return { ...initialState, isFetching: true };
		case types.FETCH_FACEBOOK_LOGIN_SUCCESS:
			return { ...state, user, isFetching: false, error };
		case types.FETCH_FACEBOOK_LOGIN_FAILURE:
			return { ...state, error, isFetching: false };
		case types.FETCH_USER:
			return { ...initialState, isFetching: true };
		case types.FETCH_USER_SUCCESS:
			return { ...state, user, isFetching: false, error };
		case types.FETCH_USER_FAILURE:
			return { ...state, error, isFetching: false };
		case types.FETCH_USER_LOGIN:
			return { ...initialState, isFetching: true };
		case types.FETCH_USER_LOGIN_SUCCESS:
			return { ...state, user, isFetching: false, error };
		case types.FETCH_USER_LOGIN_FAILURE:
			return { ...state, error, isFetching: false };
		default:
			return state;
	}
};
