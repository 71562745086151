import React from 'react';
import { SeoUrl } from '../utils';

const Broadcaster = ({ id, name, programs, favorite, removeFavorite }) => {
	return (
		<div className={`broadcaster-item${favorite ? ' broadcaster-item--favorite' : ''}`}>
			<div className="broadcaster-image">
				<img src="/assets/images/no-broadcaster.jpeg" alt="" />
			</div>

			<div className="broadcaster-info pt-0">
				<div className="broadcaster-name">
					<a href={`/programci/${SeoUrl(name)}-${id}`}>{name}</a>
				</div>

				{programs.length > 0 && (
					<div className="brodcaster-programs">
						<span>Programları</span>

						<ul>
							{programs.map((program, i) => (
								<React.Fragment key={i}>
									<li>
										<a href={`/program/${SeoUrl(program.name)}-${program.id}`}>{program.name}</a>
									</li>
									{programs.length > 0 && i < programs.length - 1 ? ',\u00A0' : ''}
								</React.Fragment>
							))}
						</ul>
					</div>
				)}
			</div>

			{favorite && (
				<div className="broadcaster-remove-favorite">
					<button onClick={() => removeFavorite(id)}>
						<ion-icon name="trash"></ion-icon>
					</button>
				</div>
			)}
		</div>
	);
};

export default Broadcaster;
