/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { BroadcasterSidebar, Footer, Header, ListenedPodcast } from '../components';
import { PossessiveSuffix } from '../possessive';
import { SeoUrl, UID } from '../utils';

const ProgramItem = ({ program }) => {
	return (
		<li>
			<a href={`/program/${SeoUrl(program.name)}-${program.id}`}>
				<img src={program.image} alt="" />
			</a>
		</li>
	);
};

const Broadcaster = ({ fetchBroadcaster, broadcaster, fetchBroadcasters, otherBroadcasters }) => {
	//const [broadcaster, setBroadcaster] = useState({});
	const [currentPage, setCurrentPage] = useState(1);
	const [maxPage, setMaxPage] = useState(0);
	const possessive = new PossessiveSuffix();
	const viewPerPage = 10;

	useEffect(() => {
		fetchBroadcaster(UID);
		fetchBroadcasters();
	}, []);

	useEffect(() => {
		if (broadcaster.length > 0) {
			setMaxPage(parseInt(broadcaster.length / viewPerPage, 10));
		}
	}, [broadcaster]);

	const handleClick = (event) => {
		setCurrentPage(event);
	};

	const indexOfLast = currentPage * viewPerPage;
	const indexOfFirst = indexOfLast - viewPerPage;

	return (
		<>
			<Helmet>
				<title>{`${broadcaster.name} | Radyospor - Türkiye'nin İlk Spor Radyosu`}</title>
			</Helmet>

			<Header />

			<div className="container">
				<div className="section-title">
					<h1>Programcı</h1>
				</div>

				{broadcaster && Object.keys(broadcaster).length > 0 && (
					<main className="no-margin profile">
						<div className="row">
							<div className="col-lg-8">
								<div className="profile-header">
									<div className="profile-image">
										<img src="/assets/images/no-broadcaster.jpeg" alt="" />
									</div>

									<div className="profile-info">
										<span className="profile-name">{broadcaster.name}</span>
									</div>
								</div>

								<div className="section-seperator"></div>

								<div className="profile-programs">
									<div className="profile-programs-title">{possessive?.word(broadcaster?.name).get()} Programları</div>

									<section className="programs">
										<div className="program-list">
											<ul>{broadcaster?.programs?.length > 0 && broadcaster?.programs?.map((program, i) => <ProgramItem key={i} program={program} />)}</ul>
										</div>
									</section>
								</div>

								<div className="section-seperator"></div>

								<section className="listened-podcasts">
									<div className="listened-podcast-head">
										<div className="listened-podcast-title">{possessive.word(broadcaster?.name).get()} Podcastleri</div>
									</div>

									<div className="listened-podcast-list">
										{broadcaster?.podcasts?.length > 0 &&
											broadcaster?.podcasts
												.sort((a, b) => b.id - a.id)
												.slice(indexOfFirst, indexOfLast)
												.map((podcast, i) => (
													<ListenedPodcast
														key={i}
														id={podcast.id}
														image={broadcaster.programs.find((x) => x.id === podcast.programId).image}
														programId={broadcaster.programs.find((x) => x.id === podcast.programId).id}
														file={podcast.name + '.mp3'}
														name={broadcaster.programs.find((x) => x.id === podcast.programId).name}
														title={podcast.title}
														date={podcast.date}
														datetime={podcast.createdAt}
													/>
												))}
									</div>

									{broadcaster.podcasts.length > 0 && (
										<div className="listened-podcast-pagination">
											<ul>
												{currentPage > 1 && (
													<li onClick={() => handleClick(currentPage - 1)}>
														{' '}
														<ion-icon name="chevron-back-outline"></ion-icon>{' '}
													</li>
												)}

												{[...Array(5)].map((_, i) => {
													const page = currentPage < 3 ? i + 1 : currentPage - 2 + i;

													if (!(page > maxPage + 1)) {
														return (
															<li key={i} onClick={() => handleClick(page)} className={currentPage === page ? 'active' : ''}>
																{page}
															</li>
														);
													}

													return false;
												})}
												{/* {currentPage < maxPage - 3 && (
														<li className={currentPage === maxPage + 1 ? 'active' : ''} onClick={() => handleClick(maxPage + 1)}>
															...{maxPage + 1}
														</li>
													)} */}
												{maxPage > 1 && currentPage < maxPage && (
													<li onClick={() => handleClick(currentPage + 1)}>
														{' '}
														<ion-icon name="chevron-forward-outline"></ion-icon>
													</li>
												)}
											</ul>
										</div>
									)}
								</section>
							</div>

							<div className="col-lg-4">
								<aside>
									<BroadcasterSidebar broadcasters={otherBroadcasters} noMargin />
								</aside>
							</div>
						</div>
					</main>
				)}
			</div>

			<Footer />
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		otherBroadcasters: state.Broadcasters.broadcasters,
		broadcaster: state.Broadcasters.broadcaster,
	};
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
	const { dispatch } = dispatchProps;
	const { actions: Broadcasters } = require('../redux/BroadcasterRedux');

	return {
		...ownProps,
		...stateProps,
		fetchBroadcaster: (id) => Broadcasters.fetchBroadcaster(dispatch, id),
		fetchBroadcasters: () => Broadcasters.fetchBroadcasters(dispatch),
	};
};

export default connect(mapStateToProps, null, mergeProps)(Broadcaster);
