import React, { Fragment } from 'react';
import moment from 'moment';
import { SeoUrl } from '../utils';
import { connect } from 'react-redux';

moment.locale('tr');

const FavoritePrograms = ({ id, name, streamDays, broadcasters, live, removeFavorite, image }) => {
	return (
		<div className="listened-podcast favorite-program">
			<div className="podcast-image">
				<img src={image} alt="" />
			</div>

			<div className="podcast-info">
				<div className="podcast-time">
					{streamDays &&
						streamDays.length > 0 &&
						streamDays.map((days, i) => (
							<Fragment key={i}>
								<span>{days.label}</span> {streamDays.length > 0 && i < streamDays.length - 1 ? ' - ' : ''}
							</Fragment>
						))}
				</div>

				<div className="podcast-title">
					<a href={`/program/${SeoUrl(name)}-${id}`}>{name}</a>
				</div>

				<div className="podcast-broadcasters">
					{broadcasters &&
						broadcasters.length > 0 &&
						broadcasters.map((broadcaster, i) => (
							<Fragment key={i}>
								<a href={`/programci/${SeoUrl(broadcaster.name)}-${broadcaster.id}`}>{broadcaster.name}</a>
								{broadcasters.length > 0 && i < broadcasters.length - 1 ? ' & ' : ''}
							</Fragment>
						))}
				</div>
			</div>

			<div className="favorite-sider">
				{live && live.programInfo.id === id && (
					<div className="program-is-live">
						<span>Canlı</span>
					</div>
				)}

				<div className="remove-favorite">
					<button onClick={() => removeFavorite(id)}>
						<ion-icon name="trash"></ion-icon>
					</button>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		live: state.LiveStream.live,
	};
};

export default connect(mapStateToProps)(FavoritePrograms);
