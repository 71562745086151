/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { SeoUrl } from '../utils';

moment.locale('tr');

const DailyNews = ({ fetchNews, news, noMargin }) => {
	useEffect(() => {
		fetchNews();
	}, []);

	return (
		<div className={`daily-news${noMargin ? ' no-margin' : ''}`}>
			<div className="daily-news-title">Günün Haberleri</div>

			<ul>
				{news.length > 0 &&
					news.slice(0, 5).map((list, i) => (
						<li key={i}>
							<a href={`https://ajansspor.com/haber/${SeoUrl(list.Title)}-${list.ContentId}`} target="_blank" rel="noreferrer">
								<div className="news-header">
									{/* <div className="news-author">Tolgahan Akkuş</div> */}
									<div className="news-date">{moment(list.UpdatedDate).format('DD MMMM YYYY, HH:mm')}</div>
								</div>

								<p>{list.Title}</p>

								{/* <div className="news-footer">
								<div className="news-read-count">
									<ion-icon name="eye"></ion-icon>
									<span>12.268.442</span>
								</div>

								<button className="podcast-favorite">
									<ion-icon name="star"></ion-icon>
								</button>

								<button className="podcast-share">
									<ion-icon name="share-social-sharp"></ion-icon>
								</button>
							</div> */}
							</a>
						</li>
					))}
			</ul>
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		news: state.News.news,
	};
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
	const { dispatch } = dispatchProps;
	const { actions: News } = require('../redux/NewsRedux');

	return {
		...ownProps,
		...stateProps,
		fetchNews: () => News.fetchNews(dispatch),
	};
};

export default connect(mapStateToProps, null, mergeProps)(DailyNews);
