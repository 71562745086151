import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Bars } from 'react-loader-spinner';

const validationSchema = Yup.object({
	email: Yup.string().email('Geçersiz eposta formatı.').required('Eposta adresi boş olamaz.'),
	password: Yup.string().min(6, 'Şifre en az 6 karakter olmalıdır.').required('Şifre boş olamaz.'),
});

const LoginModal = () => {
	const [loading, setLoading] = useState(false);

	const { handleSubmit, handleChange, values, errors } = useFormik({
		initialValues: {
			email: '',
			password: '',
		},
		validationSchema,
		onSubmit: (values) => {
			setLoading(true);

			setTimeout(() => {
				setLoading(false);
			}, 2000);
		},
	});

	return (
		<div className="user-modal">
			<div className="modal-inner">
				<div className="modal-header">
					<div className="modal-title">Giriş Yap</div>
					<div className="modal-close">
						<button type="button">
							<ion-icon name="close-outline"></ion-icon>
						</button>
					</div>
				</div>

				<div className="modal-body">
					<form action="" onSubmit={handleSubmit}>
						<div className="form-control">
							<label htmlFor="email">Eposta adresi</label>
							<input type="text" id="email" name="email" onChange={handleChange} value={values.email} className={errors.email && 'error'} disabled={loading} />

							{errors.email && <small className="alert-message">{errors.email}</small>}
						</div>

						<div className="form-control">
							<label htmlFor="password">Şifre</label>
							<input type="password" id="password" name="password" onChange={handleChange} value={values.password} className={errors.password && 'error'} disabled={loading} />

							{errors.password && <small className="alert-message">{errors.password}</small>}
						</div>

						<div className="modal-buttons">
							<button type="submit" className="btn btn-login" disabled={loading}>
								{loading ? <Bars color="#23439c" width={16} height={16} /> : 'Giriş Yap'}
							</button>

							<button type="button" className="btn btn-register">
								Kayıt Ol
							</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
};

export default LoginModal;
