/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Dropdown from 'react-dropdown';
//import Slider from 'react-slick';
import 'react-dropdown/style.css';
import { Helmet } from 'react-helmet';

import { Header, Streaming as StreamList, Footer, PointStatus, Frequencies, Programs, ListenedPodcast, BroadcasterSidebar, DailyNews } from '../components';

const options = [
	{ value: 'last', label: 'Son Eklenenler' },
	{ value: 'most-listened', label: 'En Çok Dinlenenler' },
	// { value: 'special', label: 'Özel Podcastler' },
];
const defaultOption = options[0];

const PodcastDetail = ({ fetchBroadcasters, broadcasters, fetchPodcasts, podcasts }) => {
	const [currentPage, setCurrentPage] = useState(1);
	const [type, setType] = useState('last');
	const viewPerPage = 10;

	const maxPage = parseInt(podcasts.length / viewPerPage, 10);
	//const sliderRef = React.useRef(null);

	useEffect(() => {
		fetchBroadcasters();
		fetchPodcasts(type);
	}, []);

	useEffect(() => {
		fetchPodcasts(type);
	}, [type]);

	const podcastListChange = (type) => {
		setType(type.value);
	};

	const handleClick = (event) => {
		setCurrentPage(event);
	};

	const indexOfLast = currentPage * viewPerPage;
	const indexOfFirst = indexOfLast - viewPerPage;

	// const PrevArrow = () => (
	// 	<button className="prev" onClick={() => sliderRef?.current?.slickPrev()}>
	// 		<ion-icon name="chevron-back-outline"></ion-icon>
	// 	</button>
	// );

	// const NextArrow = () => (
	// 	<button className="next" onClick={() => sliderRef?.current?.slickNext()}>
	// 		<ion-icon name="chevron-forward-outline"></ion-icon>
	// 	</button>
	// );

	// const settings = {
	// 	dots: false,
	// 	infinite: false,
	// 	speed: 500,
	// 	slidesToShow: 1,
	// 	slidesToScroll: 1,
	// 	arrows: false,
	// };

	return (
		<>
			<Helmet>
				<title>Podcastler | Radyospor - Türkiye'nin İlk Spor Radyosu</title>
			</Helmet>

			<Header page="podcasts" />

			<div className="container">
				<div className="section-title">
					<h1>Podcastler</h1>
				</div>
			</div>

			<div className="container">
				<main className="no-margin">
					<div className="row">
						<div className="col-lg-8">
							{/* <div className="podcast-carousel">
								<div className="carousel-arrows">
									<PrevArrow />
									<NextArrow />
								</div>

								<Slider ref={sliderRef} {...settings}>
									{[...Array(3)].map((_, i) => (
										<div key={i} className="podcast-carousel-item">
											<a href="#!">
												<img src="/assets/images/big-podcast-1.svg" alt="" />
											</a>
										</div>
									))}
								</Slider>
							</div> */}

							<section className="listened-podcasts">
								<div className="listened-podcast-head">
									{/* <div className="listened-podcast-title">Podcastler</div> */}

									<Dropdown
										baseClassName="dropdown"
										arrowOpen={<ion-icon name="filter"></ion-icon>}
										arrowClosed={<ion-icon name="filter"></ion-icon>}
										options={options}
										onChange={(e) => {
											podcastListChange(e);
											setCurrentPage(1);
										}}
										value={defaultOption}
										placeholder="Filtrele"
									/>
								</div>

								<div className="listened-podcast-list">
									{podcasts &&
										podcasts
											.filter((c) => c.id !== 2512)
											.slice(indexOfFirst, indexOfLast)
											.map((podcast, i) =>
												type !== 'special' ? (
													<ListenedPodcast
														key={i}
														id={podcast.id}
														image={podcast.program.image}
														programId={typeof podcast.program !== 'undefined' && podcast.program.id}
														file={podcast.name}
														name={typeof podcast.program !== 'undefined' && podcast.program.name}
														title={podcast.title}
														date={podcast.date}
														datetime={podcast.createdAt}
														broadcasters={podcast.broadcasters}
													/>
												) : (
													<ListenedPodcast
														key={i}
														id={podcast.id}
														image={podcast.image}
														programId={typeof podcast.programInfo !== 'undefined' && podcast.programInfo[0].id}
														file={`special/${podcast.name}.mp3`}
														name={typeof podcast.programInfo !== 'undefined' && podcast.programInfo[0].name}
														title={podcast.title}
														date={podcast.date}
														datetime={podcast.createdAt}
														broadcasters={
															typeof podcast.programInfo !== 'undefined' && podcast.programInfo[0].broadcasters.length > 0 && podcast.programInfo[0].broadcasters
														}
													/>
												),
											)}
								</div>

								{podcasts.length > 0 && (
									<div className="listened-podcast-pagination">
										<ul>
											{currentPage > 1 && (
												<li onClick={() => handleClick(currentPage - 1)}>
													{' '}
													<ion-icon name="chevron-back-outline"></ion-icon>{' '}
												</li>
											)}

											{[...Array(5)].map((_, i) => {
												const page = currentPage < 3 ? i + 1 : currentPage - 2 + i;

												if (!(page > maxPage + 1)) {
													return (
														<li onClick={() => handleClick(page)} className={currentPage === page ? 'active' : ''}>
															{page}
														</li>
													);
												}

												return false;
											})}
											{/* {currentPage < maxPage - 3 && (
														<li className={currentPage === maxPage + 1 ? 'active' : ''} onClick={() => handleClick(maxPage + 1)}>
															...{maxPage + 1}
														</li>
													)} */}
											{maxPage > 1 && currentPage < maxPage && (
												<li onClick={() => handleClick(currentPage + 1)}>
													{' '}
													<ion-icon name="chevron-forward-outline"></ion-icon>
												</li>
											)}
										</ul>
									</div>
								)}
							</section>

							<div className="section-seperator"></div>
							<StreamList />
						</div>

						<div className="col-lg-4">
							<aside>
								<BroadcasterSidebar broadcasters={broadcasters} noMargin />

								<DailyNews />
								<PointStatus />
							</aside>
						</div>
					</div>

					<Frequencies />
					<div className="section-seperator"></div>
					<Programs />
				</main>
			</div>

			<Footer />
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		broadcasters: state.Broadcasters.broadcasters,
		podcasts: state.Podcasts.podcasts,
	};
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
	const { dispatch } = dispatchProps;
	const { actions: Broadcasters } = require('../redux/BroadcasterRedux');
	const { actions: Podcasts } = require('../redux/PodcastsRedux');

	return {
		...stateProps,
		...ownProps,
		fetchBroadcasters: () => Broadcasters.fetchBroadcasters(dispatch),
		fetchPodcasts: (type) => Podcasts.fetchPodcast(dispatch, type),
	};
};

export default connect(mapStateToProps, null, mergeProps)(PodcastDetail);
