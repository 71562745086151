import api from '../api';

const types = {
	FETCH_PROGRAMS: 'FETCH_PROGRAMS',
	FETCH_PROGRAMS_SUCCESS: 'FETCH_PROGRAMS_SUCCESS',
	FETCH_PROGRAMS_FAILURE: 'FETCH_PROGRAMS_FAILURE',

	FETCH_PROGRAM_DETAIL: 'FETCH_PROGRAM_DETAIL',
	FETCH_PROGRAM_DETAIL_SUCCESS: 'FETCH_PROGRAM_DETAIL_SUCCESS',
	FETCH_PROGRAM_DETAIL_FAILURE: 'FETCH_PROGRAM_DETAIL_FAILURE',
};

export const actions = {
	fetchPrograms: (dispatch) => {
		dispatch({ type: types.FETCH_PROGRAMS });

		api.programs()
			.then((res) => dispatch({ type: types.FETCH_PROGRAMS_SUCCESS, programs: res.programs }))
			.catch((error) => dispatch({ type: types.FETCH_PROGRAMS_FAILURE, error }));
	},
	fetchProgram: (dispatch, id) => {
		dispatch({ type: types.FETCH_PROGRAM_DETAIL });

		api.program(id)
			.then((res) => dispatch({ type: types.FETCH_PROGRAM_DETAIL_SUCCESS, program: res }))
			.catch((error) => dispatch({ type: types.FETCH_PROGRAM_DETAIL_FAILURE, error }));
	},
};

const initialState = {
	isFetching: true,
	error: null,
	programs: [],
	program: {},
};

export const reducer = (state = initialState, action) => {
	const { error, type, programs, program } = action;

	switch (type) {
		case types.FETCH_PROGRAMS:
			return { ...initialState };
		case types.FETCH_PROGRAMS_SUCCESS:
			return { ...state, programs, isFetching: false, error };
		case types.FETCH_PROGRAMS_FAILURE:
			return { ...state, error, isFetching: false };
		case types.FETCH_PROGRAM_DETAIL:
			return { ...initialState };
		case types.FETCH_PROGRAM_DETAIL_SUCCESS:
			return { ...state, program, isFetching: false, error };
		case type.FETCH_PROGRAM_DETAIL_FAILURE:
			return { ...state, error, isFetching: false };
		default:
			return state;
	}
};
