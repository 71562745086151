import api from '../api';

const types = {
	FETCH_USER_UPDATE: 'FETCH_USER_UPDATE',
	FETCH_USER_UPDATE_SUCCESS: 'FETCH_USER_UPDATE_SUCCESS',
	FETCH_USER_UPDATE_FAILURE: 'FETCH_USER_UPDATE_FAILURE',
};

export const actions = {
	update: (dispatch, data) => {
		dispatch({ type: types.FETCH_USER_UPDATE });

		api.updateUser(data)
			.then((res) => {
				dispatch({ type: types.FETCH_USER_UPDATE_SUCCESS, user: res });
			})
			.catch((error) => dispatch({ type: types.FETCH_USER_UPDATE_FAILURE, error }));
	},
};

const initialState = {
	isFetching: false,
	error: null,
	user: {},
};

export const reducer = (state = initialState, action) => {
	const { error, type, user } = action;

	switch (type) {
		case types.FETCH_USER_UPDATE:
			return { ...initialState, isFetching: true };

		case types.FETCH_USER_UPDATE_SUCCESS:
			return { ...state, user, isFetching: false, error };

		case types.FETCH_USER_UPDATE_FAILURE:
			return { ...state, error, isFetching: false };

		default:
			return state;
	}
};
