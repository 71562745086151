/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import Slider from 'react-rangeslider';
import 'react-rangeslider/lib/index.css';
import ReactHlsPlayer from 'react-hls-player';
import { connect } from 'react-redux';
import { browser, getIp, os, SeoUrl } from '../utils';
import api from '../api';

const VolumeSlider = ({ changeVolume, changeMute }) => {
	const [value, setValue] = useState(100);
	const [volumeIcon, setVolumeIcon] = useState('volume-high-outline');
	const [isMute, setIsMute] = useState(false);

	const onChange = (value) => {
		setValue(value);
		changeVolume(value);

		if (value > 60) {
			setVolumeIcon('volume-high-outline');
		} else if (value <= 60 && value > 30) {
			setVolumeIcon('volume-medium-outline');
		} else if (value <= 30 && value > 0) {
			setVolumeIcon('volume-low-outline');
		} else {
			setVolumeIcon('volume-off-outline');
		}
	};

	const volumeMute = (mute) => {
		setIsMute(mute);
		changeMute(mute);
	};

	return (
		<>
			<button onClick={() => volumeMute(!isMute)}>
				<ion-icon name={isMute ? 'volume-mute' : volumeIcon}></ion-icon>
			</button>
			<Slider min={0} max={100} value={value} onChange={onChange} />
		</>
	);
};

const Favorite = ({ favorited, onClick }) => (
	<button className="favorite" onClick={() => onClick(!favorited)}>
		<div className={`toggle-liked${favorited ? ' active' : ''}`}>
			<div className="toggle-liked-sub">
				<ion-icon name={`heart${favorited ? '' : '-outline'}`}></ion-icon>
			</div>
		</div>
	</button>
);

const PlayerLive = ({ live, live_play, setPlayLive, settings, user, favoritePrograms, setFavoriteProgram }) => {
	const [isPlay, setIsPlay] = useState(false);
	const [isFavorite, setIsFavorite] = useState(true);
	const [volume, setVolume] = useState(0);
	const title = document.title;
	const playerRef = useRef();

	const play = async (status) => {
		setIsPlay(!status);
		setPlayLive(!status);
	};

	const changeVolume = (value) => {
		playerRef.current.volume = value / 100;
		setVolume(value);
	};

	const changeMuteStatus = (status) => {
		if (status) {
			playerRef.current.volume = 0;
		} else {
			playerRef.current.volume = volume / 100;
		}
	};

	const setFavorite = (status) => {
		setIsFavorite(status);

		if (Object.keys(live).length > 0 && Object.keys(user).length > 0) {
			setFavoriteProgram({ programs: live.programInfo.id, userId: user.id });
		}
	};

	useEffect(() => {
		const find = favoritePrograms && favoritePrograms.find((x) => x === live?.programInfo?.id);
		const status = typeof find === 'undefined' ? false : true;
		setIsFavorite(status);
	}, [favoritePrograms, live?.programInfo]);

	useEffect(async () => {
		setIsPlay(live_play);

		setTimeout(async () => {
			if (live_play) {
				playerRef.current.play();
				document.title = `► ${live?.programInfo?.name} yayında | ${title}`;

				if (Object.keys(live).length > 0 && Object.keys(user).length > 0) {
					const ip = await getIp();
					api.logs({ userId: user.id, logType: 1, eventId: live.programInfo.id, ip, os: os(), bw: browser() }).then((res) => res);
				}
			} else {
				playerRef?.current?.pause();
				if (live && Object.keys(live).length > 0 && live.programInfo.length > 0) {
					document.title = title.split(`► ${live.programInfo.name} yayında | `)[1];
				}
			}
		}, 300);
	}, [live_play]);

	return (
		<div className="player">
			{isPlay && <ReactHlsPlayer playerRef={playerRef} src={settings && settings.streamHLS} />}

			<button className="play" onClick={() => play(isPlay)}>
				{isPlay ? (
					<ion-icon name="pause" class="pause-icon"></ion-icon>
				) : (
					<>
						<ion-icon name="play" class="play-icon"></ion-icon>
						<ion-icon name="play" class="shadow-icon"></ion-icon>
					</>
				)}
			</button>

			{Object.keys(live).length > 0 && (
				<div className="program-info">
					<div className="program-image">
						<a href={`/program/${SeoUrl(live.programInfo.name)}-${live.programInfo.id}`}>
							<img src={live.programInfo.image} alt={live.programInfo.length > 0 ? live.programInfo[0].name : ''} />
						</a>
					</div>
					<div className="program-detail">
						<div className="program-name">
							<a href={`/program/${SeoUrl(live.programInfo.name)}-${live.programInfo.id}`}>{live.programInfo.name}</a>
						</div>
						<div className="program-broadcaster">
							{live.broadcasters.length > 0 &&
								live.broadcasters.map((broadcaster, i) => (
									<a key={i} href={`/programci/${SeoUrl(broadcaster.name)}-${broadcaster.id}`}>
										{broadcaster.name}
									</a>
								))}
						</div>
					</div>
				</div>
			)}

			<div className="volume">
				<VolumeSlider changeVolume={(val) => changeVolume(val)} changeMute={(status) => changeMuteStatus(status)} />
			</div>

			{user && Object.keys(user).length > 0 && <Favorite onClick={(status) => setFavorite(status)} favorited={isFavorite} />}
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		live: state.LiveStream.live,
		live_play: state.Play.live_play,
		settings: state.Settings.settings,
	};
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
	const { dispatch } = dispatchProps;
	const { actions: Play } = require('../redux/PlayRedux');

	return {
		...ownProps,
		...stateProps,
		setPlayLive: (status) => Play.setPlayLive(dispatch, status),
	};
};

export default connect(mapStateToProps, null, mergeProps)(PlayerLive);
