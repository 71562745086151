/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Header, Streaming as StreamList, Footer, PointStatus, Frequencies, Programs, DailyNews } from '../components';
import { getToken, getTokenDecoded, SeoUrl } from '../utils';

const Broadcaster = ({ broadcaster, setFavorited, favoriteBroadcasters, favoriteStatus }) => {
	const [isFavorite, setIsFavorite] = useState(false);

	const setFavorite = () => {
		setIsFavorite(!isFavorite);
		setFavorited(broadcaster.id);
	};

	useEffect(() => {
		const find = favoriteBroadcasters && favoriteBroadcasters.find((x) => x === broadcaster.id);
		const status = typeof find === 'undefined' ? false : true;
		setIsFavorite(status);
	}, [favoriteBroadcasters]);

	return (
		<div className="broadcaster-item">
			<div className="broadcaster-image">
				<img src="/assets/images/no-broadcaster.jpeg" alt="" />
			</div>

			<div className="broadcaster-info pt-0">
				<div className="broadcaster-name">
					<a href={`/programci/${SeoUrl(broadcaster.name)}-${broadcaster.id}`}>{broadcaster.name}</a>
				</div>

				{broadcaster.programs.length > 0 && (
					<div className="brodcaster-programs">
						<span>Programları</span>

						<ul>
							{broadcaster.programs
								.filter((x) => x.status)
								.map((program, i) => (
									<React.Fragment key={i}>
										<li>
											<a href={`/program/${SeoUrl(program.name)}-${program.id}`}>{program.name}</a>
										</li>
										{broadcaster.programs.length > 0 && i < broadcaster.programs.length - 1 ? ',\u00A0' : ''}
									</React.Fragment>
								))}
						</ul>
					</div>
				)}
			</div>

			{favoriteStatus && (
				<button className={`broadcaster-favorite${isFavorite ? ' favorited' : ''}`} onClick={() => setFavorite()}>
					<ion-icon name="star"></ion-icon>
				</button>
			)}
		</div>
	);
};

const Broadcasters = ({ fetchBroadcasters, broadcasters, favorites, fetchFavoriteBroadcasters, setFavoriteBroadcaster }) => {
	const [user, setUser] = useState({});
	const [favoriteBroadcasters, setFavoriteBroadcasters] = useState([]);

	useEffect(() => {
		if (!broadcasters) {
			fetchBroadcasters();
		}

		if (getToken()) {
			const user = getTokenDecoded(getToken());
			setUser(user);

			if (user && Object.keys(user).length > 0) {
				fetchFavoriteBroadcasters(user.id);
			}
		}

		// if (!podcasts || podcasts.length === 0) {
		// 	fetchPodcasts();
		// }
	}, []);

	useEffect(() => {
		if (favorites && typeof favorites !== 'undefined' && Array.isArray(favorites)) {
			const arr = [];
			favorites.map((fv) => arr.push(fv.id));
			setFavoriteBroadcasters(arr);
		}
	}, [favorites]);

	const setFavorite = (id) => {
		if (Object.keys(user).length > 0) {
			setFavoriteBroadcaster({ broadcasters: id, userId: user.id });
		}
	};

	return (
		<>
			<Helmet>
				<title>Programcılar | Radyospor - Türkiye'nin İlk Spor Radyosu</title>
			</Helmet>

			<Header page="broadcasters" />

			<div className="container">
				<div className="section-title">
					<h1>Programcılar</h1>
				</div>

				<main className="no-margin">
					<div className="row">
						<div className="col-lg-8">
							<section className="broadcasters">
								<div className="row">
									{broadcasters.length > 0 &&
										broadcasters
											.filter((x) => x.programs.length > 0)
											.map((broadcaster, i) => (
												<div className="col-lg-6" key={i}>
													<Broadcaster
														favoriteStatus={Object.keys(user).length > 0 ? true : false}
														broadcaster={broadcaster}
														favoriteBroadcasters={favoriteBroadcasters}
														setFavorited={(id) => setFavorite(id)}
													/>
												</div>
											))}
								</div>
							</section>
							<div className="section-seperator"></div>
							<StreamList />
						</div>

						<div className="col-lg-4">
							<aside>
								{/* {podcasts && podcasts.length > 0 && <DailyPodcasts single podcasts={podcasts} />} */}
								<DailyNews noMargin />
								<PointStatus />
							</aside>
						</div>
					</div>

					<Frequencies />
					<div className="section-seperator"></div>
					<Programs />
				</main>
			</div>

			<Footer />
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		broadcasters: state.Broadcasters.broadcasters,
		podcasts: state.Podcasts.podcasts,
		favorites: state.Favorites.broadcasters,
	};
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
	const { dispatch } = dispatchProps;
	const { actions: Broadcasters } = require('../redux/BroadcasterRedux');
	const { actions: Podcasts } = require('../redux/PodcastsRedux');
	const { actions: Favorites } = require('../redux/FavoriteRedux');

	return {
		...ownProps,
		...stateProps,
		fetchBroadcasters: () => Broadcasters.fetchBroadcasters(dispatch),
		fetchPodcasts: () => Podcasts.fetchPodcast(dispatch, 'last'),
		fetchFavoriteBroadcasters: (id) => Favorites.fetchFavoriteBroadcasters(dispatch, id),
		setFavoriteBroadcaster: (data) => Favorites.setFavoriteBroadcaster(dispatch, data),
	};
};

export default connect(mapStateToProps, null, mergeProps)(Broadcasters);
