import api from '../api';

const types = {
	FETCH_FAVORITE_PROGRAMS: 'FETCH_FAVORITE_PROGRAMS',
	FETCH_FAVORITE_PROGRAMS_SUCCESS: 'FETCH_FAVORITE_PROGRAMS_SUCCESS',
	FETCH_FAVORITE_PROGRAMS_FAILURE: 'FETCH_FAVORITE_PROGRAMS_FAILURE',

	SET_FAVORITE_PROGRAM: 'SET_FAVORITE_PROGRAM',
	SET_FAVORITE_PROGRAM_SUCCESS: 'SET_FAVORITE_PROGRAM_SUCCESS',
	SET_FAVORITE_PROGRAM_FAILURE: 'SET_FAVORITE_PROGRAM_FAILURE',

	FETCH_FAVORITE_MATCHES: 'FETCH_FAVORITE_MATCHES',
	FETCH_FAVORITE_MATCHES_SUCCESS: 'FETCH_FAVORITE_MATCHES_SUCCESS',
	FETCH_FAVORITE_MATCHES_FAILURE: 'FETCH_FAVORITE_MATCHES_FAILURE',

	SET_FAVORITE_MATCH: 'SET_FAVORITE_MATCH',
	SET_FAVORITE_MATCH_SUCCESS: 'SET_FAVORITE_MATCH_SUCCESS',
	SET_FAVORITE_MATCH_FAILURE: 'SET_FAVORITE_MATCH_FAILURE',

	FETCH_FAVORITE_BROADCASTERS: 'FETCH_FAVORITE_BROADCASTERS',
	FETCH_FAVORITE_BROADCASTERS_SUCCESS: 'FETCH_FAVORITE_BROADCASTERS_SUCCESS',
	FETCH_FAVORITE_BROADCASTERS_FAILURE: 'FETCH_FAVORITE_BROADCASTERS_FAILURE',

	SET_FAVORITE_BROADCASTER: 'SET_FAVORITE_BROADCASTER',
	SET_FAVORITE_BROADCASTER_SUCCESS: 'SET_FAVORITE_BROADCASTER_SUCCESS',
	SET_FAVORITE_BROADCASTER_FAILURE: 'SET_FAVORITE_BROADCASTER_FAILURE',

	FETCH_FAVORITE_PODCASTS: 'FETCH_FAVORITE_PODCASTS',
	FETCH_FAVORITE_PODCASTS_SUCCESS: 'FETCH_FAVORITE_PODCASTS_SUCCESS',
	FETCH_FAVORITE_PODCASTS_FAILURE: 'FETCH_FAVORITE_PODCASTS_FAILURE',

	SET_FAVORITE_PODCAST: 'SET_FAVORITE_PODCAST',
	SET_FAVORITE_PODCAST_SUCCESS: 'SET_FAVORITE_PODCAST_SUCCESS',
	SET_FAVORITE_PODCAST_FAILURE: 'SET_FAVORITE_PODCAST_FAILURE',
};

export const actions = {
	fetchFavoritePrograms: (dispatch, id) => {
		dispatch({ type: types.FETCH_FAVORITE_PROGRAMS });

		api.getFavoriteProgram(id)
			.then((res) => dispatch({ type: types.FETCH_FAVORITE_PROGRAMS_SUCCESS, programs: res }))
			.catch((error) => dispatch({ type: types.FETCH_FAVORITE_PROGRAMS_FAILURE, error }));
	},
	setFavoriteProgram: (dispatch, data) => {
		api.setFavoritePrograms(data)
			.then((res) => dispatch({ type: types.SET_FAVORITE_PROGRAM_SUCCESS, programs: res }))
			.catch((error) => dispatch({ type: types.SET_FAVORITE_PROGRAM_FAILURE, error }));
	},
	fetchFavoriteMatches: (dispatch, id) => {
		dispatch({ type: types.FETCH_FAVORITE_MATCHES });

		api.getFavoriteMatch(id)
			.then((res) => dispatch({ type: types.FETCH_FAVORITE_MATCHES_SUCCESS, matches: res }))
			.catch((error) => dispatch({ type: types.FETCH_FAVORITE_MATCHES_FAILURE, error }));
	},
	setFavoriteMatch: (dispatch, data) => {
		api.setFavoriteMatches(data)
			.then((res) => dispatch({ type: types.SET_FAVORITE_MATCH_SUCCESS, matches: res }))
			.catch((error) => dispatch({ type: types.SET_FAVORITE_MATCH_FAILURE, error }));
	},
	fetchFavoriteBroadcasters: (dispatch, id) => {
		dispatch({ type: types.FETCH_FAVORITE_BROADCASTERS });

		api.getFavoriteBroadcaster(id)
			.then((res) => dispatch({ type: types.FETCH_FAVORITE_BROADCASTERS_SUCCESS, broadcasters: res }))
			.catch((error) => dispatch({ type: types.FETCH_FAVORITE_BROADCASTERS_FAILURE, error }));
	},
	setFavoriteBroadcaster: (dispatch, data) => {
		api.setFavoriteBroadcasters(data)
			.then((res) => dispatch({ type: types.SET_FAVORITE_BROADCASTER_SUCCESS, broadcasters: res }))
			.catch((error) => dispatch({ type: types.SET_FAVORITE_BROADCASTER_FAILURE, error }));
	},
	fetchFavoritePodcasts: (dispatch, id) => {
		dispatch({ type: types.FETCH_FAVORITE_PODCASTS });

		api.getFavoritePodcast(id)
			.then((res) => dispatch({ type: types.FETCH_FAVORITE_PODCASTS_SUCCESS, podcasts: res }))
			.catch((error) => dispatch({ type: types.FETCH_FAVORITE_PODCASTS_FAILURE, error }));
	},
	setFavoritePodcast: (dispatch, data) => {
		api.setFavoritePodcasts(data)
			.then((res) => dispatch({ type: types.SET_FAVORITE_PODCAST_SUCCESS, podcasts: res }))
			.catch((error) => dispatch({ type: types.SET_FAVORITE_PODCAST_FAILURE, error }));
	},
};

const initialState = {
	isFetching: false,
	error: null,
	programs: {},
	podcasts: {},
	matches: {},
	broadcasters: [],
};

export const reducer = (state = initialState, action) => {
	const { error, type, programs, podcasts, matches, broadcasters } = action;

	switch (type) {
		// Programs
		case types.FETCH_FAVORITE_PROGRAMS:
			return { ...state, ...matches, ...broadcasters, ...podcasts };
		case types.FETCH_FAVORITE_PROGRAMS_SUCCESS:
			return { ...state, programs, ...matches, ...broadcasters, ...podcasts, isFetching: false, error };
		case types.FETCH_FAVORITE_PROGRAMS_FAILURE:
			return { ...state, error, isFetching: false };
		case types.SET_FAVORITE_PROGRAM:
			return { ...state, ...matches, ...broadcasters, ...podcasts };
		case types.SET_FAVORITE_PROGRAM_SUCCESS:
			return { ...state, programs, ...matches, ...broadcasters, ...podcasts, isFetching: false, error };
		case types.SET_FAVORITE_PROGRAM_FAILURE:
			return { ...state, error, isFetching: false };

		// Matches
		case types.FETCH_FAVORITE_MATCHES:
			return { ...state, ...programs, ...broadcasters, ...podcasts };
		case types.FETCH_FAVORITE_MATCHES_SUCCESS:
			return { ...state, ...programs, matches, ...broadcasters, ...podcasts, isFetching: false, error };
		case types.FETCH_FAVORITE_MATCHES_FAILURE:
			return { ...state, error, isFetching: false };
		case types.SET_FAVORITE_MATCH:
			return { ...state, ...programs, ...broadcasters, ...podcasts };
		case types.SET_FAVORITE_MATCH_SUCCESS:
			return { ...state, ...programs, matches, ...broadcasters, ...podcasts, isFetching: false, error };
		case types.SET_FAVORITE_MATCH_FAILURE:
			return { ...state, error, isFetching: false };

		// Broadcasters
		case types.FETCH_FAVORITE_BROADCASTERS:
			return { ...state, ...programs, ...matches, ...podcasts };
		case types.FETCH_FAVORITE_BROADCASTERS_SUCCESS:
			return { ...state, ...programs, ...matches, broadcasters, ...podcasts, isFetching: false, error };
		case types.FETCH_FAVORITE_BROADCASTERS_FAILURE:
			return { ...state, error, isFetching: false };
		case types.SET_FAVORITE_BROADCASTER:
			return { ...state, ...programs, ...matches, ...podcasts };
		case types.SET_FAVORITE_BROADCASTER_SUCCESS:
			return { ...state, ...programs, ...matches, broadcasters, ...podcasts, isFetching: false, error };
		case types.SET_FAVORITE_BROADCASTER_FAILURE:
			return { ...state, error, isFetching: false };

		// Podcasts
		case types.FETCH_FAVORITE_PODCASTS:
			return { ...state, ...matches, ...broadcasters, ...programs };
		case types.FETCH_FAVORITE_PODCASTS_SUCCESS:
			return { ...state, podcasts, ...programs, ...matches, ...broadcasters, isFetching: false, error };
		case types.FETCH_FAVORITE_PODCASTS_FAILURE:
			return { ...state, error, isFetching: false };
		case types.SET_FAVORITE_PODCAST:
			return { ...state, ...matches, ...broadcasters, ...programs };
		case types.SET_FAVORITE_PODCAST_SUCCESS:
			return { ...state, podcasts, ...programs, ...matches, ...broadcasters, isFetching: false, error };
		case types.SET_FAVORITE_PODCAST_FAILURE:
			return { ...state, error, isFetching: false };

		default:
			return state;
	}
};
