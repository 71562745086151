import api from '../api';

const types = {
	FETCH_FREQUENCIES: 'FETCH_FREQUENCIES',
	FETCH_FREQUENCIES_SUCCESS: 'FETCH_FREQUENCIES_SUCCESS',
	FETCH_FREQUENCIES_FAILURE: 'FETCH_FREQUENCIES_FAILURE',
};

export const actions = {
	fetchFrequencies: (dispatch) => {
		dispatch({ type: types.FETCH_FREQUENCIES });

		api.frequency()
			.then((res) => dispatch({ type: types.FETCH_FREQUENCIES_SUCCESS, frequencies: res.frequencies }))
			.catch((error) => dispatch({ type: types.FETCH_FREQUENCIES_FAILURE, error }));
	},
};

const initialState = {
	isFetching: true,
	error: null,
	frequencies: [],
};

export const reducer = (state = initialState, action) => {
	const { error, type, frequencies } = action;

	switch (type) {
		case types.FETCH_FREQUENCIES:
			return { ...initialState };
		case types.FETCH_FREQUENCIES_SUCCESS:
			return { ...state, frequencies, isFetching: false, error };
		case types.FETCH_FREQUENCIES_FAILURE:
			return { ...state, error, isFetching: false };
		default:
			return state;
	}
};
