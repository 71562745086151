/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel } from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import { Helmet } from 'react-helmet';

import { Header, Streaming as StreamList, Footer, PointStatus, NewsList, Programs, BroadcasterSidebar, DailyNews } from '../components';
import { getToken, getTokenDecoded, SeoUrl, TeamImage } from '../utils';

moment.locale('tr');

const FavoriteButton = ({ favorite, matchId, addFavorite }) => {
	const [active, setActive] = useState(favorite);

	useEffect(() => {
		setActive(favorite);
	}, [favorite]);

	const setFavorite = (data) => {
		addFavorite(data);
		setActive(!active);
	};

	return (
		<button type="button" onClick={() => setFavorite({ matches: matchId, userId: getTokenDecoded(getToken()).id })}>
			{active ? <img src="/assets/images/icons/favorite-filled.svg" alt="" /> : <img src="/assets/images/icons/favorite.svg" alt="" />}
		</button>
	);
};

const MatchList = ({ opened, count, tournament, matches, scored, auth, favorites, setFavoriteMatch, fetchMatchDetail, matchDetail }) => {
	const [open, setOpened] = useState(opened || false);
	const [favoriteMatches, setFavoriteMatches] = useState([]);
	const [detail, setDetail] = useState({});

	useEffect(() => {
		setFavoriteMatches(favorites.matches);
	}, [favorites.matches]);

	useEffect(() => {
		setDetail(matchDetail);
	}, [matchDetail]);

	const renderTime = (match) => {
		if (match.status.id === 4) {
			return 'D.A.';
		} else if (match.status.id === 2 || match.status.id === 3 || match.status.id === 6) {
			return `${match.status.id === 6 ? match.status.shortName : ''} ${match.times.currentMinute}${typeof match.times.stoppage !== 'undefined' ? '+' + match.times.stoppage : ''}<span>'</span>`;
		} else {
			return moment(match.date).format('HH:mm');
		}
	};

	const addFavorite = (data) => {
		setFavoriteMatch(data);
	};

	const getMatchDetail = (id) => {
		fetchMatchDetail(id);
	};

	const percentage = (value, total) => {
		return Math.round((value * 100) / total);
	};

	return (
		<div className="live-match-list">
			<div className="live-match-header" onClick={() => setOpened(!open)}>
				<div className="league-name">
					{tournament.name} {matches.length > 0 ? `(${matches.length})` : ''}
				</div>

				<button className={`arrow ${open ? 'forward' : 'down'}`}>
					<ion-icon name="chevron-forward"></ion-icon>
				</button>
			</div>

			{open && (
				<Accordion className="live-match-list" allowZeroExpanded>
					{matches.map((match, i) => (
						<AccordionItem key={i} className={`match-card${scored === match.id ? ' goal' : ''}`}>
							<AccordionItemHeading>
								<AccordionItemButton>
									{auth && (
										<div className="match-favorite">
											<FavoriteButton favorite={favoriteMatches && favoriteMatches.find((x) => x === match.id)} matchId={match.id} addFavorite={(data) => addFavorite(data)} />
										</div>
									)}
									<div className="match-info">
										<a href={`https://ajansspor.com/futbol/takim/genel-bakis/${match.homeTeam.id}`} className="team-info" target="_blank" rel="noreferrer">
											<span>{match.homeTeam.name}</span>
											<img src={TeamImage(match.homeTeam.id)} alt="" />
										</a>

										<a
											href={`https://ajansspor.com/futbol/mac-ozeti/${SeoUrl(match.homeTeam.name)}-${SeoUrl(match.awayTeam.name)}/${match.id}`}
											className="match-score"
											target="_blank"
											rel="noreferrer"
										>
											{typeof match.homeTeam.score !== 'undefined' ? match.homeTeam.score.current : '0'} -{' '}
											{typeof match.awayTeam.score !== 'undefined' ? match.awayTeam.score.current : '0'}
											{match.status.id === 3 && <small>{`(${match.homeTeam.score.halfTime} - ${match.awayTeam.score.halfTime})`}</small>}
										</a>

										<a href={`https://ajansspor.com/futbol/takim/genel-bakis/${match.awayTeam.id}`} className="team-info away-team" target="_blank" rel="noreferrer">
											<img src={TeamImage(match.awayTeam.id)} alt="" />
											<span>{match.awayTeam.name}</span>
										</a>
									</div>
									<div className="match-date">
										<div dangerouslySetInnerHTML={{ __html: renderTime(match) }}></div>

										<button onClick={() => getMatchDetail(match.id)}>
											<ion-icon name="chevron-down"></ion-icon>
										</button>
									</div>
								</AccordionItemButton>
							</AccordionItemHeading>

							<AccordionItemPanel>
								{detail.isFetching ? 'Maç bilgisi getiriliyor...' : ''}
								<div className="match-detail__info">
									<Tabs>
										<TabList>
											<Tab>İstatistikler</Tab>
										</TabList>

										<TabPanel>
											<div className="match__detail-stats">
												<div className="home-team">
													{typeof detail !== 'undefined' &&
													typeof detail.statistics !== 'undefined' &&
													Object.keys(detail.statistics).length > 0 &&
													Object.keys(detail.statistics.homeTeam.statistics).length > 0 ? (
														<div>
															<ul>
																<li>
																	<span>Topla Oynama</span>

																	<CircularProgressbarWithChildren
																		value={detail.statistics.homeTeam.statistics.ballPossession}
																		styles={{
																			path: {
																				stroke: '#F00',
																				strokeWidth: 6,
																			},
																			trail: {
																				stroke: '#d6d6d6',
																				strokeWidth: 6,
																			},
																		}}
																	>
																		{detail.statistics.homeTeam.statistics.ballPossession || 0}
																	</CircularProgressbarWithChildren>
																</li>
																<li>
																	<span>İsabetli Şut</span>

																	<CircularProgressbarWithChildren
																		value={percentage(
																			detail.statistics.homeTeam.statistics.shotsOnTarget,
																			detail.statistics.homeTeam.statistics.shotsOnTarget + detail.statistics.awayTeam.statistics.shotsOnTarget,
																		)}
																		styles={{
																			path: {
																				stroke: '#F00',
																				strokeWidth: 6,
																			},
																			trail: {
																				stroke: '#d6d6d6',
																				strokeWidth: 6,
																			},
																		}}
																	>
																		{detail.statistics.homeTeam.statistics.shotsOnTarget || '0'}
																	</CircularProgressbarWithChildren>
																</li>
																<li>
																	<span>İsabetsiz Şut</span>

																	<CircularProgressbarWithChildren
																		value={percentage(
																			detail.statistics.homeTeam.statistics.shotsOffTarget,
																			detail.statistics.homeTeam.statistics.shotsOffTarget + detail.statistics.awayTeam.statistics.shotsOffTarget,
																		)}
																		styles={{
																			path: {
																				stroke: '#F00',
																				strokeWidth: 6,
																			},
																			trail: {
																				stroke: '#d6d6d6',
																				strokeWidth: 6,
																			},
																		}}
																	>
																		{detail.statistics.homeTeam.statistics.shotsOffTarget || '0'}
																	</CircularProgressbarWithChildren>
																</li>
																<li>
																	<span>Faul</span>

																	<CircularProgressbarWithChildren
																		value={percentage(
																			detail.statistics.homeTeam.statistics.fouls,
																			detail.statistics.homeTeam.statistics.fouls + detail.statistics.awayTeam.statistics.fouls,
																		)}
																		styles={{
																			path: {
																				stroke: '#F00',
																				strokeWidth: 6,
																			},
																			trail: {
																				stroke: '#d6d6d6',
																				strokeWidth: 6,
																			},
																		}}
																	>
																		{detail.statistics.homeTeam.statistics.fouls || '0'}
																	</CircularProgressbarWithChildren>
																</li>
																<li>
																	<span>Korner</span>

																	<CircularProgressbarWithChildren
																		value={percentage(
																			detail.statistics.homeTeam.statistics.corners,
																			detail.statistics.homeTeam.statistics.corners + detail.statistics.awayTeam.statistics.corners,
																		)}
																		styles={{
																			path: {
																				stroke: '#F00',
																				strokeWidth: 6,
																			},
																			trail: {
																				stroke: '#d6d6d6',
																				strokeWidth: 6,
																			},
																		}}
																	>
																		{detail.statistics.homeTeam.statistics.corners || '0'}
																	</CircularProgressbarWithChildren>
																</li>
																<li>
																	<span>Sarı Kart</span>

																	<CircularProgressbarWithChildren
																		value={percentage(
																			detail.statistics.homeTeam.statistics.yellowCards,
																			detail.statistics.homeTeam.statistics.yellowCards + detail.statistics.awayTeam.statistics.yellowCards,
																		)}
																		styles={{
																			path: {
																				stroke: '#F00',
																				strokeWidth: 6,
																			},
																			trail: {
																				stroke: '#d6d6d6',
																				strokeWidth: 6,
																			},
																		}}
																	>
																		{detail.statistics.homeTeam.statistics.yellowCards || '0'}
																	</CircularProgressbarWithChildren>
																</li>
																{detail.statistics.homeTeam.statistics.secondYellowCards + detail.statistics.awayTeam.statistics.secondYellowCards > 0 && (
																	<li>
																		<span>2. Sarıdan Kırmızı Kart</span>

																		<CircularProgressbarWithChildren
																			value={percentage(
																				detail.statistics.homeTeam.statistics.secondYellowCards,
																				detail.statistics.homeTeam.statistics.secondYellowCards + detail.statistics.awayTeam.statistics.secondYellowCards,
																			)}
																			styles={{
																				path: {
																					stroke: '#F00',
																					strokeWidth: 6,
																				},
																				trail: {
																					stroke: '#d6d6d6',
																					strokeWidth: 6,
																				},
																			}}
																		>
																			{detail.statistics.homeTeam.statistics.secondYellowCards || '0'}
																		</CircularProgressbarWithChildren>
																	</li>
																)}

																{detail.statistics.homeTeam.statistics.redCards + detail.statistics.awayTeam.statistics.redCards > 0 && (
																	<li>
																		<span>Kırmızı Kart</span>

																		<CircularProgressbarWithChildren
																			value={percentage(
																				detail.statistics.homeTeam.statistics.redCards,
																				detail.statistics.homeTeam.statistics.redCards + detail.statistics.awayTeam.statistics.redCards,
																			)}
																			styles={{
																				path: {
																					stroke: '#F00',
																					strokeWidth: 6,
																				},
																				trail: {
																					stroke: '#d6d6d6',
																					strokeWidth: 6,
																				},
																			}}
																		>
																			{detail.statistics.homeTeam.statistics.redCards || '0'}
																		</CircularProgressbarWithChildren>
																	</li>
																)}
															</ul>
														</div>
													) : (
														''
													)}
												</div>
												<div className="away-team">
													{typeof detail !== 'undefined' &&
													typeof detail.statistics !== 'undefined' &&
													Object.keys(detail.statistics).length > 0 &&
													Object.keys(detail.statistics.awayTeam.statistics).length > 0 ? (
														<div>
															<ul>
																<li>
																	<span>Topla Oynama</span>

																	<CircularProgressbarWithChildren
																		value={detail.statistics.awayTeam.statistics.ballPossession}
																		styles={{
																			path: {
																				stroke: '#F00',
																				strokeWidth: 6,
																			},
																			trail: {
																				stroke: '#d6d6d6',
																				strokeWidth: 6,
																			},
																		}}
																	>
																		{detail.statistics.awayTeam.statistics.ballPossession || '0'}
																	</CircularProgressbarWithChildren>
																</li>
																<li>
																	<span>İsabetli Şut</span>

																	<CircularProgressbarWithChildren
																		value={percentage(
																			detail.statistics.awayTeam.statistics.shotsOnTarget,
																			detail.statistics.homeTeam.statistics.shotsOnTarget + detail.statistics.awayTeam.statistics.shotsOnTarget,
																		)}
																		styles={{
																			path: {
																				stroke: '#F00',
																				strokeWidth: 6,
																			},
																			trail: {
																				stroke: '#d6d6d6',
																				strokeWidth: 6,
																			},
																		}}
																	>
																		{detail.statistics.awayTeam.statistics.shotsOnTarget || '0'}
																	</CircularProgressbarWithChildren>
																</li>
																<li>
																	<span>İsabetsiz Şut</span>

																	<CircularProgressbarWithChildren
																		value={percentage(
																			detail.statistics.awayTeam.statistics.shotsOffTarget,
																			detail.statistics.homeTeam.statistics.shotsOffTarget + detail.statistics.awayTeam.statistics.shotsOffTarget,
																		)}
																		styles={{
																			path: {
																				stroke: '#F00',
																				strokeWidth: 6,
																			},
																			trail: {
																				stroke: '#d6d6d6',
																				strokeWidth: 6,
																			},
																		}}
																	>
																		{detail.statistics.awayTeam.statistics.shotsOffTarget || '0'}
																	</CircularProgressbarWithChildren>
																</li>
																<li>
																	<span>Faul</span>

																	<CircularProgressbarWithChildren
																		value={percentage(
																			detail.statistics.awayTeam.statistics.fouls,
																			detail.statistics.homeTeam.statistics.fouls + detail.statistics.awayTeam.statistics.fouls,
																		)}
																		styles={{
																			path: {
																				stroke: '#F00',
																				strokeWidth: 6,
																			},
																			trail: {
																				stroke: '#d6d6d6',
																				strokeWidth: 6,
																			},
																		}}
																	>
																		{detail.statistics.awayTeam.statistics.fouls || '0'}
																	</CircularProgressbarWithChildren>
																</li>
																<li>
																	<span>Korner</span>

																	<CircularProgressbarWithChildren
																		value={percentage(
																			detail.statistics.awayTeam.statistics.corners,
																			detail.statistics.homeTeam.statistics.corners + detail.statistics.awayTeam.statistics.corners,
																		)}
																		styles={{
																			path: {
																				stroke: '#F00',
																				strokeWidth: 6,
																			},
																			trail: {
																				stroke: '#d6d6d6',
																				strokeWidth: 6,
																			},
																		}}
																	>
																		{detail.statistics.awayTeam.statistics.corners || '0'}
																	</CircularProgressbarWithChildren>
																</li>
																<li>
																	<span>Sarı Kart</span>

																	<CircularProgressbarWithChildren
																		value={percentage(
																			detail.statistics.awayTeam.statistics.yellowCards,
																			detail.statistics.homeTeam.statistics.yellowCards + detail.statistics.awayTeam.statistics.yellowCards,
																		)}
																		styles={{
																			path: {
																				stroke: '#F00',
																				strokeWidth: 6,
																			},
																			trail: {
																				stroke: '#d6d6d6',
																				strokeWidth: 6,
																			},
																		}}
																	>
																		{detail.statistics.awayTeam.statistics.yellowCards || '0'}
																	</CircularProgressbarWithChildren>
																</li>
																{detail.statistics.homeTeam.statistics.secondYellowCards + detail.statistics.awayTeam.statistics.secondYellowCards > 0 && (
																	<li>
																		<span>2. Sarıdan Kırmızı Kart</span>

																		<CircularProgressbarWithChildren
																			value={percentage(
																				detail.statistics.awayTeam.statistics.secondYellowCards,
																				detail.statistics.homeTeam.statistics.secondYellowCards + detail.statistics.awayTeam.statistics.secondYellowCards,
																			)}
																			styles={{
																				path: {
																					stroke: '#F00',
																					strokeWidth: 6,
																				},
																				trail: {
																					stroke: '#d6d6d6',
																					strokeWidth: 6,
																				},
																			}}
																		>
																			{detail.statistics.awayTeam.statistics.secondYellowCards}
																		</CircularProgressbarWithChildren>
																	</li>
																)}

																{detail.statistics.homeTeam.statistics.redCards + detail.statistics.awayTeam.statistics.redCards > 0 && (
																	<li>
																		<span>Kırmızı Kart</span>

																		<CircularProgressbarWithChildren
																			value={percentage(
																				detail.statistics.awayTeam.statistics.redCards,
																				detail.statistics.homeTeam.statistics.redCards + detail.statistics.awayTeam.statistics.redCards,
																			)}
																			styles={{
																				path: {
																					stroke: '#F00',
																					strokeWidth: 6,
																				},
																				trail: {
																					stroke: '#d6d6d6',
																					strokeWidth: 6,
																				},
																			}}
																		>
																			{detail.statistics.awayTeam.statistics.redCards}
																		</CircularProgressbarWithChildren>
																	</li>
																)}
															</ul>
														</div>
													) : (
														''
													)}
												</div>
											</div>
										</TabPanel>
										<TabPanel>
											{detail.events && detail.events.length > 0 ? (
												<span>{detail.events.filter((x) => x.type.name === 'Gol').map((c) => c.teamId)}</span>
											) : (
												<span className="not-goal">Bu maçta henüz gol olmadı :(</span>
											)}
										</TabPanel>
									</Tabs>
								</div>
							</AccordionItemPanel>
						</AccordionItem>
					))}
				</Accordion>
			)}
		</div>
	);
};

const LiveScore = ({ fetchBroadcasters, broadcasters, fetchLiveMatches, matches, fetchFavoriteMatches, favorites, setFavoriteMatch, fetchMatchDetail, matchDetail }) => {
	// const sliderRef = React.useRef(null);
	const [alert, setAlert] = useState(false);
	const [alertText, setAlertText] = useState('');
	const [alertMatchId, setAlertMatchId] = useState('');
	const [auth, setAuth] = useState(false);
	const [favoriteMatches, setFavoriteMatches] = useState([]);

	useEffect(() => {
		setAuth(getToken() ? true : false);

		if (getToken()) {
			fetchFavoriteMatches(getTokenDecoded(getToken()).id);
		}
	}, []);

	useEffect(() => {
		fetchBroadcasters();
		fetchLiveMatches(moment().format('DD-MM-YYYY'));

		setInterval(() => {
			fetchLiveMatches(moment().format('DD-MM-YYYY'));
		}, 10000);
	}, []);

	useEffect(() => {
		if (alert) {
			if (!('Notification' in window)) {
				console.log('Notification API not supported.');
			} else if (Notification.permission === 'granted') {
				new Notification(alertText);
			} else if (Notification.permission !== 'denied') {
				Notification.requestPermission((permission) => {
					if (permission === 'granted') {
						new Notification(alertText);
					}
				});
			}
		}
	}, [alert]);

	useEffect(() => {
		if (typeof matches !== 'undefined' && matches && matches.count > 0) {
			matches.tournaments.map((tournament) => {
				tournament.matches.map((match) => {
					if (!localStorage.getItem(match.id)) {
						localStorage.setItem(
							match.id,
							JSON.stringify([
								typeof match.homeTeam.score !== 'undefined' ? match.homeTeam.score.current : '0',
								typeof match.awayTeam.score !== 'undefined' ? match.awayTeam.score.current : '0',
							]),
						);
					} else {
						const scores = JSON.parse(localStorage.getItem(match.id));

						if (
							scores[0] !== (typeof match.homeTeam.score !== 'undefined' ? match.homeTeam.score.current : '0') ||
							scores[1] !== (typeof match.awayTeam.score !== 'undefined' ? match.awayTeam.score.current : '0')
						) {
							setAlert(true);
							setAlertText(`${match.homeTeam.name} ${match.homeTeam.score.current} - ${match.awayTeam.score.current} ${match.awayTeam.name}`);
							setAlertMatchId(match.id);

							setTimeout(() => {
								setAlert(false);
								setAlertText('');
								setAlertMatchId('');
							}, 4000);

							localStorage.setItem(
								match.id,
								JSON.stringify([
									typeof match.homeTeam.score !== 'undefined' ? match.homeTeam.score.current : '0',
									typeof match.awayTeam.score !== 'undefined' ? match.awayTeam.score.current : '0',
								]),
							);
						}
					}
				});
			});
		}
	}, [matches]);

	useEffect(() => {
		const favoriteMatches = [];

		if (typeof matches !== 'undefined' && matches && matches.count > 0) {
			matches.tournaments.map((tournament) => {
				tournament.matches.map((match) => {
					if (typeof favorites !== 'undefined' && typeof favorites.matches !== 'undefined' && favorites.matches.length > 0 && favorites.matches.find((x) => x === match.id)) {
						favoriteMatches.push(match);
					}
				});
			});
		}

		setFavoriteMatches([{ tournament: { id: 0, name: 'Favori Maçlarınız' }, matches: favoriteMatches }]);
	}, [matches, favorites]);

	return (
		<>
			<Helmet>
				<title>Canlı Skor | Radyospor - Türkiye'nin İlk Spor Radyosu</title>
			</Helmet>

			<Header page="livescore" />

			<div className="container">
				<div className="section-title">
					<h1>Canlı Skor</h1>
				</div>

				<main>
					<div className="row">
						<div className="col-lg-8">
							<section className="live-score">
								<div className="live-score-header">
									<div className="header-title">Popüler Maçlar</div>

									<a href="https://ajansspor.com/mac-merkezi" target="_blank" rel="noreferrer">
										Tüm Maçlar
									</a>

									<div className="day-selector">
										<span>{moment().format('DD.MM.YYYY')}</span>
									</div>
								</div>

								{favoriteMatches &&
									favoriteMatches.length > 0 &&
									favoriteMatches[0].matches.length > 0 &&
									favoriteMatches.map((tournament, i) => (
										<MatchList key={i} {...tournament} scored={alertMatchId} auth={auth} favorites={favorites} setFavoriteMatch={(data) => setFavoriteMatch(data)} />
									))}

								{typeof matches !== 'undefined' && matches && matches.count > 0 ? (
									matches.tournaments.map((tournament, i) => (
										<MatchList
											key={i}
											{...tournament}
											scored={alertMatchId}
											auth={auth}
											favorites={favorites}
											setFavoriteMatch={(data) => setFavoriteMatch(data)}
											fetchMatchDetail={(id) => fetchMatchDetail(id)}
											matchDetail={matchDetail}
										/>
									))
								) : (
									<div className="match-not-found">
										<img src="/assets/images/no-match.svg" alt="" />
										<span>Canlı Maç Bulunmuyor</span>
									</div>
								)}
							</section>

							<NewsList />
							<div className="section-seperator"></div>
							<StreamList />
						</div>

						<div className="col-lg-4">
							<aside>
								<PointStatus />
								<DailyNews />
								<BroadcasterSidebar broadcasters={broadcasters} />
							</aside>
						</div>
					</div>

					<Programs />
				</main>
			</div>

			{alert && <div className="live-score-alertify">{alertText}</div>}

			<Footer />
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		broadcasters: state.Broadcasters.broadcasters,
		news: state.News.news,
		matches: state.LiveScore.matches,
		favorites: state.Favorites.matches,
		matchDetail: state.MatchDetail.matchDetail,
	};
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
	const { dispatch } = dispatchProps;
	const { actions: Broadcasters } = require('../redux/BroadcasterRedux');
	const { actions: News } = require('../redux/NewsRedux');
	const { actions: LiveScore } = require('../redux/LiveScoreRedux');
	const { actions: Favorites } = require('../redux/FavoriteRedux');
	const { actions: MatchDetail } = require('../redux/MatchDetailRedux');

	return {
		...ownProps,
		...stateProps,
		fetchBroadcasters: () => Broadcasters.fetchBroadcasters(dispatch),
		fetchNews: () => News.fetchNews(dispatch),
		fetchLiveMatches: (day) => LiveScore.fetchLiveMatches(dispatch, day),
		fetchFavoriteMatches: (id) => Favorites.fetchFavoriteMatches(dispatch, id),
		setFavoriteMatch: (data) => Favorites.setFavoriteMatch(dispatch, data),
		fetchMatchDetail: (id) => MatchDetail.fetchMatchDetail(dispatch, id),
	};
};

export default connect(mapStateToProps, null, mergeProps)(LiveScore);
