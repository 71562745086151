/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Header, Footer, Frequencies, Programs, ListenedPodcast } from '../components';
import { getToken, getTokenDecoded } from '../utils';

const Listened = ({ fetchPodcasts, podcasts }) => {
	const [currentPage, setCurrentPage] = useState(1);
	const viewPerPage = 5;

	useEffect(() => {
		const user = getTokenDecoded(getToken());

		if (user && Object.keys(user).length > 0) {
			fetchPodcasts('listened');
		} else {
			fetchPodcasts('last');
		}
	}, []);

	const handleClick = (event) => {
		setCurrentPage(event);
	};

	const indexOfLast = currentPage * viewPerPage;
	const indexOfFirst = indexOfLast - viewPerPage;

	return (
		<>
			<Helmet>
				<title>Dinlediğim Podcastler | Radyospor - Türkiye'nin İlk Spor Radyosu</title>
			</Helmet>

			<Header page="streaming" />

			<div className="container">
				<div className="section-title">
					<h1>Dinlediğim Podcastler</h1>
				</div>

				<main className="no-margin">
					<div className="row">
						<div className="col-lg-12">
							<section className="listened-podcasts">
								<div className="listened-podcast-list">
									{podcasts?.success === false && <h6 className="podcast-not-found ">{podcasts?.message}</h6>}

									{podcasts &&
										podcasts.length > 0 &&
										podcasts
											.slice(indexOfFirst, indexOfLast)
											.map((podcast, i) => (
												<ListenedPodcast
													key={i}
													id={podcast.detail.id}
													image={podcast.program.image}
													programId={typeof podcast.program !== 'undefined' && podcast.program.id}
													file={podcast.detail.file}
													name={typeof podcast.program !== 'undefined' && podcast.program.name}
													title={podcast.title}
													date={podcast.date}
													datetime={podcast.createdAt}
													broadcasters={podcast.broadcasters}
													dates={podcast.dates}
													currentTime={podcast.currentTime}
												/>
											))}
								</div>

								{podcasts.length > 0 && (
									<div className="listened-podcast-pagination">
										<ul>
											{[...Array(Math.round(podcasts.length / viewPerPage))].map((_, i) => (
												<li key={i} onClick={() => handleClick(i + 1)} className={currentPage === i + 1 ? 'active' : ''}>
													{i + 1}
												</li>
											))}
										</ul>
									</div>
								)}
							</section>
						</div>
					</div>

					<Frequencies />
					<div className="section-seperator"></div>
					<Programs />
				</main>
			</div>

			<Footer />
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		podcasts: state.Podcasts.podcasts,
	};
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
	const { dispatch } = dispatchProps;
	const { actions: Podcasts } = require('../redux/PodcastsRedux');

	return {
		...ownProps,
		...stateProps,
		fetchPodcasts: (type) => Podcasts.fetchPodcast(dispatch, type),
	};
};

export default connect(mapStateToProps, null, mergeProps)(Listened);
