/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Helmet } from 'react-helmet';
import { Footer, Header, Programs } from '../components';

const KVKK = () => {
	return (
		<>
			<Helmet>
				<title>KVKK Açık Rıza ve Bilgilendirme | Radyospor - Türkiye'nin İlk Spor Radyosu</title>
			</Helmet>

			<Header />

			<div className="container">
				<main className="kvkk">
					<div className="row">
						<div className="col-lg-12">
							<div className="section-title">KVKK Açık Rıza ve Bilgilendirme</div>

							<div className="kvkk-content">
								KİŞİSEL VERİLERİN KORUNMASI KANUNU KAPSAMINDA AÇIK RIZA ONAYI
								<br />
								<br />
								6698 Sayılı Kişisel Verilerin Korunması Kanunu kapsamında veri sorumlusu sıfatıyla, TATLISES RADYO TELEVİZYON VE MEDYA HİZMETLERİ A.Ş. ("RADYOSPOR") tarafından;
								<br />
								<br />
								1. İsim, soyadı, e-posta adresi, Facebook, Twitter, Gmail, Hotmail gibi üçüncü şahıs hesapları ile üye olunmuş ise işbu hesap bilgilerim, işlem güvenliği (IP adresi
								bilgileri, internet sitesi/uygulama giriş çıkış bilgileri, standart web günlüğü bilgileri), pazarlama satış bilgisi (anket, çerez kayıtları, kampanya, promosyon
								çalışmasıyla elde edilen bilgiler), RADYOSPOR tarafından sunulan ürün ve hizmetlerin kullanışı sırasında elde edilen hesap hareketlerim (favori sporlar, ligler
								takımlar, etkinlikler ve/veya spor taraftarlarını ilgilendiren konularla ilgili sağladığım bilgiler, takım tercihlerim, isteyerek paylaştığım veriler, yorumlar,
								görüntülediğim içerik türü, ne sıklıkla, hangi saatlerde görüntülediğime yönelik veriler), RADYOSPOR ile olan yazışma kayıtlarım gibi kişisel bilgilerimin; - Hukuki ve
								ticari güvenliğin temini, şirketin ticari ve iş stratejilerinin belirlenmesi ve uygulanması ve Şirketin insan kaynakları politikalarının yürütülmesinin temini amacıyla
								bağlantılı, sınırlı ve ölçülü olacak şekilde işlenmesine, yine işlenmesini gerektiren amaç çerçevesinde kaydedilmesine, Aydınlatılmış açık rızam ile onay veririm.
								hukuken bu kişisel verileri talep etmeye yetkili olan kamu kurum ve kuruluşlara, işbirlikçilerine özel hukuk tüzel kişiliklerine, Şirket topluluğuna, grup şirketlerine,
								Şirket hissedarlarına, birinci, ikinci, üçüncü yöneticilerine tedarikçilere, İş ortaklarına yurtiçinde olacak şekilde aktarılmasına; Aydınlatılmış açık rızam ile onay
								veririm. <br />
								<br />
								2. İsim, soyadı, e-posta adresi, Facebook, Twitter, Gmail, Hotmail gibi üçüncü şahıs hesapları ile üye olunmuş ise işbu hesap bilgilerim, işlem güvenliği (IP adresi
								bilgileri, internet sitesi/uygulama giriş çıkış bilgileri, standart web günlüğü bilgileri), pazarlama satış bilgisi (anket, çerez kayıtları, kampanya, promosyon
								çalışmasıyla elde edilen bilgiler), RADYOSPOR tarafından sunulan ürün ve hizmetlerin kullanışı sırasında elde edilen hesap hareketlerim (favori sporlar, ligler
								takımlar, etkinlikler ve/veya spor taraftarlarını ilgilendiren konularla ilgili sağladığım bilgiler, takım tercihlerim, isteyerek paylaştığım veriler, yorumlar,
								görüntülediğim içerik türü, ne sıklıkla, hangi saatlerde görüntülediğime yönelik veriler), RADYOSPOR ile olan yazışma kayıtlarım gibi kişisel bilgilerimin; - Sunulan
								ürün ve hizmetlerin tarafımın beğeni, kullanım alışkanlıklarım ve ihtiyaçlarıma göre özelleştirilerek yine tarafıma önerilmesi amacıyla bağlantılı, sınırlı ve ölçülü
								olacak şekilde işlenmesine, yine işlenmesini gerektiren amaç çerçevesinde kaydedilmesine, Aydınlatılmış açık rızam ile onay veririm. hukuken bu kişisel verileri talep
								etmeye yetkili olan kamu kurum ve kuruluşlara, işbirlikçilerine özel hukuk tüzel kişiliklerine, Şirket topluluğuna, grup şirketlerine, Şirket hissedarlarına, birinci,
								ikinci, üçüncü yöneticilerine tedarikçilere, İş ortaklarına yurtiçinde olacak şekilde aktarılmasına; Aydınlatılmış açık rızam ile onay veririm. <br />
								<br />
								3. İsim, soyadı, e-posta adresi, Facebook, Twitter, Gmail, Hotmail gibi üçüncü şahıs hesapları ile üye olunmuş ise işbu hesap bilgilerim, işlem güvenliği (IP adresi
								bilgileri, internet sitesi/uygulama giriş çıkış bilgileri, standart web günlüğü bilgileri), pazarlama satış bilgisi (anket, çerez kayıtları, kampanya, promosyon
								çalışmasıyla elde edilen bilgiler), RADYOSPOR tarafından sunulan ürün ve hizmetlerin kullanışı sırasında elde edilen hesap hareketlerim (favori sporlar, ligler
								takımlar, etkinlikler ve/veya spor taraftarlarını ilgilendiren konularla ilgili sağladığım bilgiler, takım tercihlerim, isteyerek paylaştığım veriler, yorumlar,
								görüntülediğim içerik türü, ne sıklıkla, hangi saatlerde görüntülediğime yönelik veriler), RADYOSPOR ile olan yazışma kayıtlarım gibi kişisel bilgilerimin; -
								istatistiki raporlama sisteminde veya reklam ve pazarlama (anket ve/veya promosyon) alanında kullanıcılarının genel eğilimlerini belirlenmesi, kişiselleştirilmiş içerik
								ve reklam olarak tarafıma sunulması, hizmetlerle ilgili kalite ve standart denetimleri yapabilmek amacıyla bağlantılı, sınırlı ve ölçülü olacak şekilde işlenmesine,
								yine işlenmesini gerektiren amaç çerçevesinde kaydedilmesine, Aydınlatılmış açık rızam ile onay veririm. hukuken bu kişisel verileri talep etmeye yetkili olan kamu
								kurum ve kuruluşlara, işbirlikçilerine özel hukuk tüzel kişiliklerine, Şirket topluluğuna, grup şirketlerine, Şirket hissedarlarına, birinci, ikinci, üçüncü
								yöneticilerine tedarikçilere, İş ortaklarına yurtiçinde olacak şekilde aktarılmasına; Aydınlatılmış açık rızam ile onay veririm.
								<br />
								<br />
								4. İsim, soyadı, e-posta adresi, Facebook, Twitter, Gmail, Hotmail gibi üçüncü şahıs hesapları ile üye olunmuş ise işbu hesap bilgilerim, işlem güvenliği (IP adresi
								bilgileri, internet sitesi/uygulama giriş çıkış bilgileri, standart web günlüğü bilgileri), pazarlama satış bilgisi (anket, çerez kayıtları, kampanya, promosyon
								çalışmasıyla elde edilen bilgiler), RADYOSPOR tarafından sunulan ürün ve hizmetlerin kullanışı sırasında elde edilen hesap hareketlerim (favori sporlar, ligler
								takımlar, etkinlikler ve/veya spor taraftarlarını ilgilendiren konularla ilgili sağladığım bilgiler, takım tercihlerim, isteyerek paylaştığım veriler, yorumlar,
								görüntülediğim içerik türü, ne sıklıkla, hangi saatlerde görüntülediğime yönelik veriler), RADYOSPOR ile olan yazışma kayıtlarım gibi kişisel bilgilerimin; - bilgi
								işlem gereksinimleri, sistemsel yapısı, alınan bilgi işlem destek hizmetlerinin gerekliliği, IP adresleri sitedeki sistemle ilgili sorunların tanımlanabilmesi ve
								çıkabilecek muhtemel sorunların acil olarak giderilmesi amacıyla bağlantılı, sınırlı ve ölçülü olacak şekilde işlenmesine, yine işlenmesini gerektiren amaç çerçevesinde
								kaydedilmesine, Aydınlatılmış açık rızam ile onay veririm. hukuken bu kişisel verileri talep etmeye yetkili olan kamu kurum ve kuruluşlara, işbirlikçilerine özel hukuk
								tüzel kişiliklerine, Şirket topluluğuna, grup şirketlerine, Şirket hissedarlarına, birinci, ikinci, üçüncü yöneticilerine tedarikçilere, İş ortaklarına yurtiçinde
								olacak şekilde aktarılmasına; Aydınlatılmış açık rızam ile onay veririm. <br />
								<br />
								5. İsim, soyadı, e-posta adresi, Facebook, Twitter, Gmail, Hotmail gibi üçüncü şahıs hesapları ile üye olunmuş ise işbu hesap bilgilerim, işlem güvenliği (IP adresi
								bilgileri, internet sitesi/uygulama giriş çıkış bilgileri, standart web günlüğü bilgileri), pazarlama satış bilgisi (anket, çerez kayıtları, kampanya, promosyon
								çalışmasıyla elde edilen bilgiler), RADYOSPOR tarafından sunulan ürün ve hizmetlerin kullanışı sırasında elde edilen hesap hareketlerim (favori sporlar, ligler
								takımlar, etkinlikler ve/veya spor taraftarlarını ilgilendiren konularla ilgili sağladığım bilgiler, takım tercihlerim, isteyerek paylaştığım veriler, yorumlar,
								görüntülediğim içerik türü, ne sıklıkla, hangi saatlerde görüntülediğime yönelik veriler), RADYOSPOR ile olan yazışma kayıtlarım gibi kişisel bilgilerimin; - müşteri
								memnuniyetinin ölçümlenmesi ve artırılması, yeni hizmet ve ürünler ile ilgili görüş ve önerilerimi almak amacıyla bağlantılı, sınırlı ve ölçülü olacak şekilde
								işlenmesine, yine işlenmesini gerektiren amaç çerçevesinde kaydedilmesine, Aydınlatılmış açık rızam ile onay veririm. hukuken bu kişisel verileri talep etmeye yetkili
								olan kamu kurum ve kuruluşlara, işbirlikçilerine özel hukuk tüzel kişiliklerine, Şirket topluluğuna, grup şirketlerine, Şirket hissedarlarına, birinci, ikinci, üçüncü
								yöneticilerine tedarikçilere, İş ortaklarına yurtiçinde olacak şekilde aktarılmasına; Aydınlatılmış açık rızam ile onay veririm.
							</div>
						</div>
					</div>

					<Programs />
				</main>
			</div>

			<Footer />
		</>
	);
};

export default KVKK;
