import api from '../api';
import { getToken } from '../utils';

const types = {
	FETCH_PODCAST: 'FETCH_PODCAST',
	FETCH_PODCAST_SUCCESS: 'FETCH_PODCAST_SUCCESS',
	FETCH_PODCAST_FAILURE: 'FETCH_PODCAST_FAILURE',
};

export const actions = {
	fetchPodcast: (dispatch, type) => {
		dispatch({ type: types.FETCH_PODCAST });

		if (type === 'last') {
			api.lastPodcast()
				.then((res) => dispatch({ type: types.FETCH_PODCAST_SUCCESS, podcasts: res.podcasts }))
				.catch((err) => err);
		} else if (type === 'most-listened') {
			api.mostPodcast()
				.then((res) => dispatch({ type: types.FETCH_PODCAST_SUCCESS, podcasts: res.podcasts }))
				.catch((err) => err);
		} else if (type === 'special') {
			api.privatePodcast()
				.then((res) => dispatch({ type: types.FETCH_PODCAST_SUCCESS, podcasts: res.podcasts }))
				.catch((err) => err);
		} else if (type === 'listened') {
			api.getListenedPodcast({ token: getToken() })
				.then((res) => dispatch({ type: types.FETCH_PODCAST_SUCCESS, podcasts: res.podcasts }))
				.catch((err) => err);
		}
	},
};

const initialState = {
	isFetching: false,
	error: null,
	podcasts: [],
};

export const reducer = (state = initialState, action) => {
	const { error, type, podcasts } = action;

	switch (type) {
		case types.FETCH_PODCAST:
			return { ...initialState, isFetching: true };
		case types.FETCH_PODCAST_SUCCESS:
			return { ...state, podcasts, isFetching: false, error };
		case types.FETCH_PODCAST_FAILURE:
			return { ...state, error, isFetching: false };
		default:
			return state;
	}
};
