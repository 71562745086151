/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Header, Streaming as StreamList, Footer, PointStatus, NewsList, Frequencies, Programs, ListenedPodcast, BroadcasterSidebar } from '../components';
import { getToken, getTokenDecoded } from '../utils';

const Streaming = ({ fetchBroadcasters, broadcasters, fetchPodcasts, podcasts }) => {
	const [user, setUser] = useState({});

	useEffect(() => {
		fetchBroadcasters();

		if (getToken()) {
			const user = getTokenDecoded(getToken());
			setUser(user);
		}

		if (user && Object.keys(user).length > 0) {
			fetchPodcasts('listened');
		} else {
			fetchPodcasts('last');
		}
	}, []);

	return (
		<>
			<Helmet>
				<title>Yayın Akışı | Radyospor - Türkiye'nin İlk Spor Radyosu</title>
			</Helmet>
			<Header page="streaming" />

			<div className="container">
				<div className="section-title">
					<h1>Yayın Akışı</h1>
				</div>

				<StreamList big />

				<main>
					<div className="row">
						<div className="col-lg-8">
							{Object.keys(user).length > 0 && (
								<>
									<section className="listened-podcasts">
										<div className="listened-podcast-head">
											<div className="listened-podcast-title">Son Dinlenen Podcastler</div>
											<a href="/dinlediklerim">Tümü</a>
										</div>

										<div className="listened-podcast-list">
											{podcasts &&
												podcasts.length > 0 &&
												podcasts
													.slice(0, 10)
													.map((podcast, i) => (
														<ListenedPodcast
															key={i}
															id={podcast.id}
															image={typeof podcast.program !== 'undefined' && podcast.program.image ? podcast.program.id : 0}
															programId={typeof podcast.program !== 'undefined' && podcast.program.id}
															file={podcast.name}
															name={typeof podcast.program !== 'undefined' && podcast.program.name}
															title={podcast.title}
															date={podcast.date}
															datetime={podcast.createdAt}
															broadcasters={podcast.broadcasters}
														/>
													))}
										</div>
									</section>
									<div className="section-seperator"></div>
								</>
							)}
							<NewsList />
						</div>

						<div className="col-lg-4">
							<aside>
								<PointStatus />
							</aside>
						</div>
					</div>

					<BroadcasterSidebar broadcasters={broadcasters} />

					<Frequencies />
					<div className="section-seperator"></div>
					<Programs />
				</main>
			</div>

			<Footer />
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		broadcasters: state.Broadcasters.broadcasters,
		podcasts: state.Podcasts.podcasts,
	};
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
	const { dispatch } = dispatchProps;
	const { actions: Broadcasters } = require('../redux/BroadcasterRedux');
	const { actions: Podcasts } = require('../redux/PodcastsRedux');

	return {
		...ownProps,
		...stateProps,
		fetchBroadcasters: () => Broadcasters.fetchBroadcasters(dispatch),
		fetchPodcasts: (type) => Podcasts.fetchPodcast(dispatch, type),
	};
};

export default connect(mapStateToProps, null, mergeProps)(Streaming);
