/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet';
import Avatar from 'react-avatar';
import { Footer, Header, FavoritePrograms, FavoriteBroadcaster } from '../components';
import { getToken, getTokenDecoded } from '../utils';

moment.locale('tr');

const Profile = ({ fetchFavoritePrograms, programs, setFavoriteProgram, fetchFavoriteBroadcasters, broadcasters, setFavoriteBroadcaster }) => {
	const [user, setUser] = useState({});
	const [programList, setProgramList] = useState([]);
	const [broadcasterList, setBroadcasterList] = useState([]);

	useEffect(() => {
		const user = getTokenDecoded(getToken());
		setUser(user);

		fetchFavoritePrograms(`?userId=${user.id}&type=1`);
		fetchFavoriteBroadcasters(user.id);
	}, []);

	useEffect(() => {
		if (programs && typeof programs !== 'undefined') {
			setProgramList(programs);
		}

		if (programs.message) {
			fetchFavoritePrograms(`?userId=${user.id}&type=1`);

			toast.success(programs.message, {
				position: 'bottom-center',
				autoClose: 1500,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				closeButton: false,
			});
		}
	}, [programs]);

	useEffect(() => {
		if (broadcasters && typeof broadcasters !== 'undefined' && Array.isArray(broadcasters)) {
			setBroadcasterList(broadcasters);
		}

		if (broadcasters.message) {
			fetchFavoriteBroadcasters(user.id);

			toast.success(broadcasters.message, {
				position: 'bottom-center',
				autoClose: 1500,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				closeButton: false,
			});
		}
	}, [broadcasters]);

	const removeFavorite = (id) => {
		setFavoriteProgram({ programs: id, userId: user.id });
	};

	const removeFavoriteBroadcaster = (id) => {
		setFavoriteBroadcaster({ broadcasters: id, userId: user.id });
	};

	return (
		<>
			<Helmet>
				<title>Profil | Radyospor - Türkiye'nin İlk Spor Radyosu</title>
			</Helmet>

			<Header />

			<div className="container">
				<main className="profile">
					<div className="row">
						<div className="col-lg-8 mb-40">
							<div className="profile-header">
								<div className="profile-image">
									<Avatar name={user.fullname} size={140} />
								</div>

								<div className="profile-info">
									<span className="profile-name">{user.fullname}</span>
									<span className="profile-date">{moment(user.date).format('DD.MM.YYYY')}</span>
									{/* <span className="profile-login__type">{user.loginType === 'facebook' ? 'Facebook' : 'Eposta adresiniz'} ile giriş yaptınız.</span> */}
								</div>

								<div className="profile-settings">
									<a href="/ayarlar">
										<ion-icon name="settings"></ion-icon>
									</a>
								</div>
							</div>

							<div className="section-seperator"></div>

							<section className="listened-podcasts">
								<div className="listened-podcast-head">
									<div className="listened-podcast-title">Favori Programlarım</div>
								</div>

								<div className="listened-podcast-list">
									{programList.length > 0 && programList.map((prg, i) => <FavoritePrograms {...prg} key={i} removeFavorite={(data) => removeFavorite(data)} />)}
								</div>
							</section>
						</div>

						<div className="col-lg-4">
							<FavoriteBroadcaster broadcasters={broadcasterList} noMargin removeFavorite={(id) => removeFavoriteBroadcaster(id)} />
						</div>
					</div>
				</main>
			</div>

			<Footer />
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		programs: state.Favorites.programs,
		broadcasters: state.Favorites.broadcasters,
	};
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
	const { dispatch } = dispatchProps;
	const { actions: Favorites } = require('../redux/FavoriteRedux');

	return {
		...ownProps,
		...stateProps,
		fetchFavoritePrograms: (id) => Favorites.fetchFavoritePrograms(dispatch, id),
		setFavoriteProgram: (data) => Favorites.setFavoriteProgram(dispatch, data),
		fetchFavoriteBroadcasters: (id) => Favorites.fetchFavoriteBroadcasters(dispatch, id),
		setFavoriteBroadcaster: (data) => Favorites.setFavoriteBroadcaster(dispatch, data),
	};
};

export default connect(mapStateToProps, null, mergeProps)(Profile);
