/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Header, Footer, Programs, BroadcasterSidebar } from '../components';

const Favorites = ({ fetchBroadcasters, broadcasters }) => {
	useEffect(() => {
		fetchBroadcasters();
	}, []);

	return (
		<>
			<Header />

			<div className="container">
				<div className="section-title">
					<h1>Favorilerim</h1>
				</div>

				<main className="no-margin">
					<div className="row">
						<div className="col-lg-8">
							{/* <section className="listened-podcasts">
								<div className="listened-podcast-head">
									<div className="listened-podcast-title">Favori Podcastlerim</div>
								</div>

								<div className="listened-podcast-list">
									{[...Array(5)].map((_, i) => (
										<ListenedPodcast key={i} />
									))}
								</div>
							</section> */}
							<div className="section-seperator"></div>

							<section className="news">
								<div className="news-title">Spor Haberleri</div>

								<div className="news-list">
									<ul>
										{[...Array(5)].map((_, i) => (
											<li key={i}>
												<a href="#!">
													<div className="news-image">
														<img src="/assets/images/news-1.png" alt="" />
													</div>

													<div className="news-info">
														<div className="news-source">
															<img src="/assets/images/ajansspor.svg" alt="" />
														</div>

														<div className="news-head">
															<div className="news-author">Tolgahan Akkuş</div>

															<div className="news-date">11.12.2021</div>
														</div>

														<p>Kendi gelmeden raporu geldi! İşte Pereira'nın transfer talebi...</p>

														<div className="news-footer">
															<div className="news-read-count">
																<ion-icon name="eye"></ion-icon>
																<span>12.268.442</span>
															</div>

															<button className="podcast-favorite">
																<ion-icon name="star"></ion-icon>
															</button>

															<button className="podcast-share">
																<ion-icon name="share-social-sharp"></ion-icon>
															</button>
														</div>
													</div>
												</a>
											</li>
										))}
									</ul>
								</div>
							</section>
						</div>

						<div className="col-lg-4">
							<aside>
								<BroadcasterSidebar noMargin favorite broadcasters={broadcasters} />
							</aside>
						</div>
					</div>

					<Programs />
				</main>
			</div>

			<Footer />
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		broadcasters: state.Broadcasters.broadcasters,
	};
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
	const { dispatch } = dispatchProps;
	const { actions: Broadcasters } = require('../redux/BroadcasterRedux');

	return {
		...ownProps,
		...stateProps,
		fetchBroadcasters: () => Broadcasters.fetchBroadcasters(dispatch),
	};
};

export default connect(mapStateToProps, null, mergeProps)(Favorites);
