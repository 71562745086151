import api from '../api';

const types = {
	FETCH_SETTINGS: 'FETCH_SETTINGS',
	FETCH_SETTINGS_SUCCESS: 'FETCH_SETTINGS_SUCCESS',
	FETCH_SETTINGS_FAILURE: 'FETCH_SETTINGS_FAILURE',
};

export const actions = {
	fetchSettings: (dispatch) => {
		dispatch({ type: types.FETCH_SETTINGS });

		api.settings()
			.then((res) => dispatch({ type: types.FETCH_SETTINGS_SUCCESS, settings: res }))
			.catch((error) => dispatch({ type: types.FETCH_SETTINGS_FAILURE, error }));
	},
};

const initialState = {
	isFetching: true,
	error: null,
	settings: [],
};

export const reducer = (state = initialState, action) => {
	const { error, type, settings } = action;

	switch (type) {
		case types.FETCH_SETTINGS:
			return { ...initialState };
		case types.FETCH_SETTINGS_SUCCESS:
			return { ...state, settings, isFetching: false, error };
		case types.FETCH_SETTINGS_FAILURE:
			return { ...state, error, isFetching: false };
		default:
			return state;
	}
};
