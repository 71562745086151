/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Slider from 'react-slick';
import moment from 'moment';
import { Helmet } from 'react-helmet';

import { Header, Streaming as StreamList, Footer, PointStatus, Frequencies, Programs, BroadcasterSidebar } from '../components';
import { SeoUrl, NewsImage } from '../utils';
moment.locale('tr');

const News = ({ fetchBroadcasters, broadcasters, fetchNews, news }) => {
	const sliderRef = React.useRef(null);

	useEffect(() => {
		fetchBroadcasters();
		fetchNews();
	}, []);

	const PrevArrow = () => (
		<button className="prev" onClick={() => sliderRef?.current?.slickPrev()}>
			<ion-icon name="chevron-back-outline"></ion-icon>
		</button>
	);

	const NextArrow = () => (
		<button className="next" onClick={() => sliderRef?.current?.slickNext()}>
			<ion-icon name="chevron-forward-outline"></ion-icon>
		</button>
	);

	const settings = {
		dots: true,
		infinite: false,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: false,
	};

	return (
		<>
			<Helmet>
				<title>Haberler | Radyospor - Türkiye'nin İlk Spor Radyosu</title>
			</Helmet>

			<Header page="news" />

			<div className="container">
				<div className="section-title">
					<h1>Haberler</h1>
				</div>

				<main className="no-margin">
					<div className="row">
						<div className="col-lg-8">
							<div className="podcast-carousel news-carousel">
								<div className="carousel-arrows">
									<PrevArrow />
									<NextArrow />
								</div>

								<Slider ref={sliderRef} {...settings}>
									{news.length > 0 &&
										news.slice(0, 5).map((list, i) => (
											<div key={i} className="podcast-carousel-item">
												<a href={`https://ajansspor.com/haber/${SeoUrl(list.Title)}-${list.ContentId}`} target="_blank" rel="noreferrer">
													<div className="carousel-image">
														<img src={NewsImage(list.MediaId, true)} alt="" />
													</div>

													<div className="news-title">{list.Title}</div>
												</a>
											</div>
										))}
								</Slider>
							</div>

							<div className="section-seperator"></div>

							<section className="news">
								<div className="news-title">Spor Haberleri</div>

								<div className="news-list">
									<ul>
										{news.length > 0 &&
											news.slice(4, 11).map((list, i) => (
												<li key={i}>
													<a href={`https://ajansspor.com/haber/${SeoUrl(list.Title)}-${list.ContentId}`} target="_blank" rel="noreferrer">
														<div className="news-image">
															<img src={NewsImage(list.MediaId)} alt="" />
														</div>

														<div className="news-info">
															<div className="news-source">
																<img src="/assets/images/ajansspor.svg" alt="" />
															</div>

															<div className="news-head">
																{/* <div className="news-author">Tolgahan Akkuş</div> */}

																<div className="news-date">{moment(list.UpdatedDate).format('DD.MM.YYYY')}</div>
															</div>

															<p>{list.Title}</p>

															{/* <div className="news-footer">
																<div className="news-read-count">
																	<ion-icon name="eye"></ion-icon>
																	<span>12.268.442</span>
																</div>

																<button className="podcast-favorite">
																	<ion-icon name="star"></ion-icon>
																</button>

																<button className="podcast-share">
																	<ion-icon name="share-social-sharp"></ion-icon>
																</button>
															</div> */}
														</div>
													</a>
												</li>
											))}
									</ul>
								</div>
							</section>

							<div className="section-seperator"></div>
							<StreamList />
						</div>

						<div className="col-lg-4">
							<aside>
								{/* <DailyPodcasts /> */}

								<PointStatus />

								<BroadcasterSidebar broadcasters={broadcasters} />
							</aside>
						</div>
					</div>

					<Frequencies />
					<div className="section-seperator"></div>
					<Programs />
				</main>
			</div>

			<Footer />
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		broadcasters: state.Broadcasters.broadcasters,
		news: state.News.news,
	};
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
	const { dispatch } = dispatchProps;
	const { actions: Broadcasters } = require('../redux/BroadcasterRedux');
	const { actions: News } = require('../redux/NewsRedux');

	return {
		...ownProps,
		...stateProps,
		fetchBroadcasters: () => Broadcasters.fetchBroadcasters(dispatch),
		fetchNews: () => News.fetchNews(dispatch),
	};
};

export default connect(mapStateToProps, null, mergeProps)(News);
